import React, { Fragment, useState } from 'react';
import { Picto } from '../../../../components';
import { pictos } from '../../../../components/Picto';
import Popover from '../../../../components/Popover';
import { useTranslation } from '../../../../tools/i18n';
import { getFavoritesActions } from '../../store';
import RenameFavoritesGroupModal from '../RenameGroupModal';
import { FavoritesGroup } from '../types';

const { deleteFavoritesGroup } = getFavoritesActions;

interface ItemType {
  label: string;
  picto?: keyof typeof pictos;
  onClick: () => void;
}

export interface EditFavoritesGroupProps {
  favoritesGroup: FavoritesGroup;
}

const EditFavoritesGroup: React.FC<EditFavoritesGroupProps> = ({ favoritesGroup }) => {
  const { t } = useTranslation();
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

  const handleRemove = () => {
    deleteFavoritesGroup(favoritesGroup.id);
    document.body.click();
  };

  const items: ItemType[] = [
    {
      label: t('ssrComponents.favorites.group.popover.rename'),
      picto: 'rename',
      onClick: () => setIsRenameModalOpen(true),
    },
    {
      label: t('ssrComponents.favorites.group.popover.delete'),
      picto: 'cross',
      onClick: handleRemove,
    },
  ];

  return (
    <Fragment>
      <Popover>
        <div className="EditFavoritesGroup">
          {items.map((item) => (
            <div key={item.label} className="EditFavoritesGroup__item" onClick={item.onClick}>
              <div className="EditFavoritesGroup__item__pictoContainer">
                <Picto iconKey={item.picto} />
              </div>
              {item.label}
            </div>
          ))}
        </div>
      </Popover>
      <RenameFavoritesGroupModal
        favoritesGroup={favoritesGroup}
        isOpen={isRenameModalOpen}
        onClose={() => setIsRenameModalOpen(false)}
      />
    </Fragment>
  );
};

export default EditFavoritesGroup;
