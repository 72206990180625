import React from 'react';
import { useTranslation } from '../../../../tools/i18n';
import { TextStoreItem } from '../../../../tools/providersAPI';

const TextStoreItemComp: React.FC<TextStoreItem> = ({ text }) => {
  const { t } = useTranslation();
  const translatedText = text?.replace(/{{t:(.*?)}}/g, (match, key) =>
    t(`shopV2.groupsCustom.texts.${key}`)
  );

  return <div className="TextStoreItemComp" dangerouslySetInnerHTML={{ __html: translatedText }} />;
};

export default TextStoreItemComp;
