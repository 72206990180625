import React from 'react';
import { PictoProps } from '.';

export const TravelEcoMartinique = (props: PictoProps) => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.9844 54.2814V47.4641"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.061 69.7739C54.6034 69.7739 59.9072 67.4848 59.9072 64.6609C59.9072 61.837 54.6034 59.5479 48.061 59.5479C41.5185 59.5479 36.2148 61.837 36.2148 64.6609C36.2148 67.4848 41.5185 69.7739 48.061 69.7739Z"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.2148 64.6609V73.1826C36.2148 76.0119 41.5118 78.2957 48.061 78.2957C54.6102 78.2957 59.9072 76.0119 59.9072 73.1826V64.6609"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.292 56.139V64.6608C19.292 67.49 24.5889 69.7738 31.1381 69.7738"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.1381 52.7304C37.6806 52.7304 42.9843 50.4412 42.9843 47.6173C42.9843 44.7935 37.6806 42.5043 31.1381 42.5043C24.5957 42.5043 19.292 44.7935 19.292 47.6173C19.292 50.4412 24.5957 52.7304 31.1381 52.7304Z"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.292 47.6174V56.1391C19.292 58.9683 24.5889 61.2522 31.1381 61.2522"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
