import React from 'react';
import { PictoProps } from './index';

export const Website = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.9998 15.2C11.97 15.2 15.1998 11.9702 15.1998 8.00005C15.1998 4.02985 11.97 0.800049 7.9998 0.800049C4.02961 0.800049 0.799805 4.02985 0.799805 8.00005C0.799805 11.9702 4.02961 15.2 7.9998 15.2ZM3.01918 10.88H5.0128C5.17658 11.7815 5.41947 12.6106 5.74084 13.2975C4.59361 12.8064 3.64087 11.9509 3.01918 10.88ZM2.2398 8.00005C2.2398 7.50206 2.30998 7.02125 2.42939 6.56005H4.82199C4.78174 7.03514 4.7598 7.51758 4.7598 8.00005C4.7598 8.48252 4.78174 8.96496 4.82198 9.44005H2.42938C2.30998 8.97885 2.2398 8.49804 2.2398 8.00005ZM7.9998 2.24005C8.42783 2.24005 9.13816 3.24125 9.52602 5.12005H6.47359C6.86145 3.24125 7.57178 2.24005 7.9998 2.24005ZM9.7349 6.56005C9.77542 7.00842 9.79981 7.48646 9.79981 8.00005C9.79981 8.51364 9.77542 8.99168 9.7349 9.44005H6.26471C6.22419 8.99168 6.1998 8.51364 6.1998 8.00005C6.1998 7.48646 6.22419 7.00842 6.26471 6.56005H9.7349ZM13.7598 8.00005C13.7598 8.49804 13.6896 8.97885 13.5702 9.44005H11.1776C11.2179 8.96496 11.2398 8.48252 11.2398 8.00005C11.2398 7.51758 11.2179 7.03514 11.1776 6.56005H13.5702C13.6896 7.02125 13.7598 7.50206 13.7598 8.00005ZM7.9998 13.76C7.57178 13.76 6.86145 12.7588 6.47359 10.88H9.52602C9.13816 12.7588 8.42783 13.76 7.9998 13.76ZM10.2588 13.2975C10.5801 12.6106 10.823 11.7815 10.9868 10.88H12.9804C12.3587 11.9509 11.406 12.8064 10.2588 13.2975ZM12.9804 5.12005H10.9868C10.823 4.2186 10.5801 3.38953 10.2588 2.70262C11.406 3.19375 12.3587 4.04919 12.9804 5.12005ZM5.74084 2.70262C5.41947 3.38953 5.17658 4.2186 5.0128 5.12005H3.01918C3.64087 4.04919 4.59361 3.19375 5.74084 2.70262Z"
      fill={fill || 'white'}
    />
  </svg>
);
