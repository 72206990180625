import React from 'react';
import { PictoProps } from './index';

export const MapPin = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mapPin"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3337 5.33333C11.3337 6.33845 11.0556 7.27863 10.5722 8.08115L6.0003 16L1.42705 8.07891C0.944517 7.27688 0.666992 6.33751 0.666992 5.33333C0.666992 2.38781 3.05481 0 6.00033 0C8.94584 0 11.3337 2.38781 11.3337 5.33333ZM6.00033 7.33359C7.1049 7.33359 8.00033 6.43816 8.00033 5.33359C8.00033 4.22902 7.1049 3.33359 6.00033 3.33359C4.89576 3.33359 4.00033 4.22902 4.00033 5.33359C4.00033 6.43816 4.89576 7.33359 6.00033 7.33359Z"
      fill={fill || 'white'}
    />
  </svg>
);
