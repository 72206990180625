import React from 'react';

export interface RadioOption {
  label?: string;
  value: string;
}

export interface RadioProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  id?: string;
  options?: RadioOption[];
}

const Radio: React.FC<RadioProps> = ({
  value,
  label,
  id: baseId,
  options,
  defaultValue,
  ...rest
}) => {
  const id = baseId ?? Math.random().toString(36).substr(2, 9);

  return (
    <div className="Radio">
      {!!label && (
        <label
          className="Radio__label"
          htmlFor={id}
          nonce="__nonce__"
          dangerouslySetInnerHTML={{ __html: label }}
        />
      )}
      <div className="Radio__options">
        {options?.map(({ label: optionLabel, value: optionValue }) => (
          <div key={optionValue} className="Radio__options__option">
            <input
              name={id}
              type="radio"
              id={optionValue}
              value={optionValue}
              defaultChecked={optionValue === defaultValue}
              {...rest}
            />
            <label htmlFor={optionValue}>{optionLabel}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Radio;
