import React from 'react';
import { PictoProps } from './index';

export const Google = (props: PictoProps) => (
  <svg
    className="GoogleIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1896_95979)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6 10.2271C19.6 9.518 19.5364 8.83619 19.4182 8.18164H10V12.0498H15.3818C15.15 13.2998 14.4455 14.3589 13.3864 15.068V17.5771H16.6182C18.5091 15.8362 19.6 13.2725 19.6 10.2271Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 19.9999C12.7001 19.9999 14.9637 19.1044 16.6183 17.5772L13.3864 15.0681C12.491 15.6681 11.3455 16.0226 10.0001 16.0226C7.39554 16.0226 5.19099 14.2635 4.40463 11.8999H1.06372V14.4908C2.70918 17.759 6.09099 19.9999 10.0001 19.9999Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.40455 11.8997C4.20455 11.2997 4.09091 10.6588 4.09091 9.9997C4.09091 9.34061 4.20455 8.6997 4.40455 8.0997V5.50879H1.06364C0.386364 6.85879 0 8.38606 0 9.9997C0 11.6133 0.386364 13.1406 1.06364 14.4906L4.40455 11.8997Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 3.97727C11.4683 3.97727 12.7864 4.48182 13.8228 5.47273L16.691 2.60455C14.9592 0.990909 12.6955 0 10.0001 0C6.09099 0 2.70918 2.24091 1.06372 5.50909L4.40463 8.1C5.19099 5.73636 7.39554 3.97727 10.0001 3.97727Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_1896_95979">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
