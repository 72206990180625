import React from 'react';
import Form, { FormProps } from '../../../../components/Form';
import Modal from '../../../../components/Modal';
import { useTranslation } from '../../../../tools/i18n';
import { getFavoritesActions } from '../../store';
import { FavoritesGroup } from '../types';

const { updateFavoritesGroup } = getFavoritesActions;

interface RenameFavoritesGroupModalProps {
  favoritesGroup: FavoritesGroup;
  isOpen?: boolean;
  onClose: () => void;
}

const RenameFavoritesGroupModal = ({
  favoritesGroup,
  isOpen,
  onClose,
}: RenameFavoritesGroupModalProps) => {
  const { t } = useTranslation();

  const fields: FormProps['fields'] = [
    {
      label: t('ssrComponents.favorites.renameGroupModal.name'),
      name: 'name',
      type: 'text',
      required: true,
      defaultValue: favoritesGroup.name,
    },
  ];

  const handleRenameFavoritesGroupName = (values: { [key: string]: any }) => {
    updateFavoritesGroup({ id: favoritesGroup.id, name: values.name });
    onClose();
  };

  return (
    <Modal
      title={t('ssrComponents.favorites.renameGroupModal.title')}
      isOpen={isOpen}
      onClose={onClose}
      className="RenameFavoritesGroupModal"
    >
      <Form
        fields={fields}
        submitText={t('ssrComponents.favorites.renameGroupModal.rename')}
        onSubmit={handleRenameFavoritesGroupName}
      />
    </Modal>
  );
};

export default RenameFavoritesGroupModal;
