import React from 'react';
import { PictoProps } from './index';

export const Rename = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 18 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4995 18.2464L8.99915 16.8283L2.04263 4.62318L7.56511 1.43478L14.5216 13.6399L13.4995 18.2464Z"
      stroke={fill || 'white'}
      strokeWidth="1.73913"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.17383 21.5797H16.826"
      stroke={fill || 'white'}
      strokeWidth="1.73913"
      strokeLinecap="round"
    />
  </svg>
);
