import React, { Fragment, useState } from 'react';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';
import { A11y, Autoplay, Keyboard, Navigation } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import ClientSideSlideshowLightbox from '../ClientSideSlideshowLightbox';
import { Picto } from '../Picto';

interface ImagesCarouselProps {
  imageUrls: string[];
  swiperProps?: SwiperProps;
}

const ImagesCarousel: React.FC<ImagesCarouselProps> = ({
  imageUrls,
  swiperProps,
}: ImagesCarouselProps) => {
  const [swiper, setSwiper] = useState(null);
  const [lightboxImageIndex, setLightboxImageIndex] = useState(-1);

  if (!imageUrls?.length) return null;

  return (
    <div className="ImagesCarousel">
      <div className="ImagesCarousel__carousel">
        <Swiper
          onSwiper={setSwiper}
          modules={[
            Keyboard,
            Navigation,
            A11y,
            swiperProps?.autoplay ? Autoplay : null,
            ...(swiperProps?.modules || []),
          ]}
          loop={imageUrls?.length > 1}
          {...swiperProps}
        >
          {imageUrls.map((imageUrl, index) => (
            <SwiperSlide key={index}>
              <img src={imageUrl} alt={`${index}`} onClick={() => setLightboxImageIndex(index)} />
            </SwiperSlide>
          ))}
        </Swiper>
        {imageUrls?.length > 1 ? (
          <Fragment>
            <button
              className="ImagesCarousel__carousel__navigationButton ImagesCarousel__carousel__navigationPrev"
              onClick={() => swiper.slidePrev()}
            >
              <Picto iconKey="caret-left" />
            </button>
            <button
              className="ImagesCarousel__carousel__navigationButton ImagesCarousel__carousel__navigationNext"
              onClick={() => swiper.slideNext()}
            >
              <Picto iconKey="caret-right" />
            </button>
          </Fragment>
        ) : null}
      </div>
      <ClientSideSlideshowLightbox
        images={imageUrls?.map((imageUrl, i) => ({ src: imageUrl, alt: i.toString() }))}
        startingSlideIndex={lightboxImageIndex}
        open={lightboxImageIndex > -1}
        onClose={() => setLightboxImageIndex(-1)}
      />
    </div>
  );
};

export default ImagesCarousel;
