import React, { useEffect, useState } from 'react';
import Form, { FormProps } from '../../../../../components/Form';
import { RequirementsProps } from '../../../../../tools/context';
import { useTranslation } from '../../../../../tools/i18n';
import { getAuthActions, useUser } from '../../../store';
import { userProfiles, UserUpdate } from '../../../types';


const { updateUser } = getAuthActions;

interface AccountPageProps {
  requirements: RequirementsProps;
}

const AccountPage = (_: AccountPageProps) => {
  const { t } = useTranslation();
  const user = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [userProfileFromStorage, setUserProfileFromStorage] = useState<string | null>(null);

  useEffect(() => {
    const storedProfile = localStorage.getItem('userProfile') || localStorage.getItem('user-profile');
    
    if (storedProfile && userProfiles.includes(storedProfile))
      setUserProfileFromStorage(storedProfile);
  }, []);

  const fields: FormProps['fields'] = [
    {
      type: 'group',
      title: t('ssrComponents.form.fields.identifiers'),
      fields: [
        {
          name: 'email',
          type: 'email',
          label: t('ssrComponents.form.fields.email'),
          defaultValue: user?.email,
        },
        /*
        {
          name: 'password',
          type: 'password',
          label: t('ssrComponents.form.fields.password'),
          isOptional: true,
        },
        */
      ],
    },
    {
      type: 'group',
      title: t('ssrComponents.form.fields.youAre'),
      fields: [
        {
          name: 'profile',
          type: 'radio',
          label: t('ssrComponents.form.fields.yourProfile'),
          options: userProfiles?.map((p) => ({
            value: p,
            label: t(`ssrComponents.form.fields.profiles.${p}`),
          })),
          defaultValue: userProfileFromStorage || user?.profile,
        },
        {
          name: 'first_name',
          type: 'text',
          label: t('ssrComponents.form.fields.firstname'),
          defaultValue: user?.first_name,
        },
        {
          name: 'last_name',
          type: 'text',
          label: t('ssrComponents.form.fields.lastname'),
          defaultValue: user?.last_name,
        },
        {
          name: 'city',
          type: 'text',
          label: t('ssrComponents.form.fields.city'),
          defaultValue: user?.city,
        },
      ],
    },
  ];
  const [errors] = useState<{ [key: string]: string }>({});

  const handleFormSubmit = (data: any) => {
    setIsLoading(true);
    const body = data as UserUpdate;

    try {
      updateUser(body);
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  return (
    <div className="AccountPage">
      <Form
        fields={fields}
        errors={errors}
        onSubmit={handleFormSubmit}
        submitText={t('ssrComponents.form.submits.saveEdits')}
        isSubmitLoading={isLoading}
      />
    </div>
  );
};

export default AccountPage;
