import React from 'react';
import { useTranslation } from '../../../tools/i18n';
import ButtonV2, { ButtonV2Props } from '../../ButtonV2';

interface HowToGoButtonProps extends Partial<ButtonV2Props> {
  latitude?: number;
  longitude?: number;
}

const HowToGoButton: React.FC<HowToGoButtonProps> = ({ latitude, longitude }) => {
  const { t } = useTranslation();

  if (!!!latitude || !!!longitude) return null;

  return (
    <ButtonV2
      text={t('ssrComponents.buttons.howToGo.text')}
      leftIcon="direction"
      align="left"
      link={`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`}
    />
  );
};

export default HowToGoButton;
