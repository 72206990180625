import useEmblaCarousel from 'embla-carousel-react';
import React from 'react';

interface SlideType {
  image?: any;
  title?: string;
  subtitle?: { text?: string };
}

interface FullScreenCarouselProps {
  slides: SlideType[];
}

export default function FullScreenCarousel({ slides }: FullScreenCarouselProps) {
  const [emblaRef] = useEmblaCarousel();

  return (
    <div className="fullScreenCarousel" ref={emblaRef}>
      <div className="embla__container">
        {slides.map((slide, index) => (
          <div
            key={index}
            className="embla__slide"
            style={{ backgroundImage: `url(${slide.image?.url()})` }}
          >
            <div className="embla__slide__content">
              <p className="embla_slide_title">{slide.title ?? 'Titre de la slide'}</p>
              <p>{slide.subtitle?.text ?? 'Sous-titre de la slide'}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
