import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSticky } from '../StickyHeaderManager';

let uniqueId = 0;
const getUniqueId = () => `stickyItem_${uniqueId++}`;

export const StickyItem = ({ children, position = 'left' }) => {
  const { stickyItems, registerStickyItem, unregisterStickyItem } = useSticky();
  const itemRef = useRef(null);
  const idRef = useRef(getUniqueId());
  const [portalContainer, setPortalContainer] = useState(null);

  useEffect(() => {
    const iRef = idRef.current;
    registerStickyItem(iRef, itemRef);

    return () => unregisterStickyItem(iRef);
  }, [registerStickyItem, unregisterStickyItem]);

  const isItemSticky = stickyItems?.[idRef.current] || false;

  const containerId = position === 'right' ? 'c-Header-insert-2' : 'c-Header-insert-1';

  useEffect(() => {
    setPortalContainer(document.getElementById(containerId));
  }, [containerId]);

  return (
    <Fragment>
      {/* Original item always stays in place */}
      <div className="StickyItem" ref={itemRef}>
        {children}
      </div>

      {/* If item is sticky and container exists, show a duplicate in the header */}
      {isItemSticky &&
        portalContainer &&
        ReactDOM.createPortal(
          // Render the same children (a duplicate) in the header
          children,
          portalContainer
        )}
    </Fragment>
  );
};
