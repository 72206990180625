import React, { useState } from 'react';
import ButtonV2 from '../../../../../components/ButtonV2';
import Form, { FormProps } from '../../../../../components/Form';
import { RequirementsProps } from '../../../../../tools/context';
import { useTranslation } from '../../../../../tools/i18n';
import { usersApiLogin } from '../../../../../tools/usersApi';
import SSOAuth from '../../../components/SSOAuth';
import { getAuthActions } from '../../../store';
import { UserLogin } from '../../../types';

interface LoginPageProps {
  requirements: RequirementsProps;
}

const { setAccessToken } = getAuthActions;

const LoginPage = (_: LoginPageProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const fields: FormProps['fields'] = [
    {
      name: 'username',
      type: 'email',
      autoComplete: 'email',
      label: t('ssrComponents.form.fields.email'),
    },
    {
      name: 'password',
      type: 'password',
      autoComplete: 'current-password',
      label: t('ssrComponents.form.fields.password'),
    },
  ];

  const handleFormSubmit = (data: any) => {
    setIsLoading(true);
    const body = data as UserLogin;

    usersApiLogin(body)
      .then((response) => {
        setAccessToken(response.data?.access_token);
      })
      .catch((error) => {
        switch (error?.data?.detail) {
          case 'Incorrect email or password':
            return setErrors({
              username: t('ssrComponents.auth.errors.incorrectEmailOrPassword'),
              password: ' ',
            });
          default:
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="LoginPage">
      <Form
        title={t('ssrComponents.auth.login.title')}
        subtitle={t('ssrComponents.auth.login.subtitle')}
        fields={fields}
        errors={errors}
        onSubmit={handleFormSubmit}
        submitText={t('ssrComponents.auth.login.submit')}
        isSubmitLoading={isLoading}
      />
      <p className="LoginPage__or">{t('ssrComponents.auth.common.or')}</p>
      <SSOAuth />
      <div className="LoginPage__noAccount">
        <p>{t('ssrComponents.auth.login.noAccount')}</p>
        <ButtonV2 text={t('ssrComponents.auth.login.signup')} variant="clear" link="/inscription" />
      </div>
    </div>
  );
};

export default LoginPage;
