import React from 'react';
import { PictoProps } from './index';

export const PhoneV2 = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 21 20"
    fill={'none'}
    xmlns="http://www.w3.org/2000/svg"
    className="withStroke"
  >
    <path
      d="M1.65284 3.08771C2.24182 11.6441 9.13835 18.5406 17.6954 19.1303C18.6067 19.1932 19.4096 18.4981 19.4847 17.5811C19.4847 17.5811 19.7338 14.537 19.734 14.5344C19.7838 13.9267 19.3734 13.3302 18.7615 13.1777L15.1644 12.2784C14.6502 12.15 14.1171 12.3446 13.8064 12.7741L13.0753 13.7849C12.8662 14.0738 12.4781 14.1631 12.1726 13.9923C9.91461 12.7297 8.05505 10.8661 6.79538 8.60381C6.62549 8.29814 6.71538 7.91078 7.0045 7.70217L8.00797 6.97707C8.43832 6.66681 8.63328 6.13343 8.50467 5.61865L7.60542 2.02164C7.45208 1.40537 6.88049 0.996511 6.2487 1.04905L3.20196 1.29842C2.28498 1.37347 1.5899 2.17634 1.65284 3.08771Z"
      stroke={fill || 'white'}
      strokeWidth="1.44"
    />
  </svg>
);
