import * as React from 'react';
import ButtonV2 from '../../../ButtonV2';

export interface ArticlesCarouselLinkProps {
  text: string;
  url?: string;
}

const ArticlesCarouselLink: React.FC<ArticlesCarouselLinkProps> = ({ text, url }) => {
  if (!!!text) return null;

  return (
    <ButtonV2
      className="ArticlesCarouselLink"
      variant="clear"
      rightIcon="arrowRightV2"
      text={text}
      link={url}
      linkTarget={url?.startsWith('/') ? '_self' : '_blank'}
    />
  );
};

export default ArticlesCarouselLink;
