import React from 'react';
import { imageUrlFor } from '../../tools/sanity';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { RequirementsProps } from '../../tools/context';
import { SocialPicto } from './index';

interface  SocialBannerContentBlocProps{
  title: string
  subtitle?: string
  sectionLogo: SanityImageSource
  socialPicto: SocialPicto[]
  requirements : RequirementsProps
}
const SocialBannerContentBloc: React.FC<SocialBannerContentBlocProps> = ({title, subtitle, sectionLogo, socialPicto, requirements}) => (
  <div className="social-banner__content">
    <div className="social-banner__title">
      <img className="picto" src={imageUrlFor(sectionLogo, requirements).url()} alt="" />
      <h2>{title}</h2>
    </div>
    {subtitle ? <h3>{subtitle}</h3> : undefined}
    <div className="social-banner__links">
      {socialPicto.map((picto, index) => (
        <a href={picto.url} key={index}>
          <img
            className="social-banner__links__picto"
            src={imageUrlFor(picto.picto, requirements).url()}
            alt=""
          />
        </a>
      ))}
    </div>
  </div>
)

export default SocialBannerContentBloc;
