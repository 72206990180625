import React, {useEffect, useRef} from 'react';
import {loadMap} from "../../tools/apiTools";
import {RequirementsProps} from "../../tools/context";
import OfferSlide, {getDiscountTags} from "../Carousel/OfferSlide";
import * as ReactDomServer from "react-dom/server";

export interface MapProps {
  requirements?: RequirementsProps
  results: any[]
  geoloc?: GeolocationCoordinates
}

const Map = (props: MapProps) => {
  const {results: initialResults, requirements} = props;
  const divRef = useRef<HTMLDivElement>();

  const results = initialResults?.filter((r: any) => r._geoloc?.lat && r._geoloc?.lng);

  const couponQuery = props.requirements?.coupon ? `?coupon=${props.requirements.coupon}` : '';

  useEffect(() => {
    loadMap(divRef.current, getCenter(), results.map(shop => ({
      geoloc: shop._geoloc,
      category: shop.categslug,
      content: ReactDomServer.renderToString(<OfferSlide
        category={shop.categslug}
        title={shop.store_name}
        place={shop.city || shop.deptname}
        url={`/etablissement/${shop.seller_id}${couponQuery}`}
        image={shop.urlimg}
        fallbackImage={`https://tourismebyca.twic.pics/static/${props.requirements.partner}/v2/${shop.categslug}.png`}
        discount={couponQuery ? [] : getDiscountTags(shop.gifts)}
        noNote
        small
        requirements={requirements}
      />)
    })), null, getPosition());
  }, [results])

  const getCenter = () => {
    if (props.requirements?.lon && props.requirements?.lat) {
      return { lat: - -props.requirements.lat, lng: - -props.requirements.lon };
    }

    if (results.length) {
      const firstWithGeoloc = results.find(r => r._geoloc && r._geoloc.lat && r._geoloc.lng);

      if (firstWithGeoloc) {
        return firstWithGeoloc._geoloc;
      }
    }

    return { lat: 44.838354, lng: -0.576056 };
  }

  const getPosition = () => {
    if (props.requirements?.lon && props.requirements?.lat) {
      return { lat: - -props.requirements.lat, lng: - -props.requirements.lon } ;
    }

    if (props.geoloc?.longitude && props.geoloc?.latitude) {
      return  { lat: props.geoloc.latitude, lng: props.geoloc.longitude };
    }

    return null;
  }

  return (
    <div className="map" ref={ref => { divRef.current = ref }} />
  )
}

export default Map;