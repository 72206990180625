import React from 'react';
import { Picto, pictos } from '../../../../components/Picto';
import Separator from '../../../../components/Separator';

interface PageHeaderProps {
  title: string;
  icon: keyof typeof pictos;
}

const PageHeader = ({ title, icon }: PageHeaderProps) => {
  return (
    <div className="PageHeader">
      <div className="PageHeader__header">
        {icon ? <Picto iconKey={icon} /> : null}
        <h1>{title}</h1>
      </div>
      <Separator />
    </div>
  );
};

export default PageHeader;
