import * as React from 'react';
import { Picto } from '../Picto';

export interface StarNoteProps {
  note: number;
  show_note?: boolean;
  callback?: (p: number) => void;
}

const StarNote = ({ note, show_note = true, callback }: StarNoteProps) => {
  const note_span = show_note ? <span>{note}</span> : null;

  return (
    <div className="starNote">
      <div className="starList">
        {new Array(5).fill('').map((_m, i) => (
          <div
            className={`star ${i + 1 <= Math.round(note) ? 'full' : ''}`}
            key={`star_${i}`}
            onClick={() => callback && callback(i + 1)}
            nonce="__nonce__"
            style={callback ? { cursor: 'pointer' } : {}}
          >
            <Picto iconKey={'star'} />
          </div>
        ))}
      </div>
      {note_span}
    </div>
  );
};

export default StarNote;
