import React, { useState } from 'react';
import {
  ContentRenderer,
  Popover as ReactTinyPopover,
  PopoverAlign,
  PopoverPosition,
} from 'react-tiny-popover';
import { Picto, pictos } from '../Picto';

export interface PopoverProps {
  picto?: keyof typeof pictos;
  positions?: PopoverPosition | PopoverPosition[];
  align?: PopoverAlign;
  className?: string;
  children: ContentRenderer | JSX.Element;
}

const Popover: React.FC<PopoverProps> = ({
  picto = 'dotsV2',
  positions = ['bottom', 'top', 'left', 'right'],
  align = 'end',
  className,
  children,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <div className={`Popover ${className ?? ''}`}>
      <ReactTinyPopover
        isOpen={isPopoverOpen}
        positions={positions}
        content={children}
        align={align}
        onClickOutside={() => setIsPopoverOpen(false)}
        containerClassName="Popover__container"
      >
        <div className="Popover__button" onClick={() => setIsPopoverOpen((p) => !p)}>
          <Picto iconKey={picto} />
        </div>
      </ReactTinyPopover>
    </div>
  );
};

export default Popover;
