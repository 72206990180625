import React from 'react';
import { Picto } from '../Picto';

export interface CheckboxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, id: baseId, ...rest }) => {
  const id = baseId ?? Math.random().toString(36).substr(2, 9);

  return (
    <div className="Checkbox">
      <input name={id} type="checkbox" id={id} {...rest} />
      <div className="Checkbox__box" onClick={() => document.getElementById(id).click()}>
        <div className="Checkbox__box__checkmark">
          <Picto iconKey="check" />
        </div>
      </div>
      {!!label ? (
        <label htmlFor={id} nonce="__nonce__" dangerouslySetInnerHTML={{ __html: label }} />
      ) : null}
    </div>
  );
};

export default Checkbox;
