import React, { Fragment, useEffect, useState } from 'react';
import ButtonV2 from '../../../../components/ButtonV2';
import { useTranslation } from '../../../../tools/i18n';
import { useUser } from '../../../auth/store';
import { getFavoritesActions, useFavoritesGroups } from '../../store';
import AddToFavoritesModal from '../AddToFavoritesModal';
import { Favorite } from '../types';

const { deleteFavorite } = getFavoritesActions;

export interface FavoriteButtonProps {
  favorite: Favorite;
  withoutText?: boolean;
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({ favorite, withoutText }) => {
  const { t } = useTranslation();
  const favoritesGroups = useFavoritesGroups();
  const [currentFavoriteId, setCurrentFavoriteId] = useState('');
  const [isFavoritesModalOpen, setIsFavoritesModalOpen] = useState(false);
  const user = useUser();

  useEffect(() => {
    const getCurrentFavorite = (favorite: Favorite) => {
      for (const group of favoritesGroups || []) {
        for (const f of group?.favorites || []) {
          if (
            (f.type === 'article' &&
              favorite.type === 'article' &&
              f?.data?.article_id?.replace('drafts.', '') ===
                favorite?.data?.article_id?.replace('drafts.', '')) ||
            (f.type === 'engine' &&
              favorite.type === 'engine' &&
              f?.data?.chat_id === favorite?.data?.chat_id) ||
            (f.type === 'shop' &&
              favorite.type === 'shop' &&
              f?.data?.shop_id === favorite?.data?.shop_id)
          ) {
            return f;
          }
        }
      }

      return null;
    };

    const foundFavorite = getCurrentFavorite(favorite);
    setCurrentFavoriteId(foundFavorite?.id || null);
  }, [favorite, favoritesGroups]);

  const handleAddToFavorites = () => {
    setIsFavoritesModalOpen(true);
  };

  const handleRemoveFromFavorites = () => {
    deleteFavorite(currentFavoriteId);
  };

  if (!user) return null;

  return (
    <Fragment>
      <AddToFavoritesModal
        favorite={favorite}
        isOpen={isFavoritesModalOpen}
        onClose={() => setIsFavoritesModalOpen(false)}
      />
      <ButtonV2
        text={
          withoutText
            ? undefined
            : t(`ssrComponents.buttons.favorite.${!!currentFavoriteId ? 'remove' : 'add'}`)
        }
        variant={withoutText ? 'clear' : 'outline'}
        leftIcon="heartV2"
        className={`FavoriteButton ${!!currentFavoriteId ? 'ButtonV2--filledIcon' : ''} ${
          withoutText ? 'FavoriteButton--withoutText' : ''
        }`}
        onClick={!!currentFavoriteId ? handleRemoveFromFavorites : handleAddToFavorites}
      />
    </Fragment>
  );
};

export default FavoriteButton;
