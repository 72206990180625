import { initLightboxJS, SlideshowLightbox, SlideshowLightboxProps } from 'lightbox.js-react';
import React, { useEffect, useState } from 'react';

interface ClientSideSlideshowLightboxProps extends SlideshowLightboxProps {}

const LIGHTBOX_LICENSE_KEY = 'F5B7-8EBF-B588-0523';

const ClientSideSlideshowLightbox: React.FC<ClientSideSlideshowLightboxProps> = (props) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    initLightboxJS(LIGHTBOX_LICENSE_KEY, 'individual');
  }, []);

  if (!isClient) return null;

  return (
    <SlideshowLightbox
      lightboxIdentifier="lightbox1"
      className="SlideshowLightbox"
      showThumbnails
      imgElemClassname="slideshow-img"
      licenseKey={LIGHTBOX_LICENSE_KEY}
      showSlideshowIcon={false}
      {...props}
    />
  );
};

export default ClientSideSlideshowLightbox;
