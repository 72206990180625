import React from 'react';
import { PictoProps } from '.';

export const TravelMediumMartinique = (props: PictoProps) => (
  <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.3487 62.9937C46.3445 62.9937 52.0157 60.4732 52.0157 57.3639C52.0157 54.2547 46.3445 51.7341 39.3487 51.7341C32.3529 51.7341 26.6816 54.2547 26.6816 57.3639C26.6816 60.4732 32.3529 62.9937 39.3487 62.9937Z"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6816 65.8087V74.2534C26.6816 77.3639 32.3537 79.8832 39.3487 79.8832C46.3437 79.8832 52.0157 77.3639 52.0157 74.2534V65.8087"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6816 57.3638V65.8085C26.6816 68.919 32.3537 71.4383 39.3487 71.4383C46.3437 71.4383 52.0157 68.919 52.0157 65.8085V57.3638"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.2383 62.9936C63.2333 62.9936 68.9053 60.4743 68.9053 57.3638V48.9191"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.4598 64.3731C16.1263 64.0634 11.2002 61.6708 11.2002 58.7714V50.3267"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.4598 55.9282C16.1263 55.6185 11.2002 53.2259 11.2002 50.3265V41.8818"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.2383 71.4383C63.2333 71.4383 68.9053 68.919 68.9053 65.8085V57.3638"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.2383 54.5489C63.2333 54.5489 68.9053 52.0296 68.9053 48.9191V40.4744"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.5713 40.4744V47.7791"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.5713 32.0297V40.4744C43.5713 43.5849 49.2433 46.1042 56.2383 46.1042C63.2334 46.1042 68.9054 43.5849 68.9054 40.4744V32.0297"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.2383 37.6596C63.2341 37.6596 68.9054 35.1391 68.9054 32.0298C68.9054 28.9206 63.2341 26.4 56.2383 26.4C49.2425 26.4 43.5713 28.9206 43.5713 32.0298C43.5713 35.1391 49.2425 37.6596 56.2383 37.6596Z"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.8672 47.5119C30.863 47.5119 36.5343 44.9914 36.5343 41.8821C36.5343 38.7729 30.863 36.2523 23.8672 36.2523C16.8714 36.2523 11.2002 38.7729 11.2002 41.8821C11.2002 44.9914 16.8714 47.5119 23.8672 47.5119Z"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.5342 41.8818V47.779"
      stroke="#27A19E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
