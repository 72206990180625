import React from "react";
import MiniCarousel from "../MiniCarousel";
import {Configuration} from "../../tools/Constants";
import {RequirementsProps} from "../../tools/context";

export interface ListCategoryProps {
  label: string
  subLabel: string
  requirements: RequirementsProps
}

const ListCategory = (props: ListCategoryProps) => {

  const categories = Configuration.categories;

  return <div className="listCategory">
    <MiniCarousel
    label={props.label}
    subLabel={props.subLabel}
    list={Object.keys(categories)
      .filter(ca => !categories[ca].disabled)
      .map(c => ({
        _key: c,
        link: `/results?categ=${c}`,
        staticImage: `https://tourismebyca.twic.pics/static/tca/v2/${c}.png`,
        name: categories[c].name,
      }))}
    requirements={props.requirements}
  />
  </div>
}

export default ListCategory;