import useEmblaCarousel from 'embla-carousel-react';
import React, { useCallback, useEffect, useState, lazy } from 'react';
import { Favorite } from '../../features/favorites/components/types';
import { RequirementsProps } from '../../tools/context';
import { getSanityClient, imageUrlFor } from '../../tools/sanity';
import { ArrowCharentes, Infinite, InfiniteHeart, StarCharentes } from '../Picto/charentes/Icons';


const FavoriteButton= lazy(()  => import('../../features/favorites/components/Button'))

const getThemeIcon = (iconKey?: string) => {
  switch (iconKey) {
    case 'Infinite':
      return <Infinite />;
    case 'star-charentes':
      return <StarCharentes />;
    case 'infinite-heart':
      return <InfiniteHeart />;
    default:
      return <InfiniteHeart />;
  }
};

interface SubtitleType {
  text?: string;
  isLink: boolean;
  url?: string;
}

interface SlideType {
  image?: {
    backgroundColor: string;
    asset: any;
    alt?: string;
    opacity?: number;
    opacityColor?: 'white' | 'black';
  };
  title?: string;
  subtitle?: SubtitleType;
  cssClass?: string;
}

interface CarouselLinkType {
  text?: string;
  url?: string;
}

interface StyleOptionsType {
  backgroundColor?: { hex: string };
  padding?: string;
  margin?: string;
  showNumbers?: boolean;
  fullscreen?: boolean;
}

interface EditoCarouselProps {
  title: {
    text: string;
    color?: {
      hex: string;
    };
  };
  theme?: {
    text: string;
    icon?: string;
    color?: {
      hex: string;
    };
  };
  link?: CarouselLinkType;
  slides: SlideType[];
  requirements: RequirementsProps;
  styleOptions?: StyleOptionsType;
  align?: 'start' | 'center' | 'end';
  axis?: 'x' | 'y';
  direction?: 'ltr' | 'rtl';
  loop?: boolean;
  startIndex?: number;
  componentId?: string;
}

const PrevButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <button className="embla__prev" onClick={onClick}>
    <ArrowCharentes />
  </button>
);

const NextButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <button className="embla__next" onClick={onClick}>
    <ArrowCharentes />
  </button>
);
type GetFavoriteFromUrlFn = (url: string, requirements: RequirementsProps) => Promise<Favorite | undefined>;
const getFavoriteFromUrl: GetFavoriteFromUrlFn = async (url: string, requirements) => {
  if (url.includes('article/')) {
    return {
      type: 'article',
      data: {
        article_id: await getArticleIdFromUrl(url,requirements ),
      },
    };
  }

  return undefined;
};

type GetArticleIdFromUrlFn = (url: string , requirements: RequirementsProps) => Promise<string | null>;
const getArticleIdFromUrl: GetArticleIdFromUrlFn = async (url, requirements) => {
  try {
    const fullUrl = url.startsWith('http')
      ? url
      : `${window.location.origin}${url.startsWith('/') ? '' : '/'}${url}`;

    const urlT = new URL(fullUrl);
    const slug = 'article/' + urlT.pathname.split('/').pop();
    const query = `*[_type == 'article' && slug.current == '${slug}']`;
    const client = getSanityClient(requirements);
    const article = await client.fetch(query);

    return article[0]?._id || null;
  } catch (error) {
    console.warn('Invalid URL:', url);
    return null;
  }
};

export default function EditoCarousel({
  title,
  theme,
  link,
  slides = [],
  requirements,
  styleOptions,
  align = 'center',
  axis = 'x',
  direction = 'ltr',
  loop = false,
  startIndex = 0,
  componentId
}: EditoCarouselProps) {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align,
    axis,
    direction,
    loop,
    startIndex,
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => {
    emblaApi?.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    emblaApi?.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
    return () => {
      emblaApi.off('select', onSelect);
      emblaApi.off('reInit', onSelect);
    };
  }, [emblaApi, onSelect]);

  const [, setSlideWidth] = useState(0);

  useEffect(() => {
    const updateSlideWidth = () => {
      if (emblaApi) {
        const slideNode = emblaApi.slideNodes()[0];
        setSlideWidth(slideNode?.offsetWidth || 0);
      }
    };

    updateSlideWidth();
    window.addEventListener('resize', updateSlideWidth);

    return () => window.removeEventListener('resize', updateSlideWidth);
  }, [emblaApi]);

  const carouselClass = `editoCarousel${styleOptions?.fullscreen ? '--fullScreen' : ''}`;

  const RenderFavoriteButton = ({ url }: { url: string }) => {
    const [data, setData] = useState<Favorite | undefined>(undefined);

    useEffect(() => {
      getFavoriteFromUrl(url, requirements).then((res) => setData(res));
    }, [url]);

    if (!data) return null;

    return <FavoriteButton favorite={data} withoutText />;
  };

  return (
    <section
      className={carouselClass}
      id={`edito-carousel__${(componentId || title?.text)?.replace(/\s/g, '-').toLowerCase()}`}
      style={{
        backgroundColor: styleOptions?.backgroundColor?.hex ?? 'transparent',
        padding: styleOptions?.padding,
        margin: styleOptions?.margin ?? '0',
      }}
    >
      <div className={`${carouselClass}__header`}>
        <h2 style={theme?.color ? { color: theme.color.hex } : undefined}>
          {getThemeIcon(theme?.icon)}
          {theme?.text ?? 'Thème de l\'édito'}
        </h2>
        <h3 style={title?.color ? { color: title.color.hex } : undefined}>
          {title?.text ?? 'Titre de l\'édito'}
        </h3>
        {link?.text &&
          (link?.url ? (
            <a href={link.url} className={`${carouselClass}__header__link`}>
              {link.text}
            </a>
          ) : (
            <span className={`${carouselClass}__header__link`}>{link.text}</span>
          ))}
      </div>

      <div className={`${carouselClass}__content`}>
        <div className={`${carouselClass}__carousel embla`} ref={emblaRef}>
          <div className="embla__container">
            {Array.isArray(slides) && slides.length > 0 ? (
              slides.map((slide, index) => (
                <div
                  key={index}
                  className={`embla__slide ${slide.cssClass || ''}`}
                >
                  <div 
                    className="embla__slide__background-container"
                    style={{
                      '--overlay-color': slide?.image?.opacityColor === 'white' 
                        ? `rgba(255, 255, 255, ${slide?.image?.opacity || 0})`
                        : `rgba(0, 0, 0, ${slide?.image?.opacity || 0})`
                    } as React.CSSProperties}
                  >
                  {slide.image?.asset ? (
                    styleOptions?.fullscreen ? (
                      <img
                        className="embla__slide__background"
                        loading="lazy"
                        srcSet={`${imageUrlFor(slide.image.asset, requirements)
                          .width(400)
                          .format('webp')
                          .url()} 400w, 
                          ${imageUrlFor(slide.image.asset, requirements)
                            .width(800)
                            .format('webp')
                            .url()} 800w,
                            ${imageUrlFor(slide.image.asset, requirements)
                              .width(1200)
                              .format('webp')
                              .url()} 1200w,
                          `}
                          sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1200px"
                          src={imageUrlFor(slide.image.asset, requirements)
                            .height(500)
                            .format('webp')
                            .url()}
                        alt={slide.image?.alt}
                      />
                    ) : (
                      <img
                        className="embla__slide__background"
                        style={{
                          '--overlay-color': slide?.image?.opacityColor === 'white' 
                            ? `rgba(255, 255, 255, ${slide?.image?.opacity || 0})`
                            : `rgba(0, 0, 0, ${slide?.image?.opacity || 0})`
                        } as React.CSSProperties}
                        loading="lazy"
                        srcSet={`${imageUrlFor(slide.image.asset, requirements)
                          .width(400)
                          .format('webp')
                          .url()} 400w, 
                          ${imageUrlFor(slide.image.asset, requirements)
                            .width(600)
                            .format('webp')
                            .url()} 600w, 
                            ${imageUrlFor(slide.image.asset, requirements)
                              .width(800)
                              .format('webp')
                              .url()} 800w,
                            `}
                          sizes="(max-width: 400px) 400px, max-width: 600px) 600px,(max-width: 800px) 800px,800px"
                          src={imageUrlFor(slide.image.asset, requirements)
                            .height(500)
                            .format('webp')
                            .url()}
                          alt={slide.image?.alt}
                      />
                    )
                  ) : (
                    <div className="embla__slide__no-image">
                      Aucune image disponible
                    </div>
                  )}
                  </div>

                  <div className="embla__slide__content">
                    {styleOptions?.showNumbers && (
                      <div className="embla__numbers" style={{ textAlign: 'center' }}>
                        {index + 1} / {slides.length}
                      </div>
                    )}
                    <p className="embla_slide_title">{slide.title ?? 'Titre de la slide'}</p>
                    <a
                      href={slide.subtitle?.url ? slide.subtitle?.url : '#'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="embla_slide_subtitle">
                        {slide.subtitle?.text ?? 'Lire l\'article'}
                      </p>
                    </a>
                  </div>
                  <RenderFavoriteButton url={slide.subtitle?.url ?? ''} />
                </div>
              ))
            ) : (
              <div className="embla__slide">
                <div className="embla__slide__content">
                  <p className="embla_slide_title">Aucune slide disponible</p>
                  <p className="embla_slide_subtitle">
                    Ajoutez des contenus pour afficher le carrousel.
                  </p>
                </div>
              </div>
            )}
          </div>
          {prevBtnEnabled && <PrevButton onClick={scrollPrev} />}
          {nextBtnEnabled && <NextButton onClick={scrollNext} />}
        </div>
      </div>
    </section>
  );
}
