import React from 'react';
import {RequirementsProps} from "../../tools/context";
import {Picto} from "../Picto";
import {LinkProps} from "../index";

export interface NavigationProps {
  requirements: RequirementsProps
  closeCallback: () => void|undefined
  navigation: LinkProps[]
}


const Navigation = (props: NavigationProps) => {
  return <div className="navigation">
    <button className="close" onClick={props.closeCallback}>
      <Picto iconKey={"cross"} />
    </button>
    <div className="list">
      {props.navigation.map((n, i) => <a
        key={`navigation_${i}`}
        className={`line ${props.requirements.path === n.link ? 'current' : ''}`}
        href={n.link}>
        {n.picto && <Picto iconKey={n.picto} />}
        {n.text}
        {props.requirements.path === n.link && <div className={'separator'} />}
      </a>)}
    </div>
  </div>
}

export default Navigation;