// Map of language codes to language names for display purposes (e.g. in the language selector) => 70 languages
export const languageMap: Record<string, string> = {
  en: 'English',
  fr: 'Français',
  es: 'Español',
  de: 'Deutsch',
  it: 'Italiano',
  ja: '日本語',
  zh: '中文',
  ko: '한국어',
  ru: 'Русский',
  ar: 'العربية',
  pt: 'Português',
  'pt-br': 'Português (Brasil)',
  da: 'Dansk',
  cs: 'Čeština',
  nl: 'Nederlands',
  sv: 'Svenska',
  no: 'Norsk',
  pl: 'Polski',
  tr: 'Türkçe',
  hu: 'Magyar',
  fi: 'Suomi',
  vi: 'Tiếng Việt',
  el: 'Ελληνικά',
  ro: 'Română',
  fa: 'فارسی',
  he: 'עברית',
  hi: 'हिन्दी',
  is: 'Íslenska',
  th: 'ไทย',
  id: 'Bahasa Indonesia',
  ms: 'Bahasa Melayu',
  tl: 'Tagalog',
  bn: 'বাংলা',
  ur: 'اردو',
  ne: 'नेपाली',
  my: 'ဗမာ',
  km: 'ភាសាខ្មែរ',
  lo: 'ລາວ',
  kn: 'ಕನ್ನಡ',
  ml: 'മലയാളം',
  te: 'తెలుగు',
  ta: 'தமிழ்',
  mr: 'मराठी',
  gu: 'ગુજરાતી',
  pa: 'ਪੰਜਾਬੀ',
  sa: 'संस्कृतम्',
  ka: 'ქართული',
  am: 'አማርኛ',
  or: 'ଓଡ଼ିଆ',
  si: 'සිංහල',
  uk: 'Українська',
  bg: 'Български',
  sr: 'Српски',
  mk: 'Македонски',
  et: 'Eesti',
  lv: 'Latviešu',
  lt: 'Lietuvių',
  sk: 'Slovenčina',
  sl: 'Slovenščina',
  hr: 'Hrvatski',
  bs: 'Bosanski',
  sq: 'Shqip',
  cy: 'Cymraeg',
  ga: 'Gaeilge',
  gl: 'Galego',
  af: 'Afrikaans',
  mt: 'Malti',
  eu: 'Euskara',
  az: 'Azərbaycanca',
  kk: 'Қазақ тілі',
  ky: 'Кыргызча',
  uz: 'O‘zbek',
  tk: 'Türkmen',
  mn: 'Монгол',
  ps: 'پښتو',
  sd: 'سنڌي',
  su: 'Basa Sunda',
};
