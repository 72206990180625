import React from 'react';
import { PictoProps } from './index';

export const Facebook = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_56_2206)">
      <path
        d="M23.633 11.8165C23.633 5.29043 18.3426 0 11.8165 0C5.29043 0 0 5.29043 0 11.8165C0 17.7144 4.32111 22.603 9.97018 23.4895V15.2322H6.9699V11.8165H9.97018V9.21319C9.97018 6.25167 11.7344 4.61583 14.4335 4.61583C15.7259 4.61583 17.0786 4.84662 17.0786 4.84662V7.75459H15.5886C14.1207 7.75459 13.6628 8.66552 13.6628 9.60092V11.8165H16.9401L16.4162 15.2322H13.6628V23.4895C19.3119 22.603 23.633 17.7144 23.633 11.8165Z"
        fill={fill || 'white'}
      />
    </g>
    <defs>
      <clipPath id="clip0_56_2206">
        <rect width="23.633" height="23.633" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
