import React from 'react';
import { Picto } from '../Picto';
import PictoButton from '../TravelPlanerResults/PictoButton';

interface FloatingSearchBarButtonProps {
  isFocus: boolean;
  query: string;
  onSubmit: (query: string)=>void;
  isDefault?: boolean;
}

const FloatingSearchBarButton: React.FC<FloatingSearchBarButtonProps> = ({ isFocus, isDefault, query, onSubmit }) => {
  if (isDefault) {
    return(
      <div className="floatingSearchBar-center" onClick={() => onSubmit(query) }>
        <Picto iconKey={'arrow-right'} />
      </div>
    );
  }

  return (
    <>
      {isFocus ? (
        <PictoButton
          iconKey="arrow-right"
          className="floatingSearchBar-center"
          onClick={() => onSubmit(query)}
        />
      ) : (
        <Picto iconKey={'arrow-right'} className="floatingSearchBar-center" />
      )}
    </>
  );}

export default FloatingSearchBarButton;
