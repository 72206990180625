import React, { useState } from 'react';
import ButtonV2 from '../../../../components/ButtonV2';
import { useTranslation } from '../../../../tools/i18n';
import NewFavoritesGroupModal from '../NewGroupModal';

export interface NewFavoritesGroupButtonProps {}

const NewFavoritesGroupButton: React.FC<NewFavoritesGroupButtonProps> = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="NewFavoritesGroupButton">
      <ButtonV2
        className="NewFavoritesGroupButton__button"
        text={t('ssrComponents.favorites.newGroup')}
        leftIcon="plus"
        onClick={() => setIsModalOpen(true)}
      />
      <NewFavoritesGroupModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default NewFavoritesGroupButton;
