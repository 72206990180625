import React from 'react';
import { useTranslation } from '../../../../tools/i18n';
import { TextListStoreItem } from '../../../../tools/providersAPI';

const TextListStoreItemComp: React.FC<TextListStoreItem> = ({ texts, withBullets }) => {
  const { t } = useTranslation();
  const translatedTexts = texts.map((text) =>
    text?.replace(/{{t:(.*?)}}/g, (match, key) => t(`shopV2.groupsCustom.texts.${key}`))
  );

  return (
    <div
      className={`TextListStoreItemComp ${withBullets ? 'TextListStoreItemComp--withBullets' : ''}`}
    >
      <ul>
        {translatedTexts.map((text, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: text }} />
        ))}
      </ul>
    </div>
  );
};

export default TextListStoreItemComp;
