import React from 'react';
import ButtonV2 from '../../ButtonV2';
import { Picto, pictos } from '../../Picto';

export interface DetailShopV2Props {
  icon?: keyof typeof pictos;
  title: string;
  value?: string;
  link?: {
    href: React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >['href'];
    target?: React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >['target'];
  };
  children?: React.ReactNode;
}

const DetailShopV2: React.FC<DetailShopV2Props> = ({ icon, title, value, link, children }) => {
  if (!!!title && !!!value && !!!children) return null;

  return (
    <div className="DetailShopV2" id={title}>
      <div className="DetailShopV2__header">
        <div className="DetailShopV2__header__iconContainer">
          {icon ? <Picto iconKey={icon} /> : null}
        </div>
        <p className="DetailShopV2__title">{title}</p>
      </div>
      {!!value ? (
        link ? (
          <ButtonV2
            className="DetailShopV2__value"
            link={link.href}
            linkTarget={link.target}
            text={value}
            align="left"
            variant="clear"
          />
        ) : (
          <p className="DetailShopV2__value">{value}</p>
        )
      ) : null}
      {children}
    </div>
  );
};

export default DetailShopV2;
