import React from 'react';

import { Address } from './Address';
import { AdvantagePromo } from './AdvantagePromo';
import { AiMention } from './AiMention';
import { Alimentaire } from './Alimentaire';
import { Apple } from './Apple';
import { ArmorAlimentaire } from './armor/Alimentaire';
import { ArmorATable } from './armor/Atable';
import { ArmorLoisirs } from './armor/Loisirs';
import { ArmorProducteur } from './armor/Producteur';
import { ArrowDown } from './ArrowDown';
import { ArrowLeft } from './ArrowLeft';
import { ArrowLeftV2 } from './ArrowLeftV2';
import { ArrowRight } from './ArrowRight';
import { ArrowRightV2 } from './ArrowRightV2';
import { ArrowUp } from './ArrowUp';
import { ATable } from './aTable';
import { Backpack } from './Backpack';
import { BeauteSante } from './BeauteSante';
import { Bike } from './Bike';
import { Bin } from './Bin';
import { Bug } from './Bug';
import { BurgerMenu } from './BurgerMenu';
import { BurgerMenu2 } from './BurgerMenu2';
import { Bus } from './Bus';
import { Calendar } from './Calendar';
import { Car } from './Car';
import { CaretDown } from './CaretDown';
import { CaretLeft } from './CaretLeft';
import { CaretRight } from './CaretRight';
import { CaretRightFilled } from './CaretRightFilled';
import { CaretUp } from './CaretUp';
import { ArrowCharentes, Bell, FavCharentes, Infinite, StarCharentes } from './charentes/Icons';
import { ChatIcon } from './ChatIcon';
import { Check } from './Check';
import { ChevronDown } from './ChevronDown';
import { CircleArrowRight } from './CircleArrowRight';
import { CircleCheck } from './CircleCheck';
import { CircleCross } from './CircleCross';
import { CircleI } from './CircleI';
import { CirclePercent } from './CirclePercent';
import { CirclePlay } from './CirclePlay';
import { City } from './City';
import { Clip } from './Clip';
import { Clock } from './Clock';
import { Commerce } from './Commerce';
import { Copy } from './Copy';
import { CopyCheckV2 } from './CopyCheckV2';
import { CopyV2 } from './CopyV2';
import { Country } from './Country';
import { Cross } from './Cross';
import { Cursors } from './Cursors';
import { Cutlery } from './Cutlery';
import { Direction } from './Direction';
import { DislikeCircle } from './DislikeCircle';
import { DislikeCircleFilled } from './DislikeCircleFilled';
import { Dots } from './Dots';
import { DotsV2 } from './DotsV2';
import { Download } from './Download';
import { Edit } from './Edit';
import { Envelope } from './Envelope';
import { EnvelopeNewsletter } from './EnvelopeNewsletter';
import { EnvelopeV2 } from './EnvelopeV2';
import { EtoileVerteMichelin } from './EtoileVerteMichelin';
import { Euro } from './Euro';
import { EuroV2 } from './EuroV2';
import { Facebook } from './Facebook';
import { FacebookV2 } from './FacebookV2';
import { Fav } from './Fav';
import { Flag } from './Flag';
import { Arrow } from './fr/Arrow';
import { BackPacker } from './fr/BackPacker';
import { Bed } from './fr/Bed';
import { Burger } from './fr/Burger';
import { Calendar as FranceCalendar } from './fr/Calendar';
import { Dates } from './fr/Dates';
import { Itinerary } from './fr/Itinerary';
import { LocationFr } from './fr/Location';
import { Periods } from './fr/Periods';
import { Place as FrancePlace } from './fr/Place';
import { Runner } from './fr/Runner';
import { SearchFr } from './fr/Search';
import { Trash } from './fr/Trash';
import { TravelEcoFr } from './fr/TravelEco';
import { TravelExpensiveFr } from './fr/TravelExpensive';
import { TravelMediumFr } from './fr/TravelMedium';
import { Walker } from './fr/Walker';
import { GiftCard } from './GiftCard';
import { Google } from './Google';
import { GuideIcon } from './GuideIcon';
import { HeartIcon } from './Heart';
import { HeartV2 } from './HeartV2';
import { HowtoAdvantage1 } from './HowtoAdvantage1';
import { HowtoAdvantage2 } from './HowtoAdvantage2';
import { HowtoAdvantage3 } from './HowtoAdvantage3';
import { InfiniteHeart } from './InfiniteHeart';
import { Info } from './Info';
import { Instagram } from './Instagram';
import { InstagramV2 } from './InstagramV2';
import { LesGrandesTablesDuMonde } from './LesGrandesTablesDuMonde';
import { Like } from './Like';
import { LikeCircle } from './LikeCircle';
import { LikeCircleFilled } from './LikeCircleFilled';
import { List } from './List';
import { Loader } from './Loader';
import { Location } from './Location';
import { Logo } from './Logo';
import { Loisirs } from './loisirs';
import MagnifyingGlass from './MagnifyingGlass';
import { MaisonJardin } from './MaisonJardin';
import { MaitresRestaurateurs } from './MaitresRestaurateurs';
import { MapPin } from './MapPin';
import { Message } from './Message';
import { Minus } from './Minus';
import { Mode } from './Mode';
import { Monuments } from './monuments';
import { Navigation } from './Navigation';
import { OuSejourner } from './ouSejourner';
import { PadlockClosed } from './PadlockClosed';
import { PadlockOpen } from './PadlockOpen';
import { Pedestrian } from './Pedestrian';
import { Pen } from './Pen';
import { Phone } from './Phone';
import { PhoneV2 } from './PhoneV2';
import { Pinterest } from './Pinterest';
import { Place } from './Place';
import { Plus } from './Plus';
import { Printer } from './Printer';
import { Producteurs } from './producteurs';
import { Quote } from './Quote';
import { Region } from './Region';
import { RelaisEtChateaux } from './RelaisEtChateaux';
import { Rename } from './Rename';
import { RotateRight } from './RotateRight';
import { Schedules } from './Schedules';
import { Search } from './Search';
import { Settings } from './Settings';
import { Share } from './Share';
import { ShareV2 } from './ShareV2';
import { Shutdown } from './Shutdown';
import { Sparkles } from './Sparkles';
import { Star } from './Star';
import { SwitchLeft } from './SwitchLeft';
import { SwitchRight } from './SwitchRight';
import { Target } from './Target';
import { Telecharger } from './Telecharger';
import { ThumbBlack } from './thumbBlack';
import { ThumbWhite } from './ThumbWhite';
import { TikTok } from './TikTok';
import { TravelCouple } from './TravelCouple';
import { TravelCoupleV2 } from './TravelCoupleV2';
import { TravelEco } from './TravelEco';
import { TravelEcoMartinique } from './TravelEcoMartinique';
import { TravelEcoNewUI } from './TravelEcoNewUI';
import { TravelEcoNewUIV2 } from './TravelEcoNewUIV2';
import { TravelExpensive } from './TravelExpensive';
import { TravelExpensiveMartinique } from './TravelExpensiveMartinique';
import { TravelExpensiveNewUI } from './TravelExpensiveNewUI';
import { TravelExpensiveNewUIV2 } from './TravelExpensiveNewUIV2';
import { TravelFamily } from './TravelFamily';
import { TravelFamilyV2 } from './TravelFamilyV2';
import { TravelFriends } from './TravelFriends';
import { TravelFriendsV2 } from './TravelFriendsV2';
import { TravelMedium } from './TravelMedium';
import { TravelMediumMartinique } from './TravelMediumMartinique';
import { TravelMediumNewUI } from './TravelMediumNewUI';
import { TravelMediumNewUIV2 } from './TravelMediumNewUIV2';
import { TravelSoloV2 } from './TravelSoloV2';
import { Twitter } from './Twitter';
import { User } from './User';
import { UserV2 } from './UserV2';
import { VignoblesEtDecouvertes } from './VignoblesEtDecouvertes';
import { Voyages } from './voyages';
import { Website } from './Website';
import { WhatsApp } from './WhatsApp';
import { Youtube } from './Youtube';
import { YoutubeV2 } from './YoutubeV2';

export const pictos = {
  'a-table': ATable,
  address: Address,
  'advantage-promo': AdvantagePromo,
  alimentaire: Alimentaire,
  apple: Apple,
  'arrow-up': ArrowUp,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'beaute-sante': BeauteSante,
  bike: Bike,
  bin: Bin,
  'burger-menu': BurgerMenu,
  'burger-menu-2': BurgerMenu2,
  calendar: Calendar,
  car: Car,
  'caret-down': CaretDown,
  'caret-left': CaretLeft,
  'caret-right': CaretRight,
  'caret-up': CaretUp,
  'caret-right-filled': CaretRightFilled,
  'chat-icon': ChatIcon,
  check: Check,
  'circle-arrow-right': CircleArrowRight,
  'circle-check': CircleCheck,
  'circle-cross': CircleCross,
  'circle-i': CircleI,
  'circle-percent': CirclePercent,
  'circle-play': CirclePlay,
  city: City,
  clip: Clip,
  clock: Clock,
  commerce: Commerce,
  copy: Copy,
  country: Country,
  cross: Cross,
  cursors: Cursors,
  dots: Dots,
  download: Download,
  envelope: Envelope,
  envelopeNewsletter: EnvelopeNewsletter,
  facebook: Facebook,
  giftCard: GiftCard,
  google: Google,
  'guide-icon': GuideIcon,
  'howto-advantage1': HowtoAdvantage1,
  'howto-advantage2': HowtoAdvantage2,
  'howto-advantage3': HowtoAdvantage3,
  info: Info,
  instagram: Instagram,
  like: Like,
  list: List,
  loader: Loader,
  location: Location,
  logo: Logo,
  loisirs: Loisirs,
  'maison-jardin': MaisonJardin,
  message: Message,
  mode: Mode,
  'monuments-et-musees': Monuments,
  evenementiel: Periods,
  navigation: Navigation,
  'ou-sejourner': OuSejourner,
  'padlock-closed': PadlockClosed,
  'padlock-open': PadlockOpen,
  pedestrian: Pedestrian,
  phone: Phone,
  place: Place,
  printer: Printer,
  'producteurs-et-artisans': Producteurs,
  quote: Quote,
  region: Region,
  search: Search,
  settings: Settings,
  star: Star,
  target: Target,
  'travel-couple': TravelCouple,
  'travel-eco': TravelEco,
  'travel-medium': TravelMedium,
  'travel-expensive': TravelExpensive,
  'travel-eco-new-ui': TravelEcoNewUI,
  'travel-medium-new-ui': TravelMediumNewUI,
  'travel-expensive-new-ui': TravelExpensiveNewUI,
  'travel-eco-new-ui-v2': TravelEcoNewUIV2,
  'travel-medium-new-ui-v2': TravelMediumNewUIV2,
  'travel-expensive-new-ui-v2': TravelExpensiveNewUIV2,
  'travel-family': TravelFamily,
  'travel-friends': TravelFriends,
  twitter: Twitter,
  user: User,
  voyages: Voyages,
  youtube: Youtube,
  'thumb-white': ThumbWhite,
  'thumb-black': ThumbBlack,
  plus: Plus,
  minus: Minus,
  'like-circle': LikeCircle,
  'dislike-circle': DislikeCircle,
  heart: HeartIcon,
  'switch-left': SwitchLeft,
  'switch-right': SwitchRight,
  cutlery: Cutlery,
  share: Share,
  'like-circle-filled': LikeCircleFilled,
  'dislike-circle-filled': DislikeCircleFilled,
  euro: Euro,
  bus: Bus,
  'travel-solo-v2': TravelSoloV2,
  'travel-couple-v2': TravelCoupleV2,
  'travel-family-v2': TravelFamilyV2,
  'travel-friends-v2': TravelFriendsV2,
  flag: Flag,
  periods: Periods,
  dates: Dates,
  'ai-mention': AiMention,
  walker: Walker,
  'back-packer': BackPacker,
  runner: Runner,
  'travel-eco-fr': TravelEcoFr,
  'travel-medium-fr': TravelMediumFr,
  'travel-expensive-fr': TravelExpensiveFr,
  bed: Bed,
  'location-fr': LocationFr,
  'search-fr': SearchFr,
  'arrow-fr': Arrow,
  'trash-fr': Trash,
  'burger-fr': Burger,
  'itinerary-fr': Itinerary,
  'france-calendar': FranceCalendar,
  'france-place': FrancePlace,
  'chevron-down': ChevronDown,
  'rotate-right': RotateRight,
  pen: Pen,
  'a-table-armor': ArmorATable,
  'alimentaire-armor': ArmorAlimentaire,
  'producteurs-et-artisans-armor': ArmorProducteur,
  'loisirs-armor': ArmorLoisirs,
  fav: Fav,
  telecharger: Telecharger,
  'travelEco-martinique': TravelEcoMartinique,
  'travelMedium-martinique': TravelMediumMartinique,
  'travelExpensive-martinique': TravelExpensiveMartinique,
  visite: Walker,
  local_artisans: Producteurs,
  hebergement: OuSejourner,
  direction: Direction,
  phoneV2: PhoneV2,
  heartV2: HeartV2,
  shareV2: ShareV2,
  envelopeV2: EnvelopeV2,
  mapPin: MapPin,
  euroV2: EuroV2,
  website: Website,
  schedules: Schedules,
  arrowLeftV2: ArrowLeftV2,
  arrowRightV2: ArrowRightV2,
  facebookV2: FacebookV2,
  instagramV2: InstagramV2,
  youtubeV2: YoutubeV2,
  tiktok: TikTok,
  pinterest: Pinterest,
  infiniteHeart: InfiniteHeart,
  userV2: UserV2,
  shutdown: Shutdown,
  backpack: Backpack,
  bell: Bell,
  'fav-charentes': FavCharentes,
  'infinite-heart': InfiniteHeart,
  'star-charentes': StarCharentes,
  infinite: Infinite,
  'arrow-charentes': ArrowCharentes,
  dotsV2: DotsV2,
  rename: Rename,
  edit: Edit,
  copyV2: CopyV2,
  copyCheckV2: CopyCheckV2,
  whatsApp: WhatsApp,
  magnifyingGlass: MagnifyingGlass,
  sparkles: Sparkles,
  bug: Bug,
  etoileVerteMichelin: EtoileVerteMichelin,
  maitresRestaurateurs: MaitresRestaurateurs,
  relaisEtChateaux: RelaisEtChateaux,
  lesGrandesTablesDuMonde: LesGrandesTablesDuMonde,
  vignoblesEtDecouvertes: VignoblesEtDecouvertes,
};

export interface PictoProps {
  width?: string;
  height?: string;
  text?: string;
  fill?: string;
  className?: string;
}

export interface PictoWrapperProps {
  iconKey: keyof typeof pictos | string;
  width?: string;
  height?: string;
  text?: string;
  fill?: string;
  className?: string;
}

export const Picto = ({ iconKey, ...pictoProps }: PictoWrapperProps) => {
  const PictoComponent = pictos[iconKey];
  if (!PictoComponent) return null;

  return <PictoComponent {...pictoProps} />;
};
