import React, {
  createContext,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useWindowSize } from '../../tools/hooks';

interface StickyItems {
  [id: string]: boolean;
}

export const StickyContext = createContext({
  stickyItems: {}, // { [refId]: boolean }
  registerStickyItem: (id: keyof StickyItems, ref: Ref<any>) => {},
  unregisterStickyItem: (id: keyof StickyItems) => {},
});

export const useSticky = () => {
  const context = useContext(StickyContext);

  if (!context) {
    throw new Error('useSticky must be used within a StickyHeaderManager provider');
  }

  return context;
};

export const StickyHeaderManager = ({ children }) => {
  const [stickyItems, setStickyItems] = useState<StickyItems>();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const itemsRef = useRef({}); // { id: {ref: refObject, position: 'left'|'right'} }
  const headerLogoRef = useRef<HTMLElement>(null);
  const headerInsert2Ref = useRef<HTMLElement>(null);
  const headerInsert2ContainerRef = useRef<HTMLElement>(null);

  const registerStickyItem = useCallback((id: string, ref: Ref<any>) => {
    itemsRef.current[id] = { ref, position: 'left' };
  }, []);

  const unregisterStickyItem = useCallback((id: string) => {
    const updated = { ...itemsRef.current };
    delete updated[id];
    itemsRef.current = updated;
    setStickyItems((prev) => {
      const { [id]: _, ...rest } = prev;
      return rest;
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // const header = document.getElementById('modern-header');
      // const headerHeight = header?.clientHeight || 0;
      const forcedHeaderHeight = 70;

      const newStickyState = { ...stickyItems };

      // Check each registered item
      for (const id in itemsRef.current) {
        const { ref } = itemsRef.current[id];
        const el = ref.current;
        if (!el) continue;

        const rect = el.getBoundingClientRect();
        // If the bottom of the element is <= headerHeight, it’s under the header
        newStickyState[id] = rect.bottom <= forcedHeaderHeight;
      }

      // Update state only if something changed
      // This avoids unnecessary re-renders
      const hasChange = Object.keys(newStickyState).some(
        (key) => newStickyState?.[key] !== stickyItems?.[key]
      );
      if (hasChange) {
        setStickyItems(newStickyState);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [stickyItems, windowHeight, windowWidth]);

  // Show/hide the header logo:
  // If any item is sticky, we hide the logo on small screens
  useEffect(() => {
    if (!headerLogoRef.current) return;

    const anySticky = Object.values(stickyItems || {}).some(Boolean) || false;

    if (windowWidth > 480 || !anySticky) {
      headerLogoRef.current.style.display = 'block';
    } else {
      headerLogoRef.current.style.display = 'none';
    }

    if (headerInsert2Ref.current?.children?.length) {
      headerInsert2ContainerRef.current.style.display = 'flex';
    } else {
      headerInsert2ContainerRef.current.style.display = 'none';
    }
  }, [stickyItems, windowWidth]);

  return (
    <StickyContext.Provider value={{ stickyItems, registerStickyItem, unregisterStickyItem }}>
      {children}
    </StickyContext.Provider>
  );
};
