import React from 'react';
import { PictoProps } from './index';

export const ShareV2 = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '18'}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1982 5.44272L10.2852 1.17395L6.37215 5.44272"
      stroke={fill || 'white'}
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2852 1.17395V11.1344"
      stroke={fill || 'white'}
      strokeWidth="1.44"
      strokeLinecap="round"
    />
    <path
      d="M1.3916 11.1344V14.8625C1.3916 15.9469 2.27075 16.8261 3.35524 16.8261H16.5031C17.5876 16.8261 18.4667 15.9469 18.4667 14.8625V11.1344"
      stroke={fill || 'white'}
      strokeWidth="1.44"
      strokeLinecap="round"
    />
  </svg>
);
