import React from 'react';
import ButtonV2 from '../ButtonV2';
import Checkbox, { CheckboxProps } from '../Checkbox';
import Input from '../Input';
import { Picto, pictos } from '../Picto';
import Radio, { RadioProps } from '../Radio';
import Separator from '../Separator';

type Field = InputField | CheckboxField | RadioField | FormGroupField;

interface InputField extends React.InputHTMLAttributes<HTMLInputElement> {
  type: 'text' | 'email' | 'password' | 'number';
  label?: string;
  error?: string;
  isOptional?: boolean;
}

interface CheckboxField extends CheckboxProps {
  type: 'checkbox';
}

interface RadioField extends RadioProps {
  type: 'radio';
}

interface FormGroupField {
  type: 'group';
  title?: string;
  icon?: keyof typeof pictos;
  fields: Field[];
}

export interface FormProps {
  title?: string;
  subtitle?: string;
  fields?: Field[];
  values?: { [key: string]: string | number | boolean };
  errors?: { [key: string]: string };
  onChange?: (data: { [key: string]: string | number | boolean }) => void;
  onSubmit?: (data: { [key: string]: string | number | boolean }) => void;
  submitText: string;
  isSubmitLoading?: boolean;
  clearFormOnSubmit?: boolean;
}

const Form = ({
  title,
  subtitle,
  fields,
  values,
  errors,
  onChange,
  onSubmit,
  submitText,
  isSubmitLoading,
  clearFormOnSubmit,
}: FormProps) => {
  const ref = React.useRef<HTMLFormElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: Field) => {
    if (!onChange) return;
    onChange({
      ...(values || {}),
      [e.target.name]: field?.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!onSubmit) return;
    if (values) return onSubmit(values);

    const data = new FormData(e.currentTarget);
    // @ts-ignore
    const formValues = Object.fromEntries(data.entries());
    onSubmit(formValues);

    if (clearFormOnSubmit && ref.current) ref.current.reset();
  };

  const FormGroup = ({ title, icon, fields }: FormGroupField) => {
    return (
      <div className="FormGroup">
        {!!title || !!icon ? (
          <div className="FormGroup__header">
            {!!icon ? <Picto iconKey={icon} /> : null}
            {!!title ? <h4 className="FormGroup__title">{title}</h4> : null}
          </div>
        ) : null}
        <div className="Form__fields">
          {fields.map((field, index) => {
            const Component = types[field.type] || Input;

            return (
              <Component
                key={index}
                className="Form__field"
                value={'name' in field ? values?.[field.name] : undefined}
                error={'name' in field ? errors?.[field.name] : undefined}
                onChange={handleChange}
                {...field}
              />
            );
          })}
        </div>
        <Separator />
      </div>
    );
  };

  const types = {
    checkbox: Checkbox,
    radio: Radio,
    group: FormGroup,
  };

  return (
    <form className="Form" onSubmit={handleSubmit} autoComplete="on" ref={ref}>
      {!!title || !!subtitle ? (
        <div className="Form__header">
          {!!title ? <h3 className="Form__title">{title}</h3> : null}
          {!!subtitle ? <p className="Form__subtitle">{subtitle}</p> : null}
        </div>
      ) : null}
      <div className="Form__fields">
        {fields?.length
          ? fields.map((field, index) => {
              const Component = types[field.type] || Input;

              return (
                <Component
                  key={index}
                  className="Form__field"
                  value={'name' in field ? values?.[field.name] : undefined}
                  error={'name' in field ? errors?.[field.name] : undefined}
                  onChange={handleChange}
                  {...field}
                />
              );
            })
          : null}
      </div>
      {!!onSubmit ? (
        <div className="Form__submitContainer">
          <ButtonV2
            className="Form__submit"
            text={submitText}
            type="submit"
            isLoading={isSubmitLoading}
          />
        </div>
      ) : null}
    </form>
  );
};

export default Form;
