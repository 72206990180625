import React from 'react';

import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { RequirementsProps } from '../../tools/context';
import { useWindowSize } from '../../tools/hooks';
import SocialBannerContentBloc from './SocialBannerContentBloc';
import SocialBannerImagesContainer from './SocialBannerImagesContainer';

interface SocialBannerProps {
  title: string;
  subtitle?: string;
  sectionLogo?: SanityImageSource;
  requirements: RequirementsProps;
  socialPicto: SocialPicto[];
  socialPictures?: SocialPictures[];
}

export interface SocialPicto {
  picto: SanityImageSource;
  url: string;
}

export interface SocialPictures {
  image: SanityImageSource;
  picto: SanityImageSource;
}

const SocialBanner: React.FC<SocialBannerProps> = ({
  title,
  subtitle,
  sectionLogo,
  socialPicto,
  socialPictures,
  requirements,
}) => {
  const { isMobile } = useWindowSize();
  return (
    <div className="social-banner">
      {isMobile ? (
        <>
          <SocialBannerContentBloc
            title={title}
            subtitle={subtitle}
            sectionLogo={sectionLogo}
            socialPicto={socialPicto}
            requirements={requirements}
          />
          <SocialBannerImagesContainer
            socialPictures={socialPictures.slice(0, 2)}
            requirements={requirements}
          />
        </>
      ) : (
        <>
          <SocialBannerImagesContainer
            socialPictures={socialPictures.slice(0, 2)}
            requirements={requirements}
          />
          <SocialBannerContentBloc
            title={title}
            subtitle={subtitle}
            sectionLogo={sectionLogo}
            socialPicto={socialPicto}
            requirements={requirements}
          />
          <SocialBannerImagesContainer
            socialPictures={socialPictures.slice(2)}
            requirements={requirements}
          />
        </>
      )}
    </div>
  );
};

export default SocialBanner;
