import React from 'react';

export interface ContainerShopV2Props
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title?: string;
  children: React.ReactNode;
}

const ContainerShopV2: React.FC<ContainerShopV2Props> = ({
  title,
  children,
  className,
  ...rest
}) => {
  if (!!!title && !!!children) return null;

  return (
    <div className={`ContainerShopV2 ${className ?? ''}`} {...rest}>
      {title ? <h3 className="ContainerShopV2__title">{title}</h3> : null}
      {children ? <div className="ContainerShopV2__children">{children}</div> : null}
    </div>
  );
};

export default ContainerShopV2;
