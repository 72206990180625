import React, { useState } from 'react';
import { useTranslation } from '../../tools/i18n';
import ButtonV2 from '../ButtonV2';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  isOptional?: boolean;
}

const Input: React.FC<InputProps> = ({
  id,
  name,
  type,
  label,
  error,
  required = true,
  isOptional = false,
  inputMode,
  className,
  ...rest
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={`Input ${!!error ? 'Input--error' : ''}`}>
      {type === 'password' || !!label ? (
        <div className="Input__header">
          {!!label && (
            <label className="Input__label" htmlFor={name}>
              {label}
            </label>
          )}
          {type === 'password' ? (
            <ButtonV2
              className="Input__forgot"
              text={t('ssrComponents.Input.forgotPassword')}
              variant="clear"
              link="/mot-de-passe-oublie"
            />
          ) : null}
        </div>
      ) : null}
      <div className="Input__container">
        <input
          id={id ?? name}
          name={name}
          className={`Input__container__input ${error ? 'Input__container__input--error' : ''} ${className ?? ''}`}
          type={type === 'password' && isPasswordVisible ? 'text' : type}
          inputMode={
            (inputMode ?? type === 'email') ? 'email' : type === 'number' ? 'numeric' : 'text'
          }
          required={isOptional ? false : required}
          {...rest}
        />
        {type === 'password' ? (
          <ButtonV2
            className="Input__container__toggle-password"
            type="button"
            text={t(`ssrComponents.Input.${isPasswordVisible ? 'hide' : 'show'}Password`)}
            variant="clear"
            onClick={() => setIsPasswordVisible((p) => !p)}
          />
        ) : null}
      </div>
      {error?.trim()?.length > 1 && <p className="Input__error">{error}</p>}
    </div>
  );
};

export default Input;
