import * as z from 'zod';

export const contactFormSchema = z.object({
  first_name: z.string().min(1, 'Le prénom est requis'),
  last_name: z.string().min(1, 'Le nom est requis'),
  email: z.string().email('Email invalide'),
  subject: z.string().min(1, 'Le sujet est requis'),
  message: z.string().min(1, 'Le message est requis'),
  optin: z.boolean(),
  email_to: z.string().min(1, 'Email destinataire requis'),
  partner: z.string().min(1, 'Partenaire requis')
});

export type ContactFormData = z.infer<typeof contactFormSchema>;

export interface EmailData {
  first_name: string;
  last_name: string;
  email: string;
  subject: string;
  message: string;
  email_to: string;
  partner: string;
  optin?: boolean;
}