import React from 'react';
import { PictoProps } from './index';

export const EnvelopeV2 = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="envelopeV2"
  >
    <rect x="1" y="1" width="22" height="18" rx="1" stroke={fill || 'white'} strokeWidth="2" />
    <path
      d="M1 1.875L10.7964 10.7809C11.4832 11.4051 12.5281 11.4178 13.2297 10.8103L23 2.35119"
      stroke={fill || 'white'}
      strokeWidth="2"
    />
  </svg>
);
