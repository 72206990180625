import React from 'react';
import {isWebview} from "../../tools/tools";
import {RequirementsProps} from "../../tools/context";
import {Picto} from "../Picto";
import {useTranslation} from "../../tools/i18n";
import {useTracking} from "../../tools/analytics";

export interface OAuthButtonProps {
  redirectUrl?: string
  requirements: RequirementsProps
  parameters: any
}

export const GoogleOAuth = (props: OAuthButtonProps) => {
  const {t} = useTranslation();
  const {trackEvent} = useTracking();
  const {
    redirectUrl,
    requirements,
    parameters
  } = props;

  const enhanceAuthorizeUrl = (
    authorizeUrl,
    clientId,
    redirectUri,
    scope,
    state
  ) => {
    return `${authorizeUrl}?include_granted_scopes=true&response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
  };

  const handleClick = () => {
    trackEvent('google_oauth_click');
    const loc:Location = window.location;
    const state = {
      localUrl: redirectUrl || loc.href,
      provider: 'google',
      parameters,
    }

    const url = enhanceAuthorizeUrl(
        'https://accounts.google.com/o/oauth2/auth',
        '788851101250-n1ine0mlb64o1jlo238sfvhcjfesam77.apps.googleusercontent.com',
        `${loc.protocol}//${loc.host}/oauthredirect`,
        'email',
        btoa(encodeURIComponent(JSON.stringify(state)))      );

    if(isWebview(requirements.useragent)){
      window.open(url)
    }else{
      loc.href =url
    }

  }

  return (
     isWebview(requirements.useragent) ? <div></div>:
    <button className={"googleOAuth"} onClick={handleClick}>
      <Picto iconKey={"google"} />
      {t('loginPopup.oAuth.google')}
    </button>
  )
}