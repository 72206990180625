import * as React from "react";
import { Picto } from "../Picto";
import { Configuration } from "../../tools/Constants";
import { useEffect, useRef } from "react";
import { RequirementsProps } from "../../tools/context";
import SharingLinks from "../SharingLinks";

export interface EditoPageProps {
  headerImage: any;
  name: string;
  author: string;
  place: string;
  category: string;
  originalLink: string;
  content: string;
  requirements?: RequirementsProps;
}

const getCategory = (category) =>
  Configuration.categories[category]
    ? Configuration.categories[category].name
    : category;

const Renderer = (props: EditoPageProps) => {
  const {
    name,
    author,
    place,
    category,
    originalLink,
    content,
    requirements,
  } = props;
  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!divRef.current) return;
    // loadMap(divRef.current, {lat: seller.latitude, lng: seller.longitude}, [{
    //   geoloc: {lat: seller.latitude, lng: seller.longitude},
    //   category: state.category,
    // }], 15)
  }, []);

  return (
    <div className="editoPage">
      {/* 1 - bloc header avec img + btn ou séjourner + nom + bloc avis + ville */}
      <div className="hero">
        <div className="shop_header_info">
          <div className={`flex ${category} catBackground category`}>
            <Picto iconKey={category} />
            {getCategory(category)}
          </div>
          <div className="name">{name}</div>
          <div className="flex location">Par {author}</div>
          {place && (
            <div className="flex location">
              <Picto iconKey={"location"} /> {place}
            </div>
          )}
        </div>
      </div>

      <div className="content">
        <div
          className="editoContent"
          dangerouslySetInnerHTML={{
            __html: content.replace(/data-original/g, "src"),
          }}
        />
        <div className="sideBlock">
          {place && (
            <div className="contact">
              <p className="title">Adresse</p>
              <div key={"contact_website"} className="contact-element">
                {place}
              </div>
            </div>
          )}
          <div className="contact">
            <p className="title">Partagez cette page</p>
            <div className="share">
              <SharingLinks requirements={requirements} />
            </div>
          </div>
          <div className="contact">
            <p className="title">Voir plus</p>
            <div key={"contact_website"} className="contact-element">
              <p>
                <a href={originalLink} target="_blank" rel="noreferrer">
                  Article original
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* 6 - Vous êtes ici  */}
        {false && (
          <div className="mapBlock">
            <div className="title">Retrouvez nous ici</div>
            <div className="map" ref={ref => { divRef.current = ref }} />
            <div className="address">
              <span nonce="__nonce__" style={{ fontWeight: 700 }}>
                {place}
              </span>
              {" - "}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Shop = { Renderer };

export default Shop;
export { Renderer };
