import React from 'react';

import {RequirementsProps} from "../../tools/context";
import {Header} from "../Header";
import {Footer} from "../Footer";

export interface Page404Props {
  requirements: RequirementsProps
  navigation: any
  headerButtons: any
  headerType?: 'classic' | 'modern'
  footerType?: 'classic' | 'modern'
  header?: any
}

const Page404 = (props: Page404Props) => (
  <div className="App page404 68bc85fd-fefa-4bcf-a3f7-0dce8d69de4b">
    <Header {...props} headerType={props.headerType || 'classic'} header={props.header} />
    <div className="page">
      <div className="title">Oups !</div>
      <div className="description">La page que vous avez demandé n'existe pas</div>
    </div>
    <Footer {...props} footerType={props.headerType || 'classic'} />
  </div>
)

export default Page404;
