import React from 'react';
import { PictoProps } from './index';

export const EuroV2 = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={width || '20'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.9998 15.2C11.9763 15.2 15.1998 11.9764 15.1998 7.99999C15.1998 4.02354 11.9763 0.799988 7.9998 0.799988C4.02335 0.799988 0.799805 4.02354 0.799805 7.99999C0.799805 11.9764 4.02335 15.2 7.9998 15.2ZM10.0712 9.2264C9.64881 9.71333 9.12961 9.9568 8.51361 9.9568C8.12641 9.9568 7.78027 9.8688 7.47521 9.6928C7.17014 9.5168 6.93547 9.27333 6.77121 8.9624H8.99761V8.2232H6.55121C6.54534 8.1704 6.54241 8.0912 6.54241 7.9856C6.54241 7.88 6.54534 7.8008 6.55121 7.748H8.99761V7.0088H6.77121C6.93547 6.69787 7.17014 6.4544 7.47521 6.2784C7.78027 6.1024 8.12641 6.0144 8.51361 6.0144C9.12374 6.0144 9.64294 6.25493 10.0712 6.736L10.9776 5.8912C10.6843 5.5392 10.3205 5.26933 9.88641 5.0816C9.45814 4.89387 8.97707 4.8 8.44321 4.8C7.93281 4.8 7.45761 4.89093 7.01761 5.0728C6.58347 5.25467 6.21387 5.5128 5.90881 5.8472C5.60374 6.1816 5.38374 6.5688 5.24881 7.0088H4.25441V7.748H5.11681C5.11094 7.8008 5.10801 7.88 5.10801 7.9856C5.10801 8.0912 5.11094 8.1704 5.11681 8.2232H4.25441V8.9624H5.24881C5.38374 9.4024 5.60374 9.7896 5.90881 10.124C6.21387 10.4584 6.58347 10.7165 7.01761 10.8984C7.45761 11.0803 7.93281 11.1712 8.44321 11.1712C8.97707 11.1712 9.45814 11.0773 9.88641 10.8896C10.3205 10.7019 10.6843 10.4291 10.9776 10.0712L10.0712 9.2264Z"
      fill={fill || 'white'}
    />
  </svg>
);
