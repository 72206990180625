import React from 'react';
import { ACCOUNT_ITEMS } from '../../../../App';
import ButtonV2 from '../../../../components/ButtonV2';
import { pictos } from '../../../../components/Picto';
import Select from '../../../../components/Select';
import { useWindowSize } from '../../../../tools/hooks';
import { useTranslation } from '../../../../tools/i18n';
import { getAuthActions, useUser } from '../../store';

const { logout } = getAuthActions;

interface AccountPanelProps {}

type AccountAction = 'logout';

export interface BaseAccountItem {
  name?: string;
  icon?: keyof typeof pictos;
}

export interface AccountItemLink extends BaseAccountItem {
  type: 'link';
  link: string;
}

export interface AccountItemAction extends BaseAccountItem {
  type: 'action';
  action: AccountAction;
}

export type AccountItem = AccountItemLink | AccountItemAction;

const AccountPanel: React.FC<AccountPanelProps> = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const user = useUser();

  const defaultAccountItem: AccountItem = ACCOUNT_ITEMS?.filter((i) => i.type === 'link')?.find(
    (item: AccountItemLink) => item.link === window.location.pathname
  );

  const items = ACCOUNT_ITEMS?.map((i) => ({
    label: t(`ssrComponents.auth.accountPanel.items.${i.name}`),
    type: i.type,
    value: i.name,
    icon: i.icon,
    link: i.type === 'link' ? i.link : undefined,
    action: i.type === 'action' ? i.action : undefined,
  }));

  const handleAction = (action: AccountAction) => {
    switch (action) {
      case 'logout':
        logout();
        break;
    }
  };

  return (
    <div className="AccountPanel">
      <div className="AccountPanel__container">
        <h4 className="AccountPanel__title">
          {t('ssrComponents.auth.accountPanel.title', {
            firstname: user?.first_name,
            lastname: user?.last_name,
          })}
        </h4>
        {isMobile ? (
          <Select
            className="AccountPanel__mobile__items"
            defaultValue={defaultAccountItem?.name}
            options={items}
            onChange={(option) => {
              if (option.type === 'link') window.location.href = option.link;

              if (option.type === 'action') handleAction(option.action);
            }}
          />
        ) : (
          <div className="AccountPanel__desktop__items">
            {items.map((item) => (
              <ButtonV2
                key={item.value}
                className={`AccountPanel__desktop__item ${item.link === window.location.pathname ? 'active' : ''}`}
                text={item.label}
                variant="clear"
                leftIcon={item.icon}
                align="left"
                link={item.link}
                onClick={() => (item.action ? handleAction(item.action) : undefined)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountPanel;
