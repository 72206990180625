import axios from 'axios';
import { EmailData } from '../components/ContactForm/schema';

const CLIENTS_SERVICES_URL = 'https://clients.wearegenial.com';
//const CLIENTS_SERVICES_URL = 'http://localhost:8000';

const prepareTemplateVariables = (data: EmailData) => {
  return {
    partner: data.partner || 'default',
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    subject: data.subject,
    message: data.message,
    optin: data.optin ? 'Yes' : 'No',
  };
};

export const sendContactEmail = async (data: EmailData) => {
  try {
    const url = `${CLIENTS_SERVICES_URL}/api/v1/email/send-template`;

    const requestBody = {
      recipient: data.email_to,
      subject: data.subject,
      template_variables: prepareTemplateVariables(data),
      template: 'contact_form.html',
      sender: 'contact@infiniment-charentes.com',
      sender_name: 'Infiniment Charentes',
      reply_to: 'contact@infiniment-charentes.com',
    };

    const response = await axios.post(url, requestBody);

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Unexpected response: ${response.status}`);
    }
  } catch (error: any) {
    console.error('Detailed error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
    });
    throw error;
  }
};
