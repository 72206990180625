import React, { useEffect, useRef, useState } from "react";
import { Picto } from "../Picto";

interface ButtonProps {
  backgroundColor: {
    hex: string;
  };
  hoverTextColor: {
    hex: string;
  };
  borderRadius: string;
  hoverBackgroundColor: {
    hex: string;
  };
  link: string;
  position: string;
  text: string;
  textColor: {
    hex: string;
  };
}

export interface CarouselProps {
  label: string;
  subLabel?: string;
  className?: string;
  slides: any[];
  children: any;
  button?: ButtonProps;
}

const Carousel = (props: CarouselProps) => {
  const { label, subLabel, slides, children } = props;
  const scrollRef = useRef<HTMLDivElement>();
  const [showPrev, setShowPrev] = useState<boolean>(false);
  const [showNext, setShowNext] = useState<boolean>(false);

  const canScrollPrev = (element: HTMLDivElement): boolean => {
    return element.scrollLeft > 0;
  };

  const canScrollNext = (element: HTMLDivElement): boolean => {
    return Math.ceil(element.scrollLeft + element.clientWidth) < element.scrollWidth - 1;
  };

  useEffect(() => {
    const checkScroll = () => {
      if (scrollRef.current) {
        setShowPrev(canScrollPrev(scrollRef.current));
        setShowNext(canScrollNext(scrollRef.current));
      }
    };

    checkScroll();
    window.addEventListener('resize', checkScroll);
    
    return () => {
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  const handleNextPrev = (prev?: boolean) => {
    const scrollWidth = scrollRef.current.scrollWidth;
    const clientWidth = scrollRef.current.clientWidth;
    const tick = scrollWidth / slides.length;
    const page = Math.floor(clientWidth / tick);

    scrollRef.current.scrollBy({
      left: (prev ? -1 : 1) * tick * page,
      behavior: "smooth",
    });
  };

  const handleScroll = (e) => {
    const element = e.target as HTMLDivElement;
    setShowPrev(canScrollPrev(element));
    setShowNext(canScrollNext(element));
  };

  return (
    <div className={`carousel ${props.className ? props.className : ''}`}>
      <div className={`header${props.button ? ' with-button' : ''}`}>
        <div className="carousel-header">
          <h2>{label}</h2>
          {subLabel && <span>{subLabel}</span>}
        </div>
        {props.button && (
          <button
            className="carousel-button"
            style={{
              backgroundColor: props.button.backgroundColor.hex,
              borderRadius: props.button.borderRadius,
              color: props.button.textColor.hex,
              transition: 'background-color 0.3s, color 0.3s'
            }}
            onClick={() => window.open(props.button.link, '_blank')}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = props.button.hoverBackgroundColor.hex;
              e.currentTarget.style.color = props.button.hoverTextColor.hex;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = props.button.backgroundColor.hex;
              e.currentTarget.style.color = props.button.textColor.hex;
            }}
          >
            {props.button.text}
          </button>
        )}
      </div>
      <div
        className="scrollBloc"
        nonce="__nonce__"
        style={{ position: "relative" }}
      >
        <div
          ref={ref => { scrollRef.current = ref }}
          onScroll={handleScroll}
          className="scrollpart"
        >
          {children}
        </div>
        {showPrev && (
          <button
            onClick={() => handleNextPrev(true)}
            className="pageButton previous"
          >
            <Picto iconKey={"arrow-left"} />
          </button>
        )}
        {showNext && (
          <button onClick={() => handleNextPrev()} className="pageButton next">
            <Picto iconKey={"arrow-right"} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Carousel;
