import React, { createContext, ReactNode, useContext, useState } from 'react';

export interface SearchBoxType {
  bottomPosition: number;
}

export interface SearchBoxContextType {
  searchBoxPosition: SearchBoxType;
  setSearchBoxPosition: (value: SearchBoxType) => void;
}

const SearchBoxContext = createContext<SearchBoxContextType | undefined>(undefined);

interface SearchBoxProviderProps {
  children: ReactNode;
}

export const SearchBoxProvider: React.FC<SearchBoxProviderProps> = ({ children }) => {
  const [searchBoxPosition, setSearchBoxPosition] = useState<SearchBoxType>({ bottomPosition: 0 });
  return (
    <SearchBoxContext.Provider value={{ searchBoxPosition, setSearchBoxPosition }}>
      {children}
    </SearchBoxContext.Provider>
  );
};
export const useSearchBox = () => {
  const context = useContext(SearchBoxContext);
  if (context === undefined) {
    throw new Error('useSearchBox must be used within a SearchBoxProvider');
  }
  return context;
};
