import type { SVGProps } from 'react';
import * as React from 'react';
import { getCities } from './cities';

interface CarteOnProps extends SVGProps<SVGSVGElement> {
  highlightedPath?:
    | 'flowvelo'
    | 'scandiberique'
    | 'velodyssee'
    | 'canal2mer'
    | 'francette'
    | 'velideale'
    | null;
  color?: string;
  routes: {
    flowvelo?: { color: { hex: string }; link: string; description: string };
    scandiberique?: { color: { hex: string }; link: string; description: string };
    velodyssee?: { color: { hex: string }; link: string; description: string };
    canal2mer?: { color: { hex: string }; link: string; description: string };
    francette?: { color: { hex: string }; link: string; description: string };
    velideale?: { color: { hex: string }; link: string; description: string };
  };
  cityLinks: any;
}

const SvgCarte: React.FC<CarteOnProps> = ({ routes, highlightedPath, cityLinks, className }) => {
  const isFilledPath = true;
  const citiesObject = getCities(cityLinks);

  const citiesArray = Object.values(citiesObject).map((city) => ({
    id: city.id,
    name: city.name,
    link: city.link,
    path: city.path,
    fill: city.fill,
    className: city.className,
  }));

  // console.log('routes', routes);
  // console.log('cityLinks', cityLinks);
  // console.log('getCities', getCities(cityLinks));

  const DEFAULT_STROKE = '';
  const PATHS = [
    'flowvelo',
    'scandiberique',
    'velodyssee',
    'canal2mer',
    'francette',
    'velideale',
  ] as const;

  const strokeStyles = Object.fromEntries(
    PATHS.map((path) => [
      path,
      {
        stroke:
          highlightedPath === path || isFilledPath ? routes[path]?.color?.hex : DEFAULT_STROKE,
      },
    ])
  );

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 939.1 823.8" className={className}>
      <defs>
        <style>{`
          .st0, .st1, .st2, .st3, .st4, .st5, .st6, .st7, .st8, .st9, .st10, .st11 {
            fill: none;
          }

          .st12 {
            fill: #a2bfe6;
          }

          .st13 {
            fill: #7b31af;
          }

          .st1 {
            stroke: #a2bfe6;
          }

          .st1, .st2, .st3, .st4, .st5, .st6, .st7, .st10 {
            stroke-width: 2px;
          }

          .st14 {
            fill: #f4f4f4;
          }

          .st15 {
            fill: #c31c01;
          }

          .st16 {
            fill: #140f40;
          }

          .st17, .st18 {
            fill: #f5f5f5;
          }

          .st17, .st8, .st9 {
            stroke: #140f40;
            stroke-width: .2px;
          }

          .st19 {
            fill: #b03030;
          }

          .st2 {
            stroke: #dba070;
          }

          .st20 {
            fill: #002684;
          }

          .st21 {
            fill: url(#Carte_svg_Nouveau_motif_2);
            isolation: isolate;
            opacity: .2;
            stroke: url(#Carte_svg_Nouveau_motif_2);
          }

          .st21, .st6, .st8 {
            stroke-miterlimit: 10;
          }

          .st3 {
            stroke: #7b32af;
          }

          .st4 {
            stroke: #c5d571;
          }

          .st5 {
            stroke: #002684;
          }

          .st6 {
            stroke: #c31c01;
          }

          .st22 {
            fill: #3d8ccc;
          }

          .st23 {
            fill: #21212b;
          }

          .st24 {
            fill: #a2bfe6;
          }

          .st7 {
            stroke: #ceced9;
          }

          .st10 {
            stroke: #3d8ccc;
          }

          .st11 {
            stroke: #fff;
          }
        `}</style>
        <pattern
          id="Carte_svg_Nouveau_motif_2"
          data-name="Carte svg  Nouveau motif 2"
          x="0"
          y="0"
          width="54.9"
          height="56"
          patternTransform="translate(-2723.3 -11313.6) scale(.7 -.7)"
          patternUnits="userSpaceOnUse"
          viewBox="0 0 54.9 56"
        >
          <g>
            <rect className="st0" width="54.9" height="56" />
            <path className="st0" d="M0,56h54.9V0H0v56Z" />
            <path d="M54.9,3.5c.7-9.3,14.9-9.3,15.5,0-1.4-8.7,13.6-9.6,13.1-.8.4-8,12.3-8.2,14.2.8,0-4.3,2.5-7,5.8-7s5.8,3.4,6.2,7M0,3.5c.7-9.3,14.9-9.3,15.5,0-1.4-8.7,13.6-9.6,13.1-.8.4-8,12.3-8.2,14.2.8,0-4.3,2.5-7,5.8-7s5.8,3.4,6.2,7" />
            <path d="M-54.9,3.5c.7-9.3,14.9-9.3,15.5,0-1.4-8.7,13.6-9.6,13.1-.8.4-8,12.3-8.2,14.2.8-.1-4.3,2.5-7,5.8-7S-.4,0,0,3.5M27.5,17.5c.7-9.3,14.9-9.3,15.5,0-1.4-8.7,13.6-9.6,13.1-.8.4-8,12.3-8.2,14.2.8,0-4.3,2.5-7,5.8-7s5.8,3.4,6.2,7M-27.4,17.5c.7-9.3,14.9-9.3,15.5,0-1.4-8.7,13.6-9.6,13.1-.8.4-8,12.3-8.2,14.2.8-.1-4.3,2.5-7,5.8-7s5.8,3.4,6.2,7M54.9,31.5c.7-9.3,14.9-9.3,15.5,0-1.4-8.7,13.6-9.6,13.1-.8.4-8,12.3-8.2,14.2.8,0-4.3,2.5-7,5.8-7s5.8,3.4,6.2,7M0,31.5c.7-9.3,14.9-9.3,15.5,0-1.4-8.7,13.6-9.6,13.1-.8.4-8,12.3-8.2,14.2.8,0-4.3,2.5-7,5.8-7s5.8,3.4,6.2,7" />
            <path d="M-54.9,31.5c.7-9.3,14.9-9.3,15.5,0-1.4-8.7,13.6-9.6,13.1-.8.4-8,12.3-8.2,14.2.8-.1-4.3,2.5-7,5.8-7S-.4,27.9,0,31.5M27.5,45.5c.7-9.3,14.9-9.3,15.5,0-1.4-8.7,13.6-9.6,13.1-.8.4-8,12.3-8.2,14.2.8,0-4.3,2.5-7,5.8-7s5.8,3.4,6.2,7M-27.4,45.5c.7-9.3,14.9-9.3,15.5,0-1.4-8.7,13.6-9.6,13.1-.8.4-8,12.3-8.2,14.2.8-.1-4.3,2.5-7,5.8-7s5.8,3.4,6.2,7M54.9,59.5c.7-9.3,14.9-9.3,15.5,0-1.4-8.7,13.6-9.6,13.1-.8.4-8,12.3-8.2,14.2.8,0-4.3,2.5-7,5.8-7s5.8,3.4,6.2,7M0,59.5c.7-9.3,14.9-9.3,15.5,0-1.4-8.7,13.6-9.6,13.1-.8.4-8,12.3-8.2,14.2.8,0-4.3,2.5-7,5.8-7s5.8,3.4,6.2,7M-54.9,59.5c.7-9.3,14.9-9.3,15.5,0-1.4-8.7,13.6-9.6,13.1-.8.4-8,12.3-8.2,14.2.8-.1-4.3,2.5-7,5.8-7s5.9,3.4,6.3,7" />
          </g>
        </pattern>
      </defs>
      <path
        id="Carte_svg__Background"
        className="st18"
        d="M234.2,111.8l53.3-21.3c5.5-2.2,11,3.2,8.9,8.8-1.8,4.6,1.7,9.5,6.7,9.3l30.9-1.5c6.3-.3,12.6,1.5,17.8,5.2,7.5,5.3,11.9,13.9,11.9,23.1v4.9c0,6.8,2.5,13.4,7.1,18.5l36.2,40.6c3,3.3,6.7,5.9,10.9,7.5l118.3,44.7c10.3,3.9,19.2,10.6,25.7,19.4l8.5,11.5c5.6,7.6,15.9,9.9,24.2,5.5,5.5-2.9,9.2-8.3,10-14.5l.6-4.4c.8-6.6,5.1-12.3,11.2-15.1l25.9-11.4c6.9-3.1,14.5-4.3,22.1-3.5l13.9,1.4c2.2.2,4.3-.5,5.9-2.1,1.9-1.9,4.8-2.6,7.4-1.8l58,18.8c6.3,2,13,2.5,19.6,1.2l2.7-.5c6.9-1.3,10.5-9.1,7-15.3-2.4-4.3-1.5-9.7,2.2-12.9l3.8-3.2c4-3.5,10-3.3,13.9.3l14.8,14c7.4,7,18.2,9,27.6,5.1l7.5-3.2c4.4-1.8,8-5.1,10.2-9.3,4.2-7.8,12.8-12.1,21.6-10.7l6,1c10.3,1.7,17.7,10.7,17.3,21.1v2.2c-.5,12,4.9,23.5,14.5,30.8l12.9,9.8c6.2,4.7,9.2,12.6,7.8,20.3l-.6,3.3c-1.4,7.2-6.5,13.2-13.5,15.7l-1.2.4c-4.9,1.7-9,5.3-11.4,9.9l-24.4,46.8c-3.5,6.8-9.9,11.6-17.4,13.2h-.3c-5.5,1.2-10.4,4.1-14.1,8.2-12.9,14.4-44.3,49.3-57.8,64.4s-7.7,10.2-10.2,16.1l-10.1,23.8c-4,9.4-10.4,17.6-18.6,23.7l-26.8,20c-16.2,12.1-26.7,30.2-29.3,50.2v.5c-1.2,8.7-4.4,17-9.3,24.1-3.5,5-7.8,11.1-11.8,16.8-8.6,12.2-21.8,20.4-36.6,22.5l-11,1.5c-7,1-13.6,4.2-18.7,9.1l-4.2,4.1c-3.7,3.5-6.5,7.8-8.3,12.5l-4.1,10.9c-5.4,14.4-19.9,23.4-35.2,21.7l-40.7-4.3c-5.8-.6-11.3-2.7-16-6.1l-29.2-20.8c-8.1-5.7-13.2-14.7-14.1-24.6l-2-22.7c-.5-5.3-4.2-9.7-9.3-11l-38.2-9.7c-4.8-1.2-8.5-5.2-9.2-10.1l-.4-2.4c-1-6.6-7.1-11.2-13.8-10.4l-32.2,3.8c-2,.2-4-1-4.6-3l-13.1-42.1c-2.9-9.2-7.7-17.7-14.1-24.8l-71.5-79.4c-1.6-1.8-3.4-3.3-5.5-4.5l-51.1-31c-4.8-2.9-8.5-7.4-10.4-12.8l-3.3-9.4c-3.6-10.4.1-21.9,9.2-28.1l19-13.1c3.9-2.7,7-6.2,9.2-10.4l14.8-28.2c3.6-6.8,4.3-14.8,1.9-22.1l-7.2-22.7c-1.6-5.1,1.7-10.4,6.9-11.2l1.3-.2c5.8-.9,9.1-7.3,6.4-12.5l-24.4-47.8c-2-3.8-5.4-6.7-9.5-7.9-9.2-2.8-13.8-13.2-9.7-21.9l28.8-60.2c.5-1,1.3-1.7,2.3-2.1h-.2Z"
      />
      <path
        id="Carte_svg__Sea"
        className="st24"
        d="M0,0l62.2,50.5c1,.8,2.1,1.3,3.3,1.7l24.5,6.5c8.3,2.2,15.3,8,19,15.8l.9,2c4.4,9.3,13,15.9,23,17.9l10.9,2.1c14.8,2.9,28.7,9.4,40.3,19l9.6,7.8c4.4,3.6,10.9,1.3,12.2-4.2,1.2-5.3,6.2-8.8,11.6-8.2l15.1,1.7-.7,1.5-28.8,60.2c-4.2,8.7.4,19.1,9.7,21.9,4.1,1.2,7.5,4.1,9.5,7.9l24.6,48.1c2.6,5.1-.5,11.3-6.2,12.2l-1.8.3c-5.2.8-8.4,6-6.8,11l7.3,22.9c2.3,7.3,1.6,15.3-1.9,22.1l-14.8,28.2c-2.2,4.1-5.3,7.7-9.2,10.4l-16.2,11.2c-10.7,7.3-15.1,20.9-10.8,33.1l1.6,4.7c2.2,6.3,6.5,11.6,12.2,15.1l49.2,29.9c2.4,1.5,4.6,3.3,6.4,5.3l70.5,78.2c6.9,7.6,12,16.7,15.1,26.5l12.8,41.2,1,3.2v.4l4,17.8c.7,3,.1,6.1-1.5,8.6l-7.1,11.1c-3.1,4.8-10.4,3.9-12.2-1.6l-10.5-32.6c-3.4-10.7-9.3-20.4-17.2-28.3l-74.2-74.8c-.9-.9-1.5-1.9-1.9-3l-3.6-9.5c-1.4-3.5-6.3-3.8-8-.4l-18.3,36.3c-.5.9-.7,1.9-.9,2.8l-35.1,285.6c-.3,2.2-2.1,3.9-4.4,3.9H0V0Z"
      />
      <path
        id="Carte_svg__Sea-motif"
        className="st21"
        d="M0,0l62.2,50.5c1,.8,2.1,1.3,3.3,1.7l24.5,6.5c8.3,2.2,15.3,8,19,15.8l.9,2c4.4,9.3,13,15.9,23,17.9l10.9,2.1c14.8,2.9,28.7,9.4,40.3,19l9.6,7.8c4.4,3.6,10.9,1.3,12.2-4.2,1.2-5.3,6.2-8.8,11.6-8.2l15.1,1.7-.7,1.5-28.8,60.2c-4.2,8.7.4,19.1,9.7,21.9,4.1,1.2,7.5,4.1,9.5,7.9l24.6,48.1c2.6,5.1-.5,11.3-6.2,12.2l-1.8.3c-5.2.8-8.4,6-6.8,11l7.3,22.9c2.3,7.3,1.6,15.3-1.9,22.1l-14.8,28.2c-2.2,4.1-5.3,7.7-9.2,10.4l-16.2,11.2c-10.7,7.3-15.1,20.9-10.8,33.1l1.6,4.7c2.2,6.3,6.5,11.6,12.2,15.1l49.2,29.9c2.4,1.5,4.6,3.3,6.4,5.3l70.5,78.2c6.9,7.6,12,16.7,15.1,26.5l12.8,41.2,1,3.2v.4l4,17.8c.7,3,.1,6.1-1.5,8.6l-7.1,11.1c-3.1,4.8-10.4,3.9-12.2-1.6l-10.5-32.6c-3.4-10.7-9.3-20.4-17.2-28.3l-74.2-74.8c-.9-.9-1.5-1.9-1.9-3l-3.6-9.5c-1.4-3.5-6.3-3.8-8-.4l-18.3,36.3c-.5.9-.7,1.9-.9,2.8l-35.1,285.6c-.3,2.2-2.1,3.9-4.4,3.9H0V0Z"
      />
      <path
        id="Carte_svg__Ile-2"
        className="st14"
        d="M144.8,254.9l-10.7,16.3c-.9,1.4-.9,3.1-.1,4.5l46.2,78.7c1.7,3,6.1,2.8,7.6-.3l7.1-14.8c.4-.7.5-1.6.4-2.4l-6.9-57.8c-.2-1.3-.9-2.4-2-3.1l-35.7-22.4c-2-1.2-4.6-.7-5.9,1.3"
      />
      <path
        id="Carte_svg__Ile-1"
        className="st14"
        d="M101.6,153.9l1.1,3c.7,2,2.8,3.2,5,2.8l8.5-1.8c1.3-.3,2.7,0,3.7.9l29.8,25.6c1.3,1.1,3.1,1.3,4.6.6l13.5-6.4c3.1-1.4,3.3-5.7.4-7.5l-38.3-23.9c-1-.6-2.2-.8-3.4-.5l-13.8,3.7c-1.4.4-3,0-4-1-3.3-3.1-8.5.3-7,4.5h0Z"
      />
      <path
        className="st2"
        d="M239.6,270.6l11.6,5.1c6.1,2.7,10.2,8.7,10.1,15.3-.1,9.8,10.2,16.1,18.8,11.5l8-4.2c8.7-4.6,19,2.3,18.4,12.2.8,6.6,5.8,12.1,12.3,13.3,25.5,4.8,54.7,20.4,65.6,49.9,13.4,36.1,29.4,50.4,99,50.4h31.6c12.3-.1,24.1,4.7,32.8,13.5h0c8.7,8.7,20.4,13.5,32.7,13.5h136.4c53,0,103.9,21.1,141.4,58.6"
      />
      <path
        className="st7"
        d="M393.5,123.7h-103.4c-7.2,0-14.2,2.7-19.6,7.5l-31.1,27.6c-9.9,8.8-12.6,23.2-6.6,35l22.2,43.7c4.3,10.1,6.5,20.9,6.5,31.9v21.9c-.1,9.7,10.2,15.9,18.7,11.3l8-4.2c8.7-4.6,19,2.3,18.4,12.2h0c-.4,6.2-5.2,11.3-11.3,12.1-11.2,1.3-28.6,3.4-39.1,4.6s-9.4,4.3-10.8,9.2c-8.9,31.7-22.3,30.9-40.6,49.9-14.4,15,12.7,31.7,25.6,37.7,13.8,6.3,40.6,23.4,55,40.5,10.8,12.9,44.3,50.7,44.3,50.7.8.8,1.5,1.6,2.3,2.5,16.7,18.5,28,41.3,33.4,65.6l10.2,45.4.3,28.8c.2,15.5,12.8,28,28.3,28h6.8c9.3,0,18.3,3.7,24.9,10.3l24.6,24.6c8.2,8.2,12.9,19.4,12.9,31h0c0,11.6,4.6,22.8,12.9,31l41.1,41.1"
      />
      <path
        className="st7"
        d="M261,139.7l10.2-9-13.5-7.4c-5.1-2.8-9.1-7.2-11.4-12.5h0c-4.8-11.1-16.5-17.4-28.4-15.4l-6.5,1.1c-7.9,1.4-16-.4-22.5-5.1l-10.1-7.2c-3.2-2.3-6.9-3.9-10.8-4.8l-16.8-3.8c-9.7-2.2-17.7-9-21.3-18.3h0c-3.9-9.9-12.8-17.1-23.3-18.7l-21.8-3.3c-4.8-.7-9.3-2.6-13.2-5.5L31,0M226.1,512.8l-6.8,13.1c-3.1,5.9-5,12.4-5.8,19.1l-29.8,278.9M717,451c49.1,0,88.9-39.8,88.9-88.9v-191.1M646.9,451h-64.5c-24,0-43.4,19.5-43.4,43.4h0c0,11.5-4.6,22.6-12.7,30.7l-.9.9c-8.1,8.1-12.7,19.2-12.7,30.7v46.8c0,9.8-3.9,19.2-10.8,26.1h0c-6.9,6.9-10.8,16.3-10.8,26.1h0c0,16.3-13.2,29.5-29.5,29.5h-50.6"
      />
      <path
        className="st7"
        d="M566.2,226.1l18.6,11.3c5.6,3.4,8.6,10,7.4,16.5h0c-1.1,5.7,1.1,11.6,5.7,15.3l17.6,14.1c2.8,2.2,6.2,3.5,9.8,3.5l51.2.4c3.4,0,6.7,1.1,9.4,3.2l6.8,5.1c4.5,3.4,10.4,4.1,15.6,2l48.6-20.2c1.9-.8,4-1.2,6.1-1.2h27c8.8,0,15.9,7.1,15.9,15.9v95.7c0,8.8,7.1,15.9,15.9,15.9h71.4"
      />
      <path
        id="Vector_12"
        className="st23"
        d="M384.2,369.3c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        id="Vector_13"
        className="st23"
        d="M436,265.5c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        id="Vector_14"
        className="st23"
        d="M681.5,282.7c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        id="Vector_15"
        className="st23"
        d="M805.6,306.3c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        id="Vector_16"
        className="st23"
        d="M720.9,394.7c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        id="Vector_17"
        className="st23"
        d="M646.8,446.7c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        id="Vector_19"
        className="st23"
        d="M614,590.1c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        id="Vector_20"
        className="st23"
        d="M440.2,540.5c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        id="Vector_21"
        className="st23"
        d="M285.9,460.6c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        id="Vector_22"
        className="st23"
        d="M483.2,419.5c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        id="point_surgeres"
        className="st23"
        d="M379.2,207c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        id="point_rouillac"
        className="st23"
        d="M584.2,388c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5Z"
      />
      <path
        id="pointBordeaux"
        className="st23"
        d="M380.7,668.3c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5Z"
      />
      <path
        className="st19"
        d="M805.6,306.3c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0ZM646.8,446.7c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        className="st6"
        d="M805.9,232v130.1c0,49.1-39.8,88.9-88.9,88.9h-134.5c-24,0-43.4,19.5-43.4,43.4h0c0,11.5-4.6,22.6-12.7,30.7l-.9.9c-8.1,8.1-12.7,19.2-12.7,30.7v46.8c0,9.8-3.9,19.2-10.8,26.1h0c-6.9,6.9-10.8,16.3-10.8,26.1h0c0,13.9-9.6,25.5-22.5,28.7"
      />
      <g id="Carte_svg__VELODYSSEE_-_OVER">
        <path
          className="st22"
          d="M285.9,460.6c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0ZM284.9,295.3c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0ZM242.3,341.1c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0ZM233.6,191.1c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
        />
        <path
          className="st10"
          d="M244.7,107.6c.6,1,1.1,2,1.5,3h0c2.3,5.3,6.3,9.8,11.4,12.5l13.5,7.4-.7.6-31.1,27.6c-9.9,8.8-12.6,23.2-6.6,35l1.2,2.4,21,41.3c4.3,10.1,6.5,20.9,6.5,31.9v21.9c-.1,9.7,10.2,15.9,18.7,11.3l8-4.2c8.7-4.6,19,2.3,18.4,12.2h0c-.4,6.2-5.2,11.3-11.3,12.1-11.2,1.3-28.6,3.3-39,4.6-5.2.6-9.4,4.3-10.8,9.3-8.9,31.7-22.3,30.9-40.6,49.9-14.4,15,12.7,31.7,25.6,37.7,13.8,6.3,40.6,23.4,55,40.5"
        />
        <path
          className="st22"
          d="M271,126.3c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
        />
      </g>
      <g id="Carte_svg__CANAL2MER_-_OVER">
        <path
          className="st5"
          d="M285.4,464.4c10.8,12.9,44.3,50.7,44.3,50.7.8.8,1.5,1.6,2.3,2.5,16.7,18.5,28,41.3,33.4,65.6l5.1,22.8"
        />
        <path
          className="st20"
          d="M285.9,460.6c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
        />
      </g>
      <g id="Carte_svg__FRANCETTE_-_OVER">
        <path
          className="st4"
          d="M233.8,195.7l-.9-1.9c-6-11.8-3.3-26.2,6.6-35l31.1-27.6c5.4-4.8,12.4-7.5,19.6-7.5h70.9"
        />
        <path
          className="st11"
          d="M271,126.3c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0ZM233.6,191.1c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
        />
      </g>
      <g id="Carte_svg__VELIDEALE_-_OVER">
        <path
          className="st3"
          d="M605,274.9l10.4,8.3c2.8,2.2,6.2,3.5,9.8,3.5l51.2.4c3.4,0,6.7,1.1,9.4,3.2l6.8,5.1c4.5,3.4,10.4,4.1,15.6,2l48.6-20.2c1.9-.8,4-1.2,6.1-1.2h27c8.8,0,15.9,7.1,15.9,15.9v95.7c0,8.8,7.1,15.9,15.9,15.9h33.5"
        />
        <path
          className="st13"
          d="M681.5,282.7c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0ZM805.6,306.3c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
        />
      </g>
      <rect className="st9" x="371.8" y="184.4" width="78.8" height="21.1" rx="10.5" ry="10.5" />
      <rect className="st9" x="271.3" y="271.6" width="85.6" height="21.1" rx="10.5" ry="10.5" />
      <rect className="st9" x="242.3" y="349.6" width="79.4" height="21.1" rx="10.5" ry="10.5" />
      <rect className="st9" x="628.8" y="258.1" width="60.6" height="21.1" rx="10.5" ry="10.5" />
      <rect className="st9" x="447.4" y="260.1" width="89.5" height="37.8" rx="18.9" ry="18.9" />
      <rect className="st17" x="116.8" y="145" width="51.1" height="37.8" rx="18.9" ry="18.9" />
      <rect className="st17" x="125.6" y="273.7" width="85.5" height="37.8" rx="18.9" ry="18.9" />
      <rect className="st9" x="560.3" y="603" width="100.2" height="37.8" rx="18.9" ry="18.9" />
      <rect className="st9" x="391.9" y="363.2" width="60.6" height="21.1" rx="10.5" ry="10.5" />
      <rect className="st9" x="409.4" y="515.6" width="60.6" height="21.1" rx="10.5" ry="10.5" />
      <rect className="st9" x="291.7" y="441.2" width="55.6" height="21.1" rx="10.5" ry="10.5" />
      <rect className="st9" x="646.7" y="369" width="142.4" height="21.1" rx="10.5" ry="10.5" />
      <rect className="st9" x="554" y="400.1" width="71.2" height="21.1" rx="10.5" ry="10.5" />
      <rect className="st8" x="448.5" y="432.1" width="64.9" height="21.1" rx="10.5" ry="10.5" />
      <rect className="st16" x="596.3" y="464.1" width="91.5" height="21.1" rx="10.5" ry="10.5" />
      <rect className="st16" x="245.8" y="183.7" width="94.9" height="21.1" rx="10.5" ry="10.5" />
      <rect className="st9" x="293.6" y="672.1" width="79" height="21.1" rx="10.5" ry="10.5" />
      <rect className="st9" x="814.9" y="300.4" width="85.2" height="21.1" rx="10.5" ry="10.5" />
      <path
        className="st1"
        d="M236.8,275l11.6,5.1c5,2,8,4.4,8.1,10.3,0,14.1,14,21.3,22.9,17.5l8-4.2c7.9-4,13.4-2.9,14.5,9.2.8,6.6,6.4,12.3,13.3,14.3,27.8,5.9,55.9,21.4,66.8,50.9,13.4,36.1,29.5,50.4,99.3,50.4h31.7c12.3-.1,24.2,4.7,32.9,13.5h0c8.7,8.7,20.5,13.5,32.8,13.5h63.3c12.5,0,23.6-8.1,23.6-22s-13.3-27.3-21.2-40.9-13.7-30.2-4.2-40.7c7.6-8.4,19.5-27.5,31.3-24.4s14.6,5.1,19.2,1.9,8.6-13.2,7.5-38.9c-1.3-30.8-3.9-50.5,11.2-62.2"
      />
      <path
        className="st22"
        d="M284.9,295.3c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        className="st13"
        d="M384.2,369.3c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        className="st15"
        d="M646.8,446.7c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        className="st13"
        d="M483.2,419.5c2.5,0,4.6,1.3,4.6,4.3s-1.1,4.5-4.6,4.5-4.2-2.3-4.2-4.2,1.7-4.5,4.2-4.5h0Z"
      />
      <path
        className="st0"
        d="M239.6,270.6l11.6,5.1c6.1,2.7,10.2,8.7,10.1,15.3-.1,9.8,10.2,16.1,18.8,11.5l8-4.2c8.7-4.6,19,2.3,18.4,12.2.8,6.6,5.8,12.1,12.3,13.3,25.5,4.8,54.7,20.4,65.6,49.9,13.4,36.1,29.4,50.4,99,50.4h31.6c12.3-.1,24.1,4.7,32.8,13.5h0c8.7,8.7,20.4,13.5,32.7,13.5h136.4c28.2,0,55.7,5.9,81,17.1"
      />
      <g>
        <polygon
          className="st12"
          points="382.7 391.9 382.1 390.8 374.6 394.5 377.1 399.7 378.1 399.2 376.1 395.1 382.7 391.9"
        />
        <path
          className="st12"
          d="M385,401.9c.1-.6,0-1.3-.5-2.1-.3-.4-.6-.8-.9-1.1-.4-.3-.7-.6-1.1-.7l-.5.8c.3.1.6.3.9.5.3.3.5.5.7.9.3.5.4.9.3,1.3,0,.4-.3.7-.8.9h-.2c0,.1-.9-1.5-.9-1.5-.3-.5-.6-.9-1-1.2-.3-.2-.7-.4-1-.4-.3,0-.6,0-.9.3-.3.2-.5.4-.7.7-.2.3-.2.7-.2,1,0,.4.2.8.4,1.2.3.5.6.9,1,1.1.3.2.6.2.9.2l-.8.4.5.9,3.3-1.9c.8-.4,1.2-1,1.3-1.6ZM379.6,402.4c-.2-.4-.3-.7-.3-1,0-.3.2-.5.5-.7.2-.1.5-.2.8,0,.3,0,.5.4.8.9l.9,1.5-.8.4h0c-.4,0-.8,0-1.1-.2s-.6-.5-.8-.8Z"
        />
        <path
          className="st12"
          d="M387,413.1c-.3-.4-.5-.8-.6-1.2-.1-.4-.2-.8-.1-1.2s.2-.8.4-1.2c.2-.4.5-.7.8-1,.4-.3.7-.5,1.2-.6.4-.1.8-.2,1.2-.1.4,0,.8.2,1.2.4.4.2.7.5,1,.9.3.4.5.7.6,1.2.1.4.2.9,0,1.3h1.1c0-.4,0-1-.1-1.6-.2-.6-.5-1.1-.9-1.6-.4-.5-.9-.9-1.4-1.2-.5-.3-1-.5-1.6-.5-.6,0-1.1,0-1.6.1-.5.2-1.1.4-1.5.8-.5.4-.9.8-1.1,1.3s-.4,1-.5,1.6c0,.6,0,1.1.2,1.7.2.6.5,1.1.9,1.6.4.5.9.9,1.4,1.2.5.3,1.1.4,1.6.5v-1.1c-.4,0-.8-.2-1.2-.4-.4-.2-.7-.5-1-.8Z"
        />
        <path
          className="st12"
          d="M397.7,417.7c.1-.5,0-.9,0-1.3-.2-.4-.4-.8-.8-1.1-.4-.4-.9-.6-1.4-.7-.3,0-.7,0-1,0l2.3-2.6-.9-.8-5.9,6.7.9.8,2.2-2.5c.3-.3.6-.6,1-.7s.7-.1,1,0,.7.3,1,.5c.4.4.6.8.6,1.2,0,.4-.2.9-.6,1.3l-2.3,2.6.9.8,2.4-2.7c.4-.5.7-.9.8-1.4Z"
        />
        <path
          className="st12"
          d="M403.6,421c-.2-.6-.6-1.2-1.3-1.6-.4-.3-.9-.5-1.3-.6-.5-.1-.9-.2-1.3-.1v1c.2,0,.6,0,1,0,.4,0,.7.2,1,.5.5.3.8.7.8,1,0,.4,0,.8-.3,1.2v.2c-.1,0-1.6-1-1.6-1-.5-.3-1-.6-1.4-.6-.4,0-.8,0-1,.1-.3.1-.5.4-.7.6-.2.3-.3.6-.3,1,0,.3.1.7.3,1,.2.3.5.6.9.9.5.3,1,.5,1.4.5.3,0,.6,0,.9-.2l-.5.7.9.6,2.1-3.2c.5-.7.6-1.4.5-2ZM399,423.9c-.4-.2-.6-.5-.7-.8s0-.6.1-.8c.2-.2.4-.4.6-.4.3,0,.6,0,1.1.4l1.5,1-.5.7c-.3.2-.7.3-1,.3s-.7-.1-1.1-.4Z"
        />
        <path
          className="st12"
          d="M408.7,424.4s0,0,.1,0l.5-1c-.5-.3-1-.4-1.5-.4-.4,0-.7.1-1.1.3l.5-.9-1-.5-3,5.6,1,.5,1.5-2.8c.3-.6.7-1,1.2-1.1.5-.2,1,0,1.6.2,0,0,0,0,.1,0Z"
        />
        <path
          className="st12"
          d="M414.1,426.2c-.3-.4-.8-.8-1.3-1-.6-.2-1.1-.3-1.7-.3-.6,0-1.1.3-1.5.6s-.8.8-1.1,1.4c-.3.6-.3,1.1-.3,1.7,0,.6.3,1.1.6,1.5.4.5.9.8,1.5,1.1.5.2,1,.3,1.4.3.5,0,.9,0,1.3-.3l-.3-.9c-.3.1-.6.2-1,.2-.3,0-.7,0-1-.2-.4-.2-.8-.4-1-.7-.2-.3-.4-.7-.4-1,0-.3,0-.6,0-.8l4.7,2.1s0-.1,0-.2,0-.1,0-.2c.3-.6.4-1.2.3-1.7,0-.6-.2-1.1-.6-1.5ZM410.4,426.2c.3-.2.6-.4,1-.4.4,0,.7,0,1.1.2.4.2.7.4.9.7.2.3.3.6.4,1,0,.3,0,.5-.1.8l-3.7-1.6c.1-.2.3-.5.5-.6Z"
        />
        <path
          className="st12"
          d="M420.3,428c-.5-.2-1.1-.2-1.5-.1-.4,0-.7.2-1,.4l.3-1-1.1-.3-2,6,1.1.4,1-3.1c.1-.4.3-.8.6-1,.3-.2.5-.4.9-.5.3,0,.7,0,1.1.1.5.2.9.4,1.1.8.2.4.2.9,0,1.5l-1.1,3.4,1.1.4,1.1-3.5c.2-.6.2-1.1.2-1.6,0-.5-.3-.8-.6-1.2-.3-.3-.7-.5-1.2-.7Z"
        />
        <path
          className="st12"
          d="M425.8,430.6l1.8.4.2-.9-1.8-.4.3-1.4-1.1-.3-.3,1.4-1-.3-.2.9,1,.3-.8,3.4c-.2.6,0,1.1.2,1.6.3.4.7.7,1.3.9.3,0,.5,0,.8,0s.5,0,.7-.2l-.2-.9c-.3.1-.6.2-.9,0-.3,0-.5-.2-.7-.4-.1-.2-.1-.5,0-.8l.8-3.3Z"
        />
        <path
          className="st12"
          d="M434.3,432.8c-.2-.5-.5-1-.9-1.3-.4-.4-.9-.6-1.5-.7-.6-.1-1.2,0-1.7.1s-1,.5-1.3.9c-.4.4-.6,1-.7,1.6-.1.6,0,1.2.1,1.7.2.5.5,1,1,1.3.5.4,1,.6,1.7.7.5,0,1,0,1.5,0s.9-.3,1.2-.6l-.5-.8c-.3.2-.6.4-.9.4-.3,0-.7,0-1,0-.4,0-.8-.2-1.1-.5s-.5-.5-.7-.9c0-.3-.1-.5-.1-.8l5.1.9c0,0,0-.1,0-.2,0,0,0-.1,0-.2.1-.6,0-1.2,0-1.8ZM430.6,431.8c.3-.1.7-.1,1.1,0,.4,0,.7.2,1,.5.3.2.5.5.6.9,0,.3.1.5,0,.8l-4-.7c0-.3.2-.5.4-.7.2-.3.5-.5.9-.6Z"
        />
      </g>
      <g id="Carte_svg__Villes">
        <g id="Villes">
          {citiesArray.map((city) => (
            <g id={city.id} key={city.id}>
              {city.link ? (
                <a href={city.link} className="Carte_svg__city-link" key={city.id}>
                  {Array.isArray(city.path) ? (
                    city.path.map((path, index) => (
                      <path
                        id={city.id}
                        key={`${city.id}-${index}`}
                        d={path}
                        fill={city.fill || '#21212B'}
                        className={`${city.className} `}
                      />
                    ))
                  ) : (
                    <path
                      id={city.id}
                      key={city.id}
                      d={city.path}
                      fill={city.fill || '#21212B'}
                      className={`${city.className} `}
                    />
                  )}
                </a>
              ) : Array.isArray(city.path) ? (
                city.path.map((path, index) => (
                  <path
                    id={city.id}
                    key={`${city.id}-${index}`}
                    d={path}
                    fill={city.fill || '#21212B'}
                    className={`${city.className} `}
                  />
                ))
              ) : (
                <path
                  id={city.id}
                  key={city.id}
                  d={city.path}
                  fill={city.fill || '#21212B'}
                  className={`${city.className} `}
                />
              )}
            </g>
          ))}
        </g>
      </g>
      <g id="Carte_svg__Decors">
        <g id="maison">
          <path
            id="Vector_47"
            d="M384.5 306.6L373.1 307.6V297.1L384.5 296.1V306.6Z"
            fill="#F7AC6F"
          />
          <path
            id="Vector_48"
            d="M384.5 295.6L373.1 296.6L368.4 289L379.8 288L384.5 295.6Z"
            fill="#F7AC6F"
          />
          <path
            id="Vector_49"
            d="M372.4 297L367.7 289.4L362 295.7V307.2H363.8V300.5C363.8 298.9 365.1 297.6 366.7 297.6C368.3 297.6 369.6 298.9 369.6 300.5V307.5H372.5C372.5 307.6 372.5 297 372.5 297H372.4Z"
            fill="#F7AC6F"
          />
        </g>
        <g id="maison2">
          <path
            id="Vector_29"
            d="M547.5 414.6L536.1 415.6V405.2L547.5 404.1V414.6Z"
            fill="#F7AC6F"
          />
          <path
            id="Vector_30"
            d="M547.4 403.6L536 404.6L531.4 397L542.8 396L547.4 403.6Z"
            fill="#F7AC6F"
          />
          <path
            id="Vector_31"
            d="M535.4 405L530.7 397.4L525 403.7V415.2H526.8V408.5C526.8 406.9 528.1 405.6 529.7 405.6C531.3 405.6 532.6 406.9 532.6 408.5V415.5H535.5C535.5 415.6 535.5 405 535.5 405H535.4Z"
            fill="#F7AC6F"
          />
        </g>
        <g id="maison3">
          <path id="Vector_53" d="M431.3 491.4L419.9 492.5V482L431.3 481V491.4Z" fill="#F7AC6F" />
          <path
            id="Vector_54"
            d="M431.201 480.4L419.801 481.5L415.201 473.9L426.601 472.8L431.201 480.4Z"
            fill="#F7AC6F"
          />
          <path
            id="Vector_55"
            d="M419.201 481.8L414.501 474.2L408.801 480.5V492H410.601V485.3C410.601 483.7 411.901 482.4 413.501 482.4C415.101 482.4 416.401 483.7 416.401 485.3V492.3H419.301C419.301 492.4 419.301 481.8 419.301 481.8H419.201Z"
            fill="#F7AC6F"
          />
        </g>
        <g id="maison4">
          <path id="Vector_50" d="M552 679.9L540.6 681V670.5L552 669.5V679.9Z" fill="#F7AC6F" />
          <path
            id="Vector_51"
            d="M552 668.9L540.5 670L535.9 662.4L547.3 661.3L552 668.9Z"
            fill="#F7AC6F"
          />
          <path
            id="Vector_52"
            d="M539.9 670.3L535.2 662.7L529.5 669V680.5H531.3V673.8C531.3 672.2 532.6 670.9 534.2 670.9C535.8 670.9 537.1 672.2 537.1 673.8V680.8H540C540 680.9 540 670.3 540 670.3H539.9Z"
            fill="#F7AC6F"
          />
        </g>

        <g id="arbre7">
          <path
            id="Vector_9"
            d="M396.799 455.2C395.699 454.7 394.599 454.8 393.599 455.2C392.599 454.8 391.499 454.7 390.399 455.2C389.299 455.7 388.499 456.5 388.099 457.4C387.099 457.8 386.299 458.6 385.799 459.6C385.299 460.6 385.399 461.8 385.799 462.8C385.399 463.8 385.299 464.9 385.799 466C386.199 467.1 387.099 467.9 387.999 468.3C388.399 469.3 389.199 470.1 390.199 470.6C391.199 471.1 392.399 471 393.399 470.6C394.399 471 395.499 471.1 396.599 470.6C397.699 470.2 398.499 469.3 398.899 468.4C399.899 468 400.699 467.2 401.199 466.2C401.699 465.2 401.599 464 401.199 463C401.599 462 401.699 460.9 401.199 459.8C400.699 458.7 399.899 457.9 398.999 457.5C398.599 456.5 397.799 455.7 396.799 455.2Z"
            fill="#BCBF86"
          />
          <path
            id="Vector_10"
            d="M395 466H392.1L391.1 476C391.1 476.2 391.3 476.4 391.5 476.4H395.5C395.7 476.4 395.9 476.2 395.9 476L394.9 466H395Z"
            fill="#BCBF86"
          />
        </g>
        <g id="arbre6">
          <path
            id="Vector_11"
            d="M650.2 564.2C649.1 563.7 648 563.8 647 564.2C646 563.8 644.9 563.7 643.8 564.2C642.7 564.7 641.9 565.5 641.5 566.4C640.5 566.8 639.7 567.6 639.2 568.6C638.7 569.6 638.8 570.8 639.2 571.8C638.8 572.8 638.7 573.9 639.2 575C639.6 576.1 640.5 576.9 641.4 577.3C641.8 578.3 642.6 579.1 643.6 579.6C644.6 580.1 645.8 580 646.8 579.6C647.8 580 648.9 580.1 650 579.6C651.1 579.2 651.9 578.3 652.3 577.4C653.3 577 654.1 576.2 654.6 575.2C655.1 574.2 655 573 654.6 572C655 571 655.1 569.9 654.6 568.8C654.1 567.7 653.3 566.9 652.4 566.5C652 565.5 651.2 564.7 650.2 564.2Z"
            fill="#D0DB5F"
          />
          <path
            id="Vector_12"
            d="M648.4 575H645.5L644.5 585C644.5 585.2 644.7 585.4 644.9 585.4H648.9C649.1 585.4 649.3 585.2 649.3 585L648.3 575H648.4Z"
            fill="#D0DB5F"
          />
        </g>
        <g id="arbre5">
          <path
            id="Vector_13"
            d="M377.1 470.6C376 470.1 374.9 470.2 373.9 470.6C372.9 470.2 371.8 470.1 370.7 470.6C369.6 471.1 368.8 471.9 368.4 472.8C367.4 473.2 366.6 474 366.1 475C365.6 476 365.7 477.2 366.1 478.2C365.7 479.2 365.6 480.3 366.1 481.4C366.5 482.5 367.4 483.3 368.3 483.7C368.7 484.7 369.5 485.5 370.5 486C371.5 486.5 372.7 486.4 373.7 486C374.7 486.4 375.8 486.5 376.9 486C378 485.6 378.8 484.7 379.2 483.8C380.2 483.4 381 482.6 381.5 481.6C382 480.6 381.9 479.4 381.5 478.4C381.9 477.4 382 476.3 381.5 475.2C381 474.1 380.2 473.3 379.3 472.9C378.9 471.9 378.1 471.1 377.1 470.6Z"
            fill="#D0DB5F"
          />
          <path
            id="Vector_14"
            d="M375.3 481.5H372.4L371.4 491.5C371.4 491.7 371.6 491.9 371.8 491.9H375.8C376 491.9 376.2 491.7 376.2 491.5L375.2 481.5H375.3Z"
            fill="#D0DB5F"
          />
        </g>
        <g id="arbre4">
          <path
            id="Vector_15"
            d="M499.339 349.339C498.239 348.839 497.139 348.939 496.139 349.339C495.139 348.939 494.039 348.839 492.939 349.339C491.839 349.839 491.039 350.639 490.639 351.539C489.639 351.939 488.839 352.739 488.339 353.739C487.839 354.739 487.939 355.939 488.339 356.939C487.939 357.939 487.839 359.039 488.339 360.139C488.739 361.239 489.639 362.039 490.539 362.439C490.939 363.439 491.739 364.239 492.739 364.739C493.739 365.239 494.939 365.139 495.939 364.739C496.939 365.139 498.039 365.239 499.139 364.739C500.239 364.339 501.039 363.439 501.439 362.539C502.439 362.139 503.239 361.339 503.739 360.339C504.239 359.339 504.139 358.139 503.739 357.139C504.139 356.139 504.239 355.039 503.739 353.939C503.239 352.839 502.439 352.039 501.539 351.639C501.139 350.639 500.339 349.839 499.339 349.339Z"
            fill="#BCBF86"
          />
          <path
            id="Vector_16"
            d="M497.539 360.139H494.639L493.639 370.139C493.639 370.339 493.839 370.539 494.039 370.539H498.039C498.239 370.539 498.439 370.339 498.439 370.139L497.439 360.139H497.539Z"
            fill="#BCBF86"
          />
        </g>
        <g id="arbre3">
          <path
            id="Vector_17"
            d="M520.339 370.339C519.239 369.839 518.139 369.939 517.139 370.339C516.139 369.939 515.039 369.839 513.939 370.339C512.839 370.839 512.039 371.639 511.639 372.539C510.639 372.939 509.839 373.739 509.339 374.739C508.839 375.739 508.939 376.939 509.339 377.939C508.939 378.939 508.839 380.039 509.339 381.139C509.739 382.239 510.638 383.039 511.538 383.439C511.938 384.439 512.738 385.239 513.738 385.739C514.738 386.239 515.939 386.139 516.939 385.739C517.939 386.139 519.039 386.239 520.139 385.739C521.239 385.339 522.039 384.439 522.439 383.539C523.439 383.139 524.238 382.339 524.738 381.339C525.238 380.339 525.138 379.139 524.738 378.139C525.138 377.139 525.238 376.039 524.738 374.939C524.238 373.839 523.438 373.039 522.538 372.639C522.138 371.639 521.339 370.839 520.339 370.339Z"
            fill="#D0DB5F"
          />
          <path
            id="Vector_18"
            d="M518.539 381.139H515.639L514.639 391.139C514.639 391.339 514.839 391.539 515.039 391.539H519.039C519.239 391.539 519.439 391.339 519.439 391.139L518.439 381.139H518.539Z"
            fill="#D0DB5F"
          />
        </g>
        <g id="arbre2">
          <path
            id="Vector_19"
            d="M621.001 301.2C619.901 300.7 618.801 300.8 617.801 301.2C616.801 300.8 615.701 300.7 614.601 301.2C613.501 301.7 612.701 302.5 612.301 303.4C611.301 303.8 610.501 304.6 610.001 305.6C609.501 306.6 609.601 307.8 610.001 308.8C609.601 309.8 609.501 310.9 610.001 312C610.401 313.1 611.301 313.9 612.201 314.3C612.601 315.3 613.401 316.1 614.401 316.6C615.401 317.1 616.601 317 617.601 316.6C618.601 317 619.701 317.1 620.801 316.6C621.901 316.2 622.701 315.3 623.101 314.4C624.101 314 624.901 313.2 625.401 312.2C625.901 311.2 625.801 310 625.401 309C625.801 308 625.901 306.9 625.401 305.8C624.901 304.7 624.101 303.9 623.201 303.5C622.801 302.5 622.001 301.7 621.001 301.2Z"
            fill="#D0DB5F"
          />
          <path
            id="Vector_20"
            d="M619.201 312.1H616.301L615.301 322.1C615.301 322.3 615.501 322.5 615.701 322.5H619.701C619.901 322.5 620.101 322.3 620.101 322.1L619.101 312.1H619.201Z"
            fill="#D0DB5F"
          />
        </g>
        <g id="arbre1">
          <path
            id="Vector_21"
            d="M642.1 322.9C641 322.4 639.9 322.5 638.9 322.9C637.9 322.5 636.8 322.4 635.7 322.9C634.6 323.4 633.8 324.2 633.4 325.1C632.4 325.5 631.6 326.3 631.1 327.3C630.6 328.3 630.7 329.5 631.1 330.5C630.7 331.5 630.6 332.6 631.1 333.7C631.5 334.8 632.4 335.6 633.3 336C633.7 337 634.5 337.8 635.5 338.3C636.5 338.8 637.7 338.7 638.7 338.3C639.7 338.7 640.8 338.8 641.9 338.3C643 337.9 643.8 337 644.2 336.1C645.2 335.7 646 334.9 646.5 333.9C647 332.9 646.9 331.7 646.5 330.7C646.9 329.7 647 328.6 646.5 327.5C646 326.4 645.2 325.6 644.3 325.2C643.9 324.2 643.1 323.4 642.1 322.9Z"
            fill="#BCBF86"
          />
          <path
            id="Vector_22"
            d="M640.3 333.7H637.4L636.4 343.7C636.4 343.9 636.6 344.1 636.8 344.1H640.8C641 344.1 641.2 343.9 641.2 343.7L640.2 333.7H640.3Z"
            fill="#BCBF86"
          />
        </g>
        <path
          id="sapin6"
          d="M316.401 405.8L308.601 384.5L300.801 405.8H306.601V413.6H310.601V405.8H316.401Z"
          fill="#D0DB5F"
        />
        <path
          id="sapin5"
          d="M675.1 557.8L667.3 536.5L659.6 557.8H665.4V565.6H669.3V557.8H675.1Z"
          fill="#BCBF86"
        />
        <path
          id="sapin4"
          d="M866 370.3L858.3 349L850.5 370.3H856.3V378H860.2V370.3H866Z"
          fill="#D0DB5F"
        />
        <path
          id="sapin3"
          d="M615.301 530.4L607.601 509.1L599.801 530.4H605.601V538.1H609.601V530.4H615.301Z"
          fill="#D0DB5F"
        />
        <path
          id="sapin2"
          d="M391.5 270.3L383.7 249L376 270.3H381.7V278.1H385.7V270.3H391.5Z"
          fill="#D0DB5F"
        />
        <path
          id="sapin1"
          d="M294.4 398.5L286.7 377.2L278.9 398.5H284.7V406.3H288.6V398.5H294.4Z"
          fill="#BCBF86"
        />
      </g>
      <g id="Carte_svg__FLOWVELO_-_OVER">
        <path
          d="M284.9 295.3c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM384.2 369.3c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM646.8 446.7c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM483.2 419.5c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5Z"
          className="Carte_svg__st13"
        />
        <path
          d="m239.6 270.6 11.6 5.1c6.1 2.7 10.2 8.7 10.1 15.3-.1 9.8 10.2 16.1 18.8 11.5l8-4.2c8.7-4.6 19 2.3 18.4 12.2.8 6.6 5.8 12.1 12.3 13.3 25.5 4.8 54.7 20.4 65.6 49.9 13.4 36.1 29.4 50.4 99 50.4H515c12.3-.1 24.1 4.7 32.8 13.5h0c8.7 8.7 20.4 13.5 32.7 13.5h136.4c28.2 0 55.7 5.9 81 17.1"
          style={{
            fill: 'none',
            strokeWidth: 2,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            stroke: strokeStyles['flowvelo'].stroke,
          }}
        />
      </g>
      <g id="Carte_svg__TRANSIBERIQUE_-_OVER">
        <path
          d="M805.6 306.3c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM646.8 446.7c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5Z"
          className="Carte_svg__st19"
        />
        <path
          d="M805.9 232v130.1c0 49.1-39.8 88.9-88.9 88.9h0-134.5c-24 0-43.4 19.5-43.4 43.4h0c0 11.5-4.6 22.6-12.7 30.7l-.9.9c-8.1 8.1-12.7 19.2-12.7 30.7v46.8c0 9.8-3.9 19.2-10.8 26.1h0c-6.9 6.9-10.8 16.3-10.8 26.1h0c0 13.9-9.6 25.5-22.5 28.7"
          style={{
            fill: 'none',
            strokeWidth: 2,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            stroke: strokeStyles['scandiberique'].stroke,
          }}
        />
      </g>
      <g id="Carte_svg__VELODYSSEE_-_OVER">
        <path
          d="M285.9 460.6c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM284.9 295.3c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM242.3 341.1c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM233.6 191.1c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5Z"
          className="Carte_svg__st24"
        />
        <path
          d="M244.7 107.6q.9 1.5 1.5 3h0c2.3 5.3 6.3 9.8 11.4 12.5l13.5 7.4-.7.6-31.1 27.6c-9.9 8.8-12.6 23.2-6.6 35l1.2 2.4 21 41.3c4.3 10.1 6.5 20.9 6.5 31.9v21.9c-.1 9.7 10.2 15.9 18.7 11.3l8-4.2c8.7-4.6 19 2.3 18.4 12.2h0c-.4 6.2-5.2 11.3-11.3 12.1-11.2 1.3-28.6 3.3-39 4.6-5.2.6-9.4 4.3-10.8 9.3-8.9 31.7-22.3 30.9-40.6 49.9-14.4 15 12.7 31.7 25.6 37.7 13.8 6.3 40.6 23.4 55 40.5"
          style={{
            fill: 'none',
            strokeWidth: 2,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            stroke: strokeStyles['velodyssee'].stroke,
          }}
        />
        <path
          d="M271 126.3c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5Z"
          className="Carte_svg__st24"
        />
      </g>
      <g id="Carte_svg__CANAL2MER_-_OVER">
        <path
          d="M285.4 464.4c10.8 12.9 44.3 50.7 44.3 50.7.8.8 1.5 1.6 2.3 2.5 16.7 18.5 28 41.3 33.4 65.6l5.1 22.8"
          style={{
            fill: 'none',
            strokeWidth: 2,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            stroke: strokeStyles['canal2mer'].stroke,
          }}
        />
        <path
          d="M285.9 460.6c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5Z"
          style={{
            fill: '#002684',
          }}
        />
      </g>
      <g id="Carte_svg__FRANCETTE_-_OVER">
        <path
          d="m233.8 195.7-.9-1.9c-6-11.8-3.3-26.2 6.6-35l31.1-27.6c5.4-4.8 12.4-7.5 19.6-7.5h70.9"
          style={{
            fill: 'none',
            stroke: strokeStyles['francette'].stroke,
            strokeWidth: 2,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          d="M271 126.3c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM233.6 191.1c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5Z"
          className="Carte_svg__st8"
        />
      </g>
      <g id="Carte_svg__VELIDEALE_-_OVER">
        <path
          d="m605 274.9 10.4 8.3c2.8 2.2 6.2 3.5 9.8 3.5l51.2.4c3.4 0 6.7 1.1 9.4 3.2l6.8 5.1c4.5 3.4 10.4 4.1 15.6 2l48.6-20.2c1.9-.8 4-1.2 6.1-1.2h27c8.8 0 15.9 7.1 15.9 15.9v95.7c0 8.8 7.1 15.9 15.9 15.9h33.5"
          style={{
            fill: 'none',
            stroke: strokeStyles['velideale'].stroke,
            strokeWidth: 2,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <path
          d="M681.5 282.7c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5ZM805.6 306.3c2.5 0 4.6 1.3 4.6 4.3s-1.1 4.5-4.6 4.5-4.2-2.3-4.2-4.2 1.7-4.5 4.2-4.5Z"
          className="Carte_svg__st13"
        />
      </g>
    </svg>
  );
};

export default SvgCarte;
