import React from 'react';
import { PictoProps } from './index';

export const InfiniteHeart = ({ width, height, fill }: PictoProps) => (
  <svg width={width || '20'} height={height || '20'} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.47851 12.2886C7.77343 12.0178 8.97147 11.2329 9.92438 10.0091C9.97182 9.95182 10.0193 9.89053 10.0766 9.82331C10.1913 9.68097 10.3237 9.51886 10.476 9.30337C11.0809 8.47897 11.506 7.79692 11.9152 7.13463C12.0536 6.91123 12.192 6.68586 12.3402 6.45851C12.5735 6.08684 12.8444 5.66179 12.9689 5.49967C13.5541 4.75436 14.5722 3.841 15.9976 4.08021C17.3163 4.30163 18.3621 5.37908 18.4945 6.70958C18.6725 8.4849 17.2767 9.9874 15.537 9.9874C14.5742 9.9874 13.6648 9.52084 13.1014 8.73796C13.0065 8.60945 12.787 8.31488 12.6249 8.11323L12.5715 8.05195C12.101 8.77156 11.5574 9.57224 11.1659 10.1495C12.0002 11.2527 13.218 12.0178 14.5189 12.2866L11.2766 15.5012C10.8437 15.9302 10.1458 15.9302 9.71482 15.5012L6.47654 12.2866L6.47851 12.2886ZM20.9124 7.01601C20.9124 4.05253 18.5005 1.64062 15.537 1.64062C13.8546 1.64062 12.2671 2.48479 11.071 4.02288C10.9227 4.20871 10.7468 4.47956 10.5807 4.74249C10.2031 5.29605 9.93821 5.71516 9.66144 6.15801C9.43211 6.51584 9.19883 6.88751 8.89042 7.36001C8.62353 7.75935 8.16092 8.36035 8.02846 8.53235C7.4413 9.28755 6.40932 10.2009 4.97997 9.94786C3.66528 9.71656 2.62737 8.63713 2.50084 7.3086C2.33083 5.53921 3.72459 4.04265 5.46037 4.04265C6.37571 4.04265 7.31477 4.5191 7.91973 5.29209C7.91973 5.29209 8.25383 5.65388 8.45746 5.901L8.5484 6.01566C8.94775 5.42455 9.4677 4.65748 9.91054 3.97543C8.87065 2.51247 7.21197 1.64063 5.4584 1.64063C2.49491 1.64063 0.0830078 4.05253 0.0830078 7.01601C0.0830078 8.64701 0.814488 10.112 1.96509 11.0985L8.41792 17.5019C9.5705 18.6446 11.4289 18.6446 12.5814 17.5019L19.0955 11.0451C20.2106 10.0566 20.9163 8.61736 20.9163 7.01404"
      fill={fill || 'white'} />
  </svg>
);
