import React from 'react';
import FavoriteButton from '../../features/favorites/components/Button';
import { RequirementsProps } from '../../tools/context';
import { useWindowSize } from '../../tools/hooks';
import { imageUrlFor } from '../../tools/sanity';
import ShareButton from '../Buttons/Share';
import { Copyright } from '../Copyright';
import { StickyItem } from '../StickyItem';

interface ArticleHeroProps {
  requirements: RequirementsProps;
  _id: string;
  title: string;
  description?: string;
  image: {
    asset: {
      _id: string;
      metadata?: any;
      altText?: string;
      title?: string;
      description?: string;
      creditLine?: string;
    };
  };
  desktopImage?: {
    asset: {
      _id: string;
      metadata?: any;
      altText?: string;
      title?: string;
      description?: string;
    };
  };
  heroColor?: {
    hex: string;
    rgb: string;
    hsl: string;
  };
}

export default function ArticleHero({
  requirements,
  _id,
  title,
  description,
  image,
  desktopImage,
  heroColor,
}: ArticleHeroProps) {
  const { isMobile } = useWindowSize();
  const imageUrl = imageUrlFor(image, requirements).url();
  const desktopImageUrl = desktopImage ? imageUrlFor(desktopImage, requirements).url() : null;

  return (
    <div className="ArticleHero">
      {image?.asset?.title && (
        <Copyright title={image.asset?.creditLine} className="ArticleHero__copyright" />
      )}
      {image ? (
        <img
          className="ArticleHero__image"
          src={!isMobile && desktopImageUrl ? desktopImageUrl : imageUrl}
          alt={title}
        />
      ) : null}
      <div className="ArticleHero__container" style={{ backgroundColor: heroColor?.hex }}>
        {!!title ? (
          <StickyItem>
            <h1>{title}</h1>
          </StickyItem>
        ) : null}
        {!!description ? <h2>{description}</h2> : null}
        <StickyItem position="right">
          <div className="ArticleHero__container__buttonsContainer">
            <FavoriteButton
              favorite={{
                type: 'article',
                data: {
                  article_id: _id?.replace('drafts.', ''),
                },
              }}
            />
            <ShareButton />
          </div>
        </StickyItem>
      </div>
    </div>
  );
}
