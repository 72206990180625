import React, { useEffect } from 'react';
import { PAGE_ROUTES } from '../../../../App';
import { RequirementsProps } from '../../../../tools/context';
import { getAuthActions } from '../../store';

const { setAccessToken } = getAuthActions;

interface GoogleAuthCallbackProps {
  requirements: RequirementsProps;
}

const GoogleAuthCallback: React.FC<GoogleAuthCallbackProps> = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      setAccessToken(token);
    } else {
      console.error('Login failed: No token returned');
      window.location.href = `${PAGE_ROUTES.GUEST.LOGIN}?error=google_login_failed`;
    }
  }, []);

  return null;
};

export default GoogleAuthCallback;
