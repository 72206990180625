import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import React from 'react';
import { RequirementsProps } from '../../tools/context';
import { imageUrlFor } from '../../tools/sanity';
import { SocialPictures } from './index';

interface SocialBannerImagesContainerProps {
  socialPictures: SocialPictures[];
  requirements: RequirementsProps;
}

interface SocialBannerImagesProps {
  image: SanityImageSource;
  picto: SanityImageSource;
  requirements: RequirementsProps;
}

const SocialBannerImages: React.FC<SocialBannerImagesProps> = ({ image, picto, requirements }) => {
  return (
    <div className="social-banner-images">
      <img
        className="social-banner-images__image"
        src={imageUrlFor(image, requirements).width(300).format("webp").url()}
        alt=""
      />
      <img
        className="social-banner-images__picto"
        src={imageUrlFor(picto, requirements).url()}
        alt=""
      />
    </div>
  );
};

const SocialBannerImagesContainer: React.FC<SocialBannerImagesContainerProps> = ({
  socialPictures,
  requirements,
}) => (
  <div className="social-banner__images__container">
    {socialPictures.map((picture, index) => (
      <SocialBannerImages
        image={picture.image}
        picto={picture.picto}
        requirements={requirements}
        key={index}
      />
    ))}
  </div>
);

export default SocialBannerImagesContainer;
