import useEmblaCarousel from 'embla-carousel-react';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { RequirementsProps } from '../../tools/context';
import { eventTracking } from '../../tools/eventTracking';
import { imageUrlFor } from '../../tools/sanity';
import { Infinite, InfiniteHeart, StarCharentes } from '../Picto/charentes/Icons';

interface EventItem {
  image: any;
  mobileImage: any;
  title: string;
  subtitle: string;
  query?: string;
  redirectLink?: string;
}

interface EventsSectionProps {
  title: {
    text: string;
    icon?: string;
    titleTextColor?: any;
  };
  backgroundColor?: {
    hex: string;
  };
  items: EventItem[];
  requirements: RequirementsProps;
}

const getThemeIcon = (iconKey?: string, fill?: string) => {
  switch (iconKey) {
    case 'Infinite':
      return <Infinite fill={fill} />;
    case 'star-charentes':
      return <StarCharentes fill={fill} />;
    case 'infinite-heart':
      return <InfiniteHeart fill={fill} />;
    default:
      return <InfiniteHeart fill={fill} />;
  }
};

export default function EventsSection({
  title,
  items,
  requirements,
  backgroundColor,
}: EventsSectionProps) {
  const mobileWidth = 1024;
  const firstRowCardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const secondRowCardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [cardOffsets, setCardOffsets] = useState<number[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [emblaRef] = useEmblaCarousel();
  const resizeTimeout = useRef<NodeJS.Timeout>();
  const background = backgroundColor?.hex ?? '#141040';
  const textColor = title.titleTextColor?.hex ?? '#D0DB5F';

  useEffect(() => {
    firstRowCardRefs.current = firstRowCardRefs.current.slice(0, 5);
    secondRowCardRefs.current = secondRowCardRefs.current.slice(0, 5);
  }, []);

  useEffect(() => {
    const adjustCardPositions = () => {
      if (window.innerWidth <= mobileWidth) {
        setCardOffsets([]);
        return;
      }

      requestAnimationFrame(() => {
        const newOffsets = [];
        let hasChanged = false;

        for (let i = 0; i < 5; i++) {
          const firstRowCard = firstRowCardRefs.current[i];
          const secondRowCard = secondRowCardRefs.current[i];

          if (firstRowCard && secondRowCard) {
            const firstRowBottom = firstRowCard.offsetTop + firstRowCard.offsetHeight;
            const secondRowTop = secondRowCard.offsetTop;

            const difference = Math.round(secondRowTop - firstRowBottom - 20);

            if (difference !== cardOffsets[i]) {
              hasChanged = true;
            }
            newOffsets.push(difference);
          }
        }

        if (hasChanged) {
          setCardOffsets(newOffsets);
        }
      });
    };

    const handleResize = () => {
      const newIsMobile = window.innerWidth <= mobileWidth;
      setIsMobile(newIsMobile);

      if (!newIsMobile) {
        if (resizeTimeout.current) {
          clearTimeout(resizeTimeout.current);
        }
        resizeTimeout.current = setTimeout(() => {
          requestAnimationFrame(adjustCardPositions);
        }, 100);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
      }
    };
  }, [mobileWidth, cardOffsets]);

  const renderContent = () => {
    if (isMobile) {
      return (
        <div className="embla" ref={emblaRef}>
          <div className="embla__container">
            {items.map((item, index) => (
              <div key={index} className="embla__slide">
                <a
                  onClick={() => eventTracking('interaction', 'clic-suggestion', 'clic-suggestion')}
                  href={
                    item.query
                      ? `/engine/charentes-engine/chat?chat_id=${uuidv4()}&query=${encodeURIComponent(item.query)}`
                      : item.redirectLink
                  }
                  className="content"
                >
                  <div
                    className="card"
                    style={{
                      backgroundPosition: 'bottom',
                      backgroundImage: item.mobileImage
                        ? `url(${imageUrlFor(item.mobileImage, requirements).width(238).format('webp').url()})`
                        : undefined,
                    }}
                  >
                    <div className="content">
                      <p className="cardTitle">{item.title}</p>
                      <p className="subtitle">{item.subtitle}</p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return (
      <div className={`grid${items.length < 6 ? ' line' : ''}`}>
        {items.map((item, index) => (
          <div
            key={index}
            ref={
              index < 5
                ? (el) => {
                    firstRowCardRefs.current[index] = el;
                  }
                : (el) => {
                    secondRowCardRefs.current[index - 5] = el;
                  }
            }
            className="card"
            style={{
              backgroundImage: item.image
                ? `url(${imageUrlFor(item.image, requirements).width(220).format('webp').url()})`
                : undefined,
              ...(index >= 5 && {
                transform: `translateY(-${cardOffsets[index - 5] || 0}px)`,
              }),
            }}
            onClick={() => {
              eventTracking('interaction', 'clic-suggestion', 'clic-suggestion');
              if (item.query) {
                window.location.href = `/engine/charentes-engine/chat?chat_id=${uuidv4()}&query=${encodeURIComponent(item.query)}`;
              } else if (item.redirectLink) {
                window.location.href = item.redirectLink;
              }
            }}
            role="button"
            tabIndex={0}
          >
            <div className="overlay"></div>
            <div className="content">
              <p className="cardTitle">{item.title}</p>
              <p className="subtitle">{item.subtitle}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <section className="eventsSection" style={{ backgroundColor: background }}>
      <div className="container">
        <h2 style={{ color: textColor }}>
          {getThemeIcon(title?.icon, textColor)}
          {title?.text ?? ''}
        </h2>
        {renderContent()}
      </div>
    </section>
  );
}
