declare global {
  interface Window {
    _paq?: Array<string[] | [string, ...any[]]>;
  }
}

export const eventTracking = (
  category: string,
  action: string,
  name: string,
  value: number = 0
): void => {
  if (typeof window !== 'undefined' && window._paq) {
    window._paq.push(['trackEvent', category, action, name, value]);
  }
};
