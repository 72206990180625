import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { languageMap } from '../../tools/constants/languages';
import { useTranslation } from '../../tools/i18n';
import { handleSpecialLanguageChange } from '../../tools/languageUtils';
import { languageSelectorStyles } from './styles';

interface Language {
  code: string;
  name: string;
}

interface LanguageSelectorProps {
  customerID: string;
  endpoint: string;
  requirements?: any;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  customerID,
  endpoint,
  requirements,
}) => {
  const { i18n, t } = useTranslation();
  const [languages, setLanguages] = useState<Language[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [daysElement, setDaysElement] = useState<HTMLElement | null>(null);
  const clientStyles = {
    ...languageSelectorStyles.default,
    ...(languageSelectorStyles[requirements?.config?.key] || {}),
  };

  const fetchLanguages = async () => {
    try {
      const url = `${endpoint}/api/jsonConfiguration/keys/${customerID}`;
      const response = await fetch(url);
      const codes: string[] = await response.json();
      const validLanguages = codes
        .filter((code) => languageMap.hasOwnProperty(code))
        .map((code) => ({
          code: code,
          name: languageMap[code],
        }));
      setLanguages(validLanguages);
    } catch (error) {
      console.error('Error fetching languages:', error);
      setError('Failed to load languages. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLanguages().catch(console.error);
  }, [customerID]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDaysElement(document.getElementById('days'));
    }
  }, []);

  const changeLanguage = (selectedOption: { value: string; label: string } | null) => {
    if (!selectedOption) return;

    i18n.changeLanguage(selectedOption.value);

    handleSpecialLanguageChange(requirements.config.key, selectedOption.value);
  };

  const getLanguageName = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlLang = urlParams.get('lng');

    const langCode = (urlLang || i18n.language).split('-')[0];
    return languageMap[langCode] || 'Select language';
  }, [i18n.language]);

  const handleMenuOpen = () => {
    if (!daysElement) return;
    daysElement.style.zIndex = '0';
  };

  const handleMenuClose = () => {
    if (!daysElement) return;
    daysElement.style.zIndex = '100';
  };

  const options = languages.map((lang) => ({
    value: lang.code,
    label: lang.name,
  }));

  return (
    <div className="LanguageSelector">
      {isLoading ? (
        <p>{t('common.loading')}...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Select
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          value={options.find((option) => option.value === getLanguageName())}
          onChange={changeLanguage}
          options={options}
          isClearable={false}
          placeholder={getLanguageName()}
          className="basic-single"
          classNamePrefix="select"
          styles={{
            container: (baseStyles) => ({
              ...baseStyles,
              width: 'fit-content',
              minWidth: clientStyles?.container?.minWidth,
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: clientStyles?.control?.border,
              fontWeight: clientStyles?.control?.fontWeight,
              fontSize: clientStyles?.control?.fontSize,
              boxShadow: clientStyles?.control?.boxShadow,
              cursor: clientStyles?.control?.cursor,
              textAlign: clientStyles?.control?.textAlign,
              minHeight: clientStyles?.control?.minHeight,
              '&:hover': {
                backgroundColor: clientStyles?.control?.hoverBgColor,
              },
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: state.isSelected
                ? clientStyles?.option?.selectedBgColor
                : state.isFocused
                  ? clientStyles?.option?.focusedBgColor
                  : clientStyles?.option?.defaultBgColor,
              color: clientStyles?.option?.color,
              cursor: 'pointer',
              ':active': {
                backgroundColor: clientStyles?.option?.selectedBgColor,
              },
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              boxShadow: clientStyles?.menu?.boxShadow,
              borderRadius: clientStyles?.menu?.borderRadius,
              marginTop: clientStyles?.menu?.marginTop,
              minWidth: clientStyles?.menu?.minWidth,
              width: 'fit-content',
              textAlign: clientStyles?.menu?.textAlign,
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color: clientStyles?.singleValue?.color,
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              color: clientStyles?.dropdownIndicator?.color,
              ':hover': {
                color: clientStyles?.dropdownIndicator?.hoverColor,
              },
            }),
            indicatorSeparator: () => ({
              display: clientStyles?.indicatorSeparator?.display || 'none',
            }),
          }}
        />
      )}
    </div>
  );
};

export default React.memo(LanguageSelector);
