import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { languageMap } from '../../../tools/constants/languages';
import { useWindowSize } from '../../../tools/hooks';
import { useTranslation } from '../../../tools/i18n';
import ButtonV2 from '../../ButtonV2';
import { StickyItem } from '../../StickyItem';

export interface DescriptionWithLang {
  lang: string;
  value: string;
}

export interface DescriptionShopV2Props {
  title: string;
  descriptions: DescriptionWithLang[];
}

const DescriptionShopV2: React.FC<DescriptionShopV2Props> = ({ title, descriptions }) => {
  const { i18n } = useTranslation();
  const { isMobile } = useWindowSize();

  const currentDescription =
    descriptions?.find((d) => d?.lang === i18n.language)?.value ||
    descriptions?.find((d) => d?.lang === 'fr')?.value;

  const missingFlagsMatchMap = {
    en: 'GB',
    zh: 'CN',
    'pt-br': 'BR',
    ja: 'JP',
  };

  const handleChangeLang = (newLang: string) => {
    i18n.changeLanguage(newLang).catch(console.error);
  };

  return (
    <div className="DescriptionShopV2">
      {isMobile ? null : (
        <StickyItem>
          <h1 className="DescriptionShopV2__title">{title}</h1>
        </StickyItem>
      )}
      {currentDescription ? (
        <p
          className="DescriptionShopV2__description"
          dangerouslySetInnerHTML={{ __html: currentDescription }}
        />
      ) : null}
      {descriptions?.length ? (
        <div className="DescriptionShopV2__langs">
          {descriptions?.map((d) =>
            d ? (
              <ButtonV2
                key={d.lang}
                className={`DescriptionShopV2__langs__button ${i18n.language === d.lang ? 'DescriptionShopV2__langs__button--selected' : ''}`}
                text={languageMap[d.lang]}
                variant="clear"
                align="right"
                onClick={() => handleChangeLang(d.lang)}
              >
                <div className="DescriptionShopV2__langs__flag">
                  <ReactCountryFlag
                    countryCode={missingFlagsMatchMap[d.lang] || d.lang.toUpperCase()}
                    svg
                    title={languageMap[d.lang]}
                  />
                </div>
              </ButtonV2>
            ) : null
          )}
        </div>
      ) : null}
    </div>
  );
};

export default DescriptionShopV2;
