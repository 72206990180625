import React from 'react';
import { PictoProps } from './index';

export const Youtube = (props: PictoProps) => (
  <svg
    width={props.width || '20'}
    height={props.height || '20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_56_2210)">
      <path
        d="M23.6637 7.08991C23.6637 7.08991 23.4329 5.46053 22.7221 4.74508C21.822 3.80345 20.8158 3.79883 20.3542 3.74344C17.0493 3.50342 12.0872 3.50342 12.0872 3.50342H12.078C12.078 3.50342 7.116 3.50342 3.81107 3.74344C3.34949 3.79883 2.34323 3.80345 1.44315 4.74508C0.732312 5.46053 0.506136 7.08991 0.506136 7.08991C0.506136 7.08991 0.266113 9.00548 0.266113 10.9164V12.7074C0.266113 14.6183 0.50152 16.5339 0.50152 16.5339C0.50152 16.5339 0.732312 18.1633 1.43853 18.8787C2.33862 19.8204 3.52027 19.7881 4.04647 19.8896C5.93896 20.0696 12.0826 20.125 12.0826 20.125C12.0826 20.125 17.0493 20.1158 20.3542 19.8804C20.8158 19.825 21.822 19.8204 22.7221 18.8787C23.4329 18.1633 23.6637 16.5339 23.6637 16.5339C23.6637 16.5339 23.8991 14.6229 23.8991 12.7074V10.9164C23.8991 9.00548 23.6637 7.08991 23.6637 7.08991ZM9.64086 14.8814V8.23925L16.0245 11.5719L9.64086 14.8814Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_56_2210">
        <rect width="23.633" height="23.633" fill="white" transform="translate(0.266113)" />
      </clipPath>
    </defs>
  </svg>
);
