import React from 'react';
import ButtonV2 from '../../../../../components/ButtonV2';

interface EngineCardProps {
  chat_id: string;
  query: string;
}

const EngineCard: React.FC<EngineCardProps> = ({ chat_id, query }) => {
  return (
    <div className="EngineCard">
      <ButtonV2 text={query} leftIcon="sparkles" />
    </div>
  );
};

export default EngineCard;
