import React from 'react';
import DefaultCarousel from '../../../../components/DefaultCarousel';
import { RequirementsProps } from '../../../../tools/context';
import { useWindowSize } from '../../../../tools/hooks';
import { useTranslation } from '../../../../tools/i18n';
import FavoriteCard from '../Card';
import EditFavoritesGroup from '../EditGroup';
import { FavoritesGroup } from '../types';

interface FavoritesGroupCompProps {
  favoritesGroup: FavoritesGroup;
  requirements: RequirementsProps;
}

const FavoritesGroupComp = ({ favoritesGroup, requirements }: FavoritesGroupCompProps) => {
  const { t } = useTranslation();
  const { isTablet, width: windowWidth } = useWindowSize();
  const hasArticles = favoritesGroup.favorites?.some((f) => f.type === 'article');

  return (
    <div className="FavoritesGroupComp">
      <div className="FavoritesGroupComp__header">
        <h5>
          {favoritesGroup.name} ({favoritesGroup.favorites?.length})
        </h5>
        <EditFavoritesGroup favoritesGroup={favoritesGroup} />
      </div>
      {favoritesGroup.favorites?.length ? (
        <div className="FavoritesGroupComp__cards">
          <DefaultCarousel
            items={favoritesGroup.favorites
              .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
              .map((f) => (
                <FavoriteCard key={f.id} favorite={f} requirements={requirements} />
              ))}
            swiperProps={{
              slidesPerView:
                favoritesGroup.favorites.length > 1
                  ? (hasArticles && windowWidth < 480) || isTablet
                    ? 1
                    : 2
                  : 1,
              loop: false,
              spaceBetween: 0,
              slidesPerGroup: (hasArticles && windowWidth < 480) || isTablet ? 1 : 2,
            }}
            flexOnDesktop
          />
        </div>
      ) : (
        <p className="FavoritesGroupComp__empty">{t('ssrComponents.favorites.group.empty')}</p>
      )}
    </div>
  );
};

export default FavoritesGroupComp;
