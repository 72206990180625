import React, { Fragment, ReactNode, useState } from 'react';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';
import { A11y, Keyboard, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { useWindowSize } from '../../tools/hooks';
import { Picto } from '../Picto';

interface DefaultCarouselProps {
  items: ReactNode[];
  swiperProps?: SwiperProps;
  flexOnDesktop?: boolean;
}

const DefaultCarousel: React.FC<DefaultCarouselProps> = ({
  items,
  swiperProps,
  flexOnDesktop = false,
}: DefaultCarouselProps) => {
  const { isDesktop } = useWindowSize();
  const [swiper, setSwiper] = useState(null);
  const [activePageIndex, setActivePageIndex] = useState(0);

  if (!items?.length) return null;

  const nbPages =
    items.length / (swiperProps?.slidesPerView || 1) +
    (items.length % (swiperProps?.slidesPerView || 1) !== 0 ? 1 : 0);

  return (
    <div className={`DefaultCarousel DefaultCarousel--${items.length}`}>
      {flexOnDesktop && isDesktop ? (
        <div className="DefaultCarousel__items">
          {items.map((item, index) => (
            <div key={index} className="DefaultCarousel__items__item">
              {item}
            </div>
          ))}
        </div>
      ) : (
        <div className="DefaultCarousel__carousel">
          <Swiper
            modules={[Keyboard, Navigation, Pagination, A11y]}
            onSwiper={setSwiper}
            onSlideChange={(swiper: any) =>
              setActivePageIndex(swiper.realIndex / (swiperProps?.slidesPerView || 1))
            }
            loop={swiperProps.loop}
            spaceBetween={20}
            {...swiperProps}
          >
            {items.map((item, groupIndex) => (
              <SwiperSlide key={groupIndex}>{item}</SwiperSlide>
            ))}
            {items?.length % (swiperProps?.slidesPerView || 1) !== 0
              ? Array.from({ length: (swiperProps?.slidesPerView || 1) - (items.length % 2) }).map(
                  (_, index) => <SwiperSlide key={items.length + index} />
                )
              : null}
          </Swiper>
          {nbPages > 1 ? (
            <div className="DefaultCarousel__carousel__pagination">
              {Array.from({ length: nbPages }).map((_, index) => (
                <button
                  key={index}
                  className={`DefaultCarousel__carousel__pagination__button ${
                    activePageIndex === index
                      ? 'DefaultCarousel__carousel__pagination__button--active'
                      : ''
                  }`}
                  onClick={() => swiper?.slideToLoop(index)}
                />
              ))}
            </div>
          ) : null}
          {nbPages > 1 ? (
            <Fragment>
              {activePageIndex > 0 ? (
                <button
                  className="DefaultCarousel__carousel__navigationButton DefaultCarousel__carousel__navigationPrev"
                  onClick={() => swiper?.slidePrev()}
                >
                  <Picto iconKey="arrowLeftV2" />
                </button>
              ) : null}
              {activePageIndex < nbPages - 1 ? (
                <button
                  className="DefaultCarousel__carousel__navigationButton DefaultCarousel__carousel__navigationNext"
                  onClick={() => swiper?.slideNext()}
                >
                  <Picto iconKey="arrowRightV2" />
                </button>
              ) : null}
            </Fragment>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default DefaultCarousel;
