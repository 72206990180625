import React from 'react';
import GoogleLoginButton from '../GoogleLoginButton';

interface SSOAuthProps {}

const SSOAuth = (props: SSOAuthProps) => {
  return (
    <div className="SSOAuth">
      <GoogleLoginButton />
    </div>
  );
};

export default SSOAuth;
