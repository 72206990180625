import React, { FC } from "react";
import { Picto, pictos } from "../Picto";
import { Oval } from "react-loader-spinner";

type PictoButtonSize = "small" | "medium";

interface PictoButtonProps {
  iconKey: keyof typeof pictos | string;
  size?: PictoButtonSize;
  backgroundColor?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  pictoSize?: number;
  hideButton?: boolean;
  className?: string;
}

const getSize = (size?: PictoButtonSize, container?: boolean): number => {
  switch (size) {
    case "small":
      return container ? 14 : 12;
    case "medium":
    default:
      return container ? 30 : 15;
  }
};

const PictoButton: FC<PictoButtonProps> = ({
  iconKey,
  size,
  backgroundColor,
  disabled,
  loading,
  onClick,
  pictoSize,
  hideButton,
  className,
}) =>
  loading ? (
    <Oval
      color="#000"
      height={getSize(size, true)}
      width={getSize(size, true)}
    />
  ) : (
    <button
      className={`pictoButton ${className || ""}`}
      nonce="__nonce__"
      style={{
        display: hideButton ? "none" : "flex",
        width: getSize(size, true),
        height: getSize(size, true),
        justifyContent: "center",
        alignItems: "center",
        borderRadius: Math.round(getSize(size, true) / 2),
        backgroundColor,
        cursor:
          typeof onClick !== "undefined" && !disabled ? "pointer" : undefined,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Picto
        className={className}
        iconKey={iconKey}
        width={pictoSize ? String(pictoSize) : `${getSize(size, false)}`}
        height={pictoSize ? String(pictoSize) : `${getSize(size, false)}`}
        fill="#FFF"
      />
    </button>
  );

export default PictoButton;
