import React from 'react';
import { PictoProps } from './index';

export const YoutubeV2 = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="youtubeV2"
  >
    <path
      d="M17.2346 1.88H6.76545C4.06782 1.88 1.88 4.06782 1.88 6.76545V17.2346C1.88 19.9322 4.06782 22.12 6.76545 22.12H17.2346C19.9322 22.12 22.12 19.9322 22.12 17.2346V6.76545C22.12 4.06782 19.9322 1.88 17.2346 1.88Z"
      stroke={fill || 'white'}
      strokeWidth="1.84"
    />
    <path
      d="M10.6816 15.8769C10.0683 16.231 9.3016 15.7884 9.3016 15.0802L9.3016 9.50298C9.3016 8.79477 10.0683 8.35213 10.6816 8.70624L15.5116 11.4948C16.1249 11.849 16.1249 12.7342 15.5116 13.0883L10.6816 15.8769Z"
      fill={fill || 'white'}
    />
  </svg>
);
