import React from 'react';
import { PictoProps } from './index';

export const Backpack = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 23 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2965 16.8504H17.6983V24.8017H18.8565C20.4471 24.8017 21.7365 23.5123 21.7365 21.9217V18.2904C21.7365 17.4951 21.0918 16.8504 20.2965 16.8504Z"
      stroke={fill || 'white'}
      strokeWidth="1.44"
    />
    <path
      d="M2.7037 16.8504H5.30196V24.8017H4.1437C2.55312 24.8017 1.2637 23.5123 1.2637 21.9217V18.2904C1.2637 17.4951 1.90841 16.8504 2.7037 16.8504Z"
      stroke={fill || 'white'}
      strokeWidth="1.44"
    />
    <path
      d="M5.17654 12.1548H17.8235V23.3618C17.8235 24.1571 17.1788 24.8018 16.3835 24.8018H6.61654C5.82125 24.8018 5.17654 24.1571 5.17654 23.3618V12.1548Z"
      stroke={fill || 'white'}
      strokeWidth="1.44"
    />
    <path
      d="M9.74716 16.8504H13.2532C14.0485 16.8504 14.6932 17.4951 14.6932 18.2904V24.8017H8.30716V18.2904C8.30716 17.4951 8.95187 16.8504 9.74716 16.8504Z"
      stroke={fill || 'white'}
      strokeWidth="1.44"
    />
    <mask id="path-5-inside-1_616_1858" fill={fill || 'white'}>
      <path d="M6.80444 1.19827C6.80444 0.800627 7.1268 0.478271 7.52444 0.478271H9.99749C10.3951 0.478271 10.7175 0.800627 10.7175 1.19827V5.17392H6.80444V1.19827Z" />
    </mask>
    <path
      d="M6.80444 1.19827C6.80444 0.800627 7.1268 0.478271 7.52444 0.478271H9.99749C10.3951 0.478271 10.7175 0.800627 10.7175 1.19827V5.17392H6.80444V1.19827Z"
      stroke={fill || 'white'}
      strokeWidth="2.88"
      mask="url(#path-5-inside-1_616_1858)"
    />
    <mask id="path-6-inside-2_616_1858" fill={fill || 'white'}>
      <path d="M12.2827 1.19827C12.2827 0.800627 12.6051 0.478271 13.0027 0.478271H15.4758C15.8734 0.478271 16.1958 0.800627 16.1958 1.19827V5.17392H12.2827V1.19827Z" />
    </mask>
    <path
      d="M12.2827 1.19827C12.2827 0.800627 12.6051 0.478271 13.0027 0.478271H15.4758C15.8734 0.478271 16.1958 0.800627 16.1958 1.19827V5.17392H12.2827V1.19827Z"
      stroke={fill || 'white'}
      strokeWidth="2.88"
      mask="url(#path-6-inside-2_616_1858)"
    />
    <rect
      x="4.39407"
      y="4.3287"
      width="14.2122"
      height="7.9513"
      rx="2.88"
      stroke={fill || 'white'}
      strokeWidth="1.44"
    />
  </svg>
);
