import { SanityImageObject } from '@sanity/image-url/lib/types/types';
import * as React from 'react';
import FavoriteButton from '../../../../features/favorites/components/Button';
import { RequirementsProps } from '../../../../tools/context';
import { useTranslation } from '../../../../tools/i18n';
import { imageUrlFor } from '../../../../tools/sanity';
import ButtonV2 from '../../../ButtonV2';

export interface ArticleCardProps {
  requirements: RequirementsProps;
  _id: string;
  _updatedAt: string;
  _createdAt: string;
  title: string;
  slug: {
    _type: 'slug';
    current: string;
  };
  description?: string;
  image: SanityImageObject;
  openGraphTitle?: string;
  openGraphImage?: SanityImageObject;
}

const ArticleCard: React.FC<ArticleCardProps> = ({
  requirements,
  _id,
  title,
  slug,
  image,
  openGraphImage,
  openGraphTitle,
}) => {
  const { t } = useTranslation();
  const imageUrl = image ? imageUrlFor(image, requirements).url() : null;
  const ogImageUrl = openGraphImage ? imageUrlFor(openGraphImage, requirements).url() : null;

  const finalTitle = openGraphTitle ?? title;
  const finalImage = ogImageUrl ?? imageUrl;

  return (
    <div className="ArticleCard">
      <img className="ArticleCard__image" src={finalImage} alt={finalTitle} />
      <div className="ArticleCard__content">
        {!!finalTitle ? <span>{finalTitle}</span> : null}
        <ButtonV2
          text={t('ssrComponents.articleCard.read')}
          variant="clear"
          link={`/${slug?.current}`}
          linkTarget="_self"
        />
      </div>
      <FavoriteButton favorite={{ type: 'article', data: { article_id: _id } }} withoutText />
    </div>
  );
};

export default ArticleCard;
