import React from 'react';
import { PictoProps } from './index';

export const Schedules = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1998 8.00005C15.1998 11.9765 11.9763 15.2 7.9998 15.2C4.02335 15.2 0.799805 11.9765 0.799805 8.00005C0.799805 4.0236 4.02335 0.800049 7.9998 0.800049C11.9763 0.800049 15.1998 4.0236 15.1998 8.00005ZM11.7657 4.63431C12.0781 4.94673 12.0781 5.45327 11.7657 5.76569L8.37157 9.1598C8.05915 9.47222 7.55262 9.47222 7.2402 9.1598C7.23387 9.15347 7.22767 9.14706 7.22159 9.14057C7.19009 9.11724 7.15992 9.09129 7.13137 9.06274L5.43431 7.36569C5.1219 7.05327 5.1219 6.54673 5.43431 6.23431C5.74673 5.9219 6.25327 5.9219 6.56569 6.23431L7.8 7.46863L10.6343 4.63431C10.9467 4.3219 11.4533 4.3219 11.7657 4.63431Z"
      fill={fill || 'white'}
    />
  </svg>
);
