import React from 'react';
import { PictoProps } from './index';

export const Direction = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 15 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4477 13.3478L13.5781 10.4783L10.4477 7.60871"
      stroke={fill || 'white'}
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5781 10.4783H7.78682C5.7986 10.4783 4.18682 12.09 4.18682 14.0783V17.2609"
      stroke={fill || 'white'}
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.04395 4.73914L4.17438 1.6087L1.30481 4.73914"
      stroke={fill || 'white'}
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.1748 1.6087L4.1748 20.3913"
      stroke={fill || 'white'}
      strokeWidth="1.44"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
