import React from 'react';
import { PictoProps } from './index';

export const Edit = ({ width, height, fill }: PictoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      stroke={fill || 'white'}
      d="M12.9,4.4L14.3,3c0.4-0.4,1-0.4,1.4,0L17,4.4c0.4,0.4,0.4,1,0,1.4l-1.4,1.4c-0.1,0.1-0.3,0.1-0.5,0l-2.3-2.3
    C12.8,4.7,12.8,4.5,12.9,4.4z M12.4,5.3l2.3,2.3c0.1,0.1,0.1,0.3,0,0.5l-8.8,8.8c-0.1,0.1-0.2,0.1-0.3,0.1l-2.4,0.3
    c-0.4,0.1-0.6-0.2-0.5-0.5l0.3-2.4c0-0.1,0.1-0.2,0.1-0.3L12,5.3C12.1,5.2,12.3,5.2,12.4,5.3z"
    />
  </svg>
);
