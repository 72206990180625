import React, { useEffect, useState } from 'react';
import ArticleCard from '../../../../components/Carousel/ArticlesCarousel/ArticleCard';
import ShopV2Card, { ShopV2CardProps } from '../../../../components/ShopV2Card';
import { getStoreInfo } from '../../../../tools/ciblerAPI';
import { RequirementsProps } from '../../../../tools/context';
import { useTranslation } from '../../../../tools/i18n';
import { CustomDataStore, getStoreInfoFromProvider } from '../../../../tools/providersAPI';
import { getSanityClient } from '../../../../tools/sanity';
import { getFormattedEuro } from '../../../../tools/tools';
import { Favorite } from '../types';
import EngineCard from './Engine';

interface FavoriteCardProps {
  favorite: Favorite;
  requirements: RequirementsProps;
}

const FavoriteCard = ({ favorite, requirements }: FavoriteCardProps) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<ShopV2CardProps>();
  const [link, setLink] = useState<string>();

  useEffect(() => {
    const getShopData = async (shopId: string) => {
      const storeData = await getStoreInfo(shopId, requirements);
      let customData: CustomDataStore['store'] = {};

      if (storeData?.data?.apidae_id) {
        const d = await getStoreInfoFromProvider(
          requirements,
          'apidae',
          storeData?.data?.apidae_id
        );
        customData = (d[i18n.language] ?? d['fr'])?.store;
      }

      return {
        imageUrls: [
          customData?.images?.[0]?.url ??
            storeData?.data?.images?.[0] ?? ['https://placehold.co/320?text=📷'],
        ],
        title: customData?.name ?? storeData?.name,
        details: [
          { icon: 'mapPin', title: customData?.address?.city || storeData?.data?.city },
          !!customData?.minimumPrice
            ? {
                icon: 'euroV2',
                title: t('shopV2.hero.fromPrice', {
                  value: getFormattedEuro(customData?.minimumPrice),
                }),
              }
            : null,
        ],
      } as ShopV2CardProps;
    };

    const getArticleData = async (articleId: string) => {
      const query = `*[_type == "article" && (_id == "${articleId}" || _id == "drafts.${articleId}")]`;
      const client = getSanityClient(requirements);
      const data = await client.fetch(query);

      return {
        requirements,
        ...data?.[0],
      };
    };

    const getShopLink = async (shopId: string) => {
      const storeData = await getStoreInfo(shopId, requirements);

      return `/etablissement/${storeData?.data?.slug}`;
    };

    const getArticleLink = async (articleId: string) => {
      const query = `*[_type == "article" && (_id == "${articleId}" || _id == "drafts.${articleId}")]`;
      const client = getSanityClient(requirements);
      const data = await client.fetch(query);

      return data?.[0]?.slug?.current;
    };

    const getFavoriteData = async (favorite: Favorite) => {
      switch (favorite.type) {
        case 'article':
          return getArticleData(favorite.data?.article_id);
        case 'engine':
          return favorite.data;
        case 'shop':
          return getShopData(favorite.data?.shop_id);
        default:
          return null;
      }
    };

    const getFavoriteLink = async (favorite: Favorite) => {
      switch (favorite.type) {
        case 'article':
          return getArticleLink(favorite.data?.article_id);
        case 'engine':
          return `/engine/${requirements.partner}-engine/chat?chat_id=${favorite.data?.chat_id}&query=${favorite.data?.query}`;
        case 'shop':
          return getShopLink(favorite.data?.shop_id);
        default:
          return null;
      }
    };

    getFavoriteData(favorite)
      .then((data) => setData(data))
      .catch((error) => console.error('Error fetching favorite data', error));

    getFavoriteLink(favorite)
      .then((link) => setLink(link))
      .catch((error) => console.error('Error fetching favorite link', error));
  }, [favorite, i18n.language, requirements]);

  const types = {
    shop: ShopV2Card,
    article: ArticleCard,
    engine: EngineCard,
  };

  const Component = types[favorite.type];

  return (
    <div className="FavoriteCard" onClick={() => (link ? (window.location.href = link) : null)}>
      {/*@ts-ignore*/}
      <Component {...data} />
    </div>
  );
};

export default FavoriteCard;
