import React from 'react';
import { PictoProps } from './index';

export const Cross = (props: PictoProps) => (
  <svg
    width={props.width || '15'}
    height={props.height || '14'}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="cross"
  >
    <path
      d="M13.4443 1L1.44434 13"
      stroke="#004653"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.44434 1L13.4443 13"
      stroke="#004653"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
