import React from 'react';
import { PictoProps } from './index';

export const FacebookV2 = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="facebookV2"
  >
    <path
      d="M14.7572 11.3489L14.6248 12.4714C14.6026 12.6591 14.4519 12.7999 14.2737 12.7999H12.5518V17.4937C12.37 17.5111 12.186 17.52 12.0002 17.52C11.5841 17.52 11.1779 17.4759 10.7854 17.3924V12.8004H9.46089C9.33955 12.8004 9.23999 12.6953 9.23999 12.5662V11.1616C9.23999 11.033 9.33911 10.9275 9.46089 10.9275H10.7854V8.82082C10.7854 7.52793 11.7739 6.48001 12.9931 6.48001H14.5386C14.6599 6.48001 14.7595 6.58513 14.7595 6.71419V8.11876C14.7595 8.24735 14.6603 8.35294 14.5386 8.35294H13.4345C12.9469 8.35294 12.5518 8.77201 12.5518 9.28917V10.9279H14.4061C14.619 10.9279 14.7835 11.126 14.7572 11.3493V11.3489Z"
      fill={fill || 'white'}
    />
    <path
      d="M17.2346 1.88H6.76545C4.06782 1.88 1.88 4.06782 1.88 6.76545V17.2346C1.88 19.9322 4.06782 22.12 6.76545 22.12H17.2346C19.9322 22.12 22.12 19.9322 22.12 17.2346V6.76545C22.12 4.06782 19.9322 1.88 17.2346 1.88Z"
      stroke={fill || 'white'}
      strokeWidth="1.84"
    />
  </svg>
);
