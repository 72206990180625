import { useEffect, useState } from 'react';

interface UseTypingAnimationParams {
  placeholderSentences: string[];
  TYPING_SPEED: number;
  PAUSE_TIME: number;
  query: string;
}

const useTypingAnimation = ({
  placeholderSentences,
  TYPING_SPEED,
  PAUSE_TIME,
  query,
}: UseTypingAnimationParams) => {
  const [currentText, setCurrentText] = useState('');
  const [charIndex, setCharIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    if (query.length === 0) {
      const interval = setInterval(() => {
        if (charIndex < placeholderSentences[textIndex].length) {
          setCurrentText((prev) => prev + placeholderSentences[textIndex][charIndex]);
          setCharIndex((prev) => prev + 1);
        } else {
          clearInterval(interval);
          setTimeout(() => {
            setCharIndex(0);
            setCurrentText('');
            setTextIndex((prev) => (prev + 1) % placeholderSentences.length);
          }, PAUSE_TIME);
        }
      }, TYPING_SPEED);
      return () => clearInterval(interval);
    }
  }, [charIndex, textIndex, placeholderSentences, query, PAUSE_TIME, TYPING_SPEED]);

  return currentText;
};

export default useTypingAnimation;
