import React from 'react';
import { PictoProps } from './index';

export const ArrowLeftV2 = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 1L2 6.5L8 12" stroke={fill || 'white'} strokeWidth="2" />
    <line x1="2" y1="6.5" x2="16" y2="6.5" stroke={fill || 'white'} strokeWidth="2" />
  </svg>
);
