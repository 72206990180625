import React from 'react';
import { PAGE_ROUTES } from '../../../../App';
import { Header } from '../../../../components';
import { AuthConfigProps, RequirementsProps } from '../../../../tools/context';
import { imageUrlFor } from '../../../../tools/sanity';
import GoogleAuthCallback from '../../components/GoogleCallback';
import ForgotPasswordPage from '../../pages/guest/ForgotPassword';
import LoginPage from '../../pages/guest/Login';
import SignupPage from '../../pages/guest/Signup';

interface GuestStackProps {
  authConfig: AuthConfigProps;
  requirements: RequirementsProps;
  navigation: any;
  headerButtons: any;
  headerType?: 'classic' | 'modern';
  footerType?: 'classic' | 'modern';
  header?: any;
}

const GuestStack = ({
  authConfig,
  requirements,
  headerType = 'classic',
  ...rest
}: GuestStackProps) => {
  const pages = [
    {
      path: PAGE_ROUTES.GUEST.LOGIN,
      component: LoginPage,
    },
    {
      path: PAGE_ROUTES.GUEST.SIGNUP,
      component: SignupPage,
    },
    {
      path: PAGE_ROUTES.GUEST.FORGOT_PASSWORD,
      component: ForgotPasswordPage,
    },
    {
      path: PAGE_ROUTES.GUEST.GOOGLE_CALLBACK,
      component: GoogleAuthCallback,
    },
  ];

  const Page = pages.find((page) => page.path === window.location.pathname)?.component;

  const background = authConfig?.guestPagesBackground;
  const backgroundUrl = background?.asset
    ? imageUrlFor(background.asset, requirements).url()
    : null;

  return (
    <div className="GuestStack">
      <Header {...rest} requirements={requirements} headerType={headerType || 'classic'} />
      <div className="GuestStack__wrapper">
        {backgroundUrl ? (
          <img
            className="GuestStack__wrapper__background"
            src={backgroundUrl}
            alt="Arrière plan invité"
          />
        ) : null}
        <div className="GuestStack__wrapper__page-container">
          <div className="GuestStack__wrapper__page-container__page">
            <Page requirements={requirements} {...rest} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestStack;
