import React from 'react';
import { RequirementsProps } from '../../../tools/context';
import { imageUrlFor } from '../../../tools/sanity';
import { Copyright } from '../../Copyright';
import ScrollingEngineSuggestions, { Suggestion } from '../../ScrollingEngineSuggestions';
import SearchBoxIA from './SearchBoxIA';

interface HeroSuggestionsProps {
  backgroundImage: {
    asset: {
      _id?: string;
      altText?: string;
      title?: string;
      description?: string;
      creditLine?: string;
    };
    alt?: string;
    tags?: string[];
    caption?: string;
  };
  isSmall?: boolean;
  title: string;
  subtitle: string;
  suggestions: Suggestion[];
  requirements?: RequirementsProps;
  siteConfiguration: any;
  opacityColor?: string;
  opacityValue?: number;
}

export const HeroSuggestions: React.FC<HeroSuggestionsProps> = ({
  backgroundImage,
  isSmall,
  title,
  subtitle,
  suggestions,
  requirements,
  siteConfiguration,
  opacityColor,
  opacityValue,
}) => {
  const placeholderSentences = siteConfiguration?.placeholderSentences;
  return (
    <div className={`HeroSuggestions ${isSmall ? 'HeroSuggestions--small' : ''}`}>
      {backgroundImage ? (
        <img
          className="HeroSuggestions__background__image"
          loading="lazy"
          srcSet={`${imageUrlFor(backgroundImage, requirements)
            .width(400)
            .format('webp')
            .url()} 400w,
            ${imageUrlFor(backgroundImage, requirements).width(800).format('webp').url()} 800w,
            ${imageUrlFor(backgroundImage, requirements).width(1200).format('webp').url()} 1200w,
                        `}
          sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, 1200px"
          src={imageUrlFor(backgroundImage, requirements).height(500).format('webp').url()}
          alt=""
        />
      ) : null}
      <div
        className="HeroSuggestions__background__overlay"
        style={
          {
            backgroundColor:
              opacityColor === 'white'
                ? `rgba(255, 255, 255, ${opacityValue || 0})`
                : `rgba(0, 0, 0, ${opacityValue || 0})`,
          } as React.CSSProperties
        }
      ></div>
      {backgroundImage?.asset?.title && (
        <Copyright
          title={backgroundImage.asset?.creditLine}
          className="HeroSuggestions__copyright"
        />
      )}
      <div className="container">
        <h1 className="title">{title}</h1>
        <h2 className="subtitle">{subtitle}</h2>
        <SearchBoxIA placeholderSentences={placeholderSentences} />
      </div>
      <ScrollingEngineSuggestions.Renderer requirements={requirements} suggestions={suggestions} />
    </div>
  );
};

export default HeroSuggestions;
