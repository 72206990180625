import React from 'react';
import { useTranslation } from '../../../../tools/i18n';
import { DetailsStoreItem } from '../../../../tools/providersAPI';
import DetailShopV2 from '../../Detail';

const DetailsStoreItemComp: React.FC<DetailsStoreItem> = ({ details }) => {
  const { t } = useTranslation();

  return (
    <div className="DetailsStoreItemComp">
      {details?.map(({ title, ...rest }, index) => (
        <DetailShopV2 key={index} title={t(`shopV2.details.${title}`)} {...rest} />
      ))}
    </div>
  );
};

export default DetailsStoreItemComp;
