import React, { useState } from 'react';
import ButtonV2 from '../../../../../components/ButtonV2';
import Form, { FormProps } from '../../../../../components/Form';
import { RequirementsProps } from '../../../../../tools/context';
import { useTranslation } from '../../../../../tools/i18n';
import { usersApiRegister } from '../../../../../tools/usersApi';
import SSOAuth from '../../../components/SSOAuth';
import { getAuthActions } from '../../../store';
import { UserProfile, userProfiles, UserRegister } from '../../../types';

const { setAccessToken } = getAuthActions;

interface SignupPageProps {
  requirements: RequirementsProps;
}

const SignupPage = ({ requirements }: SignupPageProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const fields: FormProps['fields'] = [
    {
      name: 'first_name',
      type: 'text',
      autoComplete: 'given-name',
      label: t('ssrComponents.form.fields.firstname'),
    },
    {
      name: 'last_name',
      autoComplete: 'family-name',
      type: 'text',
      label: t('ssrComponents.form.fields.lastname'),
    },
    {
      name: 'city',
      autoComplete: 'city',
      type: 'text',
      label: t('ssrComponents.form.fields.city'),
    },
    {
      name: 'email',
      type: 'email',
      autoComplete: 'email',
      label: t('ssrComponents.form.fields.email'),
    },
    {
      name: 'password',
      type: 'password',
      autoComplete: 'current-password',
      label: t('ssrComponents.form.fields.password'),
    },
  ];
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleFormSubmit = (data: any) => {
    setIsLoading(true);
    const d = data as Omit<UserRegister, 'profile'>;
    const profile = (localStorage.getItem('userProfile') || 'passager') as UserProfile;

    if (!userProfiles.includes(profile)) {
      console.error('Invalid profile:', profile);
      return;
    }

    const body = { ...d, profile } as UserRegister;

    usersApiRegister(body)
      .then((response) => {
        setAccessToken(response.data?.access_token);
      })
      .catch((error) => {
        switch (error?.data?.detail) {
          case 'Email already registered':
            return setErrors({
              email: t('ssrComponents.auth.errors.emailAlreadyRegistered'),
            });
          default:
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="SignupPage">
      <Form
        title={t('ssrComponents.auth.signup.title')}
        subtitle={t('ssrComponents.auth.signup.subtitle')}
        fields={fields}
        errors={errors}
        onSubmit={handleFormSubmit}
        submitText={t('ssrComponents.auth.signup.submit')}
        isSubmitLoading={isLoading}
      />
      <p className="SignupPage__or">{t('ssrComponents.auth.common.or')}</p>
      <SSOAuth />
      <div className="SignupPage__alreadyHaveAccount">
        <p>{t('ssrComponents.auth.signup.alreadyHaveAccount')}</p>
        <ButtonV2 text={t('ssrComponents.auth.signup.login')} variant="clear" link="/connexion" />
      </div>
    </div>
  );
};

export default SignupPage;
