import React from 'react';
import { RequirementsProps } from '../../../tools/context';
import { CustomDataStore } from '../../../tools/providersAPI';
import { getAssignedDisabilitiesIcon } from '../../../tools/tools';
import { ResponsiveImage } from '../../ResponsiveImage';

export interface LabelsShopV2Props {
  requirements: RequirementsProps;
  labels: CustomDataStore['store']['labels'];
  handicaps: CustomDataStore['store']['handicaps'];
}

const LabelsShopV2: React.FC<LabelsShopV2Props> = ({ requirements, labels, handicaps }) => {
  if (!labels?.length && !handicaps?.length) return null;

  return (
    <div className="LabelsShopV2">
      {handicaps?.length ? (
        <div className="LabelsShopV2__handicaps">
          <ResponsiveImage
            title={`labels tourisme et handicap : ${handicaps.join(',').toLowerCase()}`}
            src={getAssignedDisabilitiesIcon(handicaps)}
            alt={handicaps.toString()}
            requirements={requirements}
          />
        </div>
      ) : null}
      {labels?.map((label) => (
        <ResponsiveImage
          key={label.id}
          title={label.label}
          src={label.logoUrl}
          alt={label.label || `Picto #${label.id}`}
          requirements={requirements}
        />
      ))}
    </div>
  );
};

export default LabelsShopV2;
