import React from 'react';
import { PictoProps } from './index';

export const UserV2 = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="9.83458"
      cy="6.65218"
      rx="5.21739"
      ry="5.21739"
      stroke={fill || 'white'}
      strokeWidth="1.73913"
    />
    <path
      d="M18.3615 20.5632C18.3615 15.8531 14.5432 12.0348 9.83312 12.0348C5.123 12.0348 1.30469 15.8531 1.30469 20.5632"
      stroke={fill || 'white'}
      strokeWidth="1.73913"
      strokeLinecap="round"
    />
  </svg>
);
