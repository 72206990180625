import React, { useEffect, useRef, useState } from 'react';

interface IframeContentProps {
  html?: string;
  updateIframeHeight?: (height: number) => void;
}

const IframeContent: React.FC<IframeContentProps> = ({ html, updateIframeHeight }) => {
  const [height, setHeight] = useState(0);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const updateHeight = () => {
      if (iframeRef.current && iframeRef.current.contentWindow) {
        const newHeight = iframeRef.current.contentWindow.document.body.scrollHeight + 1;
        if (newHeight !== height) {
          setHeight(newHeight);
          updateIframeHeight?.(newHeight);
        }
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.onload = updateHeight;

      if (iframe.contentWindow) {
        const observer = new MutationObserver(updateHeight);
        observer.observe(iframe.contentWindow.document.body, {
          attributes: true,
          childList: true,
          subtree: true,
        });

        // Add resize event listener to the iframe's window
        iframe.contentWindow.addEventListener('resize', updateHeight);

        return () => {
          observer.disconnect();
          iframe.contentWindow?.removeEventListener('resize', updateHeight);
        };
      }
    }
  }, [height, updateIframeHeight]);

  if (!html) return null;

  return (
    <iframe
      ref={iframeRef}
      srcDoc={html}
      style={{ width: '100%', height, border: 'none' }}
      title="HTML Preview"
      loading="lazy"
    />
  );
};

export default IframeContent;
