interface LanguageHandlerConfig {
  shouldReloadWithParam: boolean;
}

export const specialLanguageConfigs: Record<string, LanguageHandlerConfig> = {
  martinique: {
    shouldReloadWithParam: true,
  },
};

export const handleSpecialLanguageChange = (
  key: string | undefined,
  languageCode: string
): void => {
  if (key && specialLanguageConfigs[key]?.shouldReloadWithParam) {
    let currentUrl = window.location.href;

    currentUrl = currentUrl
      .replace(/[?&]lng=[^&]+/, '') // Enlève ?lng=xx ou &lng=xx
      .replace(/\?&/, '?') // Nettoie la syntaxe si nécessaire
      .replace(/\?$/, ''); // Enlève le ? final si présent

    const separator = currentUrl.includes('?') ? '&' : '?';
    const newUrl = `${currentUrl}${separator}lng=${languageCode}`;

    window.location.replace(newUrl);
  }
};

/**
 * Force une langue spécifique pour certaines configurations
 * @param configKey - La clé de configuration (ex: 'otc')
 * @param currentLanguage - La langue actuelle
 * @returns La langue à utiliser
 */
export const getLanguageForConfig = (
  configKey: string | undefined,
  currentLanguage: string
): string => {
  if (configKey === 'otc') {
    return 'fr';
  }
  return currentLanguage;
};
