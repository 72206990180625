import React from 'react';
import { PictoProps } from './index';

export const InstagramV2 = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={'20'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="instagramV2"
  >
    <path
      d="M17.2346 1.88H6.76545C4.06782 1.88 1.88 4.06782 1.88 6.76545V17.2346C1.88 19.9322 4.06782 22.12 6.76545 22.12H17.2346C19.9322 22.12 22.12 19.9322 22.12 17.2346V6.76545C22.12 4.06782 19.9322 1.88 17.2346 1.88Z"
      stroke={fill || 'white'}
      strokeWidth="1.84"
    />
    <path
      d="M7.69603 11.9993C7.69603 9.61849 9.62927 7.68526 12.0101 7.68526C14.3909 7.68526 16.3242 9.61849 16.3242 11.9993C16.3242 14.3802 14.3909 16.3134 12.0101 16.3134C9.62927 16.3134 7.69603 14.3802 7.69603 11.9993Z"
      stroke={fill || 'white'}
      strokeWidth="1.84"
    />
    <path
      d="M17.5929 7.46362C18.1711 7.46362 18.6397 6.99495 18.6397 6.41681C18.6397 5.83867 18.1711 5.37 17.5929 5.37C17.0148 5.37 16.5461 5.83867 16.5461 6.41681C16.5461 6.99495 17.0148 7.46362 17.5929 7.46362Z"
      fill={fill || 'white'}
    />
  </svg>
);
