import * as React from 'react';
import ArticlesCarouselLabel, { ArticlesCarouselLabelProps } from '../Label';
import ArticlesCarouselLink, { ArticlesCarouselLinkProps } from '../Link';

export interface ArticlesCarouselHeaderProps {
  label?: ArticlesCarouselLabelProps;
  title?: string;
  link?: ArticlesCarouselLinkProps;
}

const ArticlesCarouselHeader: React.FC<ArticlesCarouselHeaderProps> = ({ label, title, link }) => {
  return (
    <div className="ArticlesCarouselHeader">
      <ArticlesCarouselLabel {...label} />
      {!!title ? <h2 className="ArticlesCarouselHeader__title">{title}</h2> : null}
      <ArticlesCarouselLink {...link} />
    </div>
  );
};

export default ArticlesCarouselHeader;
