import React from 'react';
import ReactSelect, { OptionProps } from 'react-select';
import { Picto, PictoProps, pictos } from '../Picto';

interface SelectOption {
  icon?: keyof typeof pictos;
  iconProps?: PictoProps;
  label: string;
  value: string;
  [key: string]: any;
}

export interface SelectProps {
  defaultValue?: SelectOption['value'];
  value?: SelectOption['value'];
  options: SelectOption[];
  onChange?: (value: SelectOption) => void;
  isSearchable?: boolean;
  hideSelectedOptions?: boolean;
  className?: string;
}

const Select: React.FC<SelectProps> = ({
  defaultValue,
  value,
  options,
  onChange,
  isSearchable,
  hideSelectedOptions = true,
  className,
}) => {
  const renderSingleValue = ({ data, ...props }: OptionProps<SelectOption>) => {
    const {
      clearValue,
      getStyles,
      getClassNames,
      getValue,
      hasValue,
      isMulti,
      isRtl,
      selectOption,
      selectProps,
      setValue,
      isDisabled,
      cx,
      ...rest
    } = props;

    return (
      <div className="Select__value" {...rest}>
        {!!data?.icon ? <Picto iconKey={data.icon} {...data.iconProps} /> : null}
        <span>{data.label}</span>
      </div>
    );
  };

  const renderOption = ({ data, ...props }: OptionProps<SelectOption>) => (
    <div className="Select__option" {...props} onClick={() => onChange?.(data)}>
      {!!data?.icon ? <Picto iconKey={data.icon} {...data.iconProps} /> : null}
      <span>{data.label}</span>
    </div>
  );

  const renderDropdownIndicator = () => (
    <div className="Select__dropdown-indicator">
      <Picto iconKey="caret-down" />
    </div>
  );

  return (
    <ReactSelect
      className={`Select ${className ?? ''}`}
      value={!!value ? options?.find((option) => option.value === value) : undefined}
      defaultValue={
        !!defaultValue ? options?.find((option) => option.value === defaultValue) : undefined
      }
      options={options as any}
      isSearchable={isSearchable ?? false}
      hideSelectedOptions={hideSelectedOptions}
      classNames={{
        control: () => 'Select__control',
        valueContainer: () => 'Select__value-container',
      }}
      components={{
        IndicatorSeparator: () => null,
        Option: renderOption,
        SingleValue: renderSingleValue,
        DropdownIndicator: renderDropdownIndicator,
      }}
      onChange={onChange}
    />
  );
};

export default Select;
