import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { userApiMe, usersApiUpdateUser } from '../../tools/usersApi';
import { User, UserUpdate } from './types';

interface AuthStore {
  authState: 'guest' | 'loading' | 'loggedIn';
  accessToken?: string;
  user?: User;

  actions: {
    init: () => void;
    updateUser: (data: UserUpdate) => void;
    setAccessToken: (accessToken?: string) => void;
    logout: () => void;
  };
}

const useAuthStore = create<AuthStore>()(
  persist(
    (set, get) => ({
      authState: 'loading',
      accessToken: undefined,
      user: undefined,

      actions: {
        init: () => {
          const token = get().accessToken;
          if (!token) {
            set({ authState: 'guest', user: undefined });
            return;
          }

          userApiMe(token)
            .then((response) => {
              set({ authState: 'loggedIn', user: response.data });
            })
            .catch(() => {
              set({ authState: 'guest', user: undefined });
            });
        },
        updateUser: (data: UserUpdate) => {
          usersApiUpdateUser(data, get().accessToken)
            .then((response) => {
              // Call the init action to update the user data
              get().actions.init();
            })
            .catch(console.error);
        },
        setAccessToken: (accessToken) => {
          set({ accessToken, authState: !!accessToken ? 'loggedIn' : 'guest' });
        },
        logout: () => {
          set({ authState: 'guest', accessToken: undefined, user: undefined });
        },
      },
    }),
    {
      name: 'auth-store', // Storage key
      partialize: (state) => ({
        authState: state.authState,
        accessToken: state.accessToken,
        user: state.user,
      }),
    }
  )
);

// Getters
export const getAuthActions = useAuthStore.getState().actions;

// Selectors
export const useAuthState = () => useAuthStore((state) => state.authState);
export const useAccessToken = () => useAuthStore((state) => state.accessToken);
export const useUser = () => useAuthStore((state) => state.user);
export const useAuthActions = () => useAuthStore((state) => state.actions);

export default useAuthStore;
