import * as React from 'react';
import { useState } from 'react';
import { RequirementsProps } from '../../tools/context';
import IframeContent from '../IframeContent';

export interface EnginePageProps {
  requirements: RequirementsProps;
  content: string;
}

const EnginePage: React.FC<EnginePageProps> = ({ requirements, content }) => {
  const [iframeHeight, setIframeHeight] = useState(0);

  return (
    <div className="EnginePage" style={{ height: iframeHeight }}>
      <IframeContent html={content} updateIframeHeight={setIframeHeight} />
    </div>
  );
};

export default EnginePage;
