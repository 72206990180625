import React from 'react';

export const Loader = () => (
  <svg
    style={{
      margin: 'auto',
      background: 'none',
      display: 'block',
      shapeRendering: 'auto',
    }}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g>
      <path
        d="M50 16A34 34 0 1 0 77.18927838856109 29.58571233891995"
        fill="none"
        stroke="#3b56ca"
        strokeWidth="8"
      />
      <path d="M49 4L49 28L61 16L49 4" fill="#3b56ca" />
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </g>
  </svg>
);
