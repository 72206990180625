import React from 'react';
import { PAGE_ROUTES } from '../../../../App';
import ButtonV2 from '../../../../components/ButtonV2';
import { useTranslation } from '../../../../tools/i18n';
import { USERS_API_BASE_URL } from '../../../../tools/usersApi';

const GoogleLoginButton = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    const callbackUrl = encodeURIComponent(
      window.location.origin + PAGE_ROUTES.GUEST.GOOGLE_CALLBACK
    );
    window.location.href = `${USERS_API_BASE_URL}users/login/google/${callbackUrl}`;
  };

  return (
    <ButtonV2
      className="GoogleLoginButton"
      variant="outline"
      text={t('ssrComponents.auth.sso.continueWithGoogle')}
      leftIcon="google"
      onClick={handleClick}
    />
  );
};

export default GoogleLoginButton;
