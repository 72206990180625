import React, { useRef, useEffect, useState } from 'react';

interface CopyrightProps {
  title: string;
  className?: string;
}

export const Copyright: React.FC<CopyrightProps> = ({ title, className = '' }) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [width, setWidth] = useState(30); // Largeur initiale fermée

  useEffect(() => {
    if (textRef.current) {
      const textWidth = textRef.current.offsetWidth;
      // 30 (largeur initiale) + 15 (symbol) + 8 (margin-left) + 20 (padding)
      setWidth(textWidth + 43);
    }
  }, [title]);

  if (!title) return null;

  return (
    <div className={`copyright-container ${className}`.trim()}>
      <div 
        className="copyright-circle"
        style={{ '--expanded-width': `${width}px` } as React.CSSProperties}
      >
        <span className="copyright-symbol">©</span>
        <span ref={textRef} className="copyright-text">{title}</span>
      </div>
    </div>
  );
}; 