import React from 'react';
import { PictoProps } from './index';

export const Shutdown = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5889 3.15527C15.9727 4.5669 16.826 6.50057 16.826 8.63353C16.826 12.9558 13.3221 16.4596 8.99992 16.4596C4.67769 16.4596 1.17383 12.9558 1.17383 8.63353C1.17383 6.50057 2.02712 4.5669 3.41097 3.15527"
      stroke={fill || 'white'}
      strokeWidth="1.73913"
      strokeLinecap="round"
    />
    <line
      x1="9.02972"
      y1="1.17389"
      x2="9.02972"
      y2="7.26084"
      stroke={fill || 'white'}
      strokeWidth="1.73913"
      strokeLinecap="round"
    />
  </svg>
);
