import React, { useState } from 'react';
import Form, { FormProps } from '../../../../../components/Form';
import { RequirementsProps } from '../../../../../tools/context';
import { useTranslation } from '../../../../../tools/i18n';
import {
  usersApiRequestPasswordReset,
  usersApiSubmitNewPassword,
  usersApiVerifySubmitCode,
} from '../../../../../tools/usersApi';
import { getAuthActions } from '../../../store';
import { UserSubmitNewPassword } from '../../../types';

interface ForgotPasswordPageProps {
  requirements: RequirementsProps;
}

const { setAccessToken } = getAuthActions;

type ForgotPasswordStep = 'email' | 'code' | 'password';

type DataType = {
  [key in ForgotPasswordStep]: {
    subtitle: string;
    fields: FormProps['fields'];
    onChange?: FormProps['onChange'];
    onSubmit: FormProps['onSubmit'];
    submitText: string;
  };
};

const ForgotPasswordPage = ({ requirements }: ForgotPasswordPageProps) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<ForgotPasswordStep>('email');
  const [email, setEmail] = useState<string>('');
  const [resetCode, setResetCode] = useState<number>();
  const [resetAccessToken, setResetAccessToken] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleFormSubmitEmail = () => {
    setIsLoading(true);

    usersApiRequestPasswordReset(email)
      .then(() => setStep('code'))
      .catch((error) => {
        switch (error?.data?.detail) {
          case 'Invalid email':
            return setErrors({
              email: t('ssrComponents.auth.errors.invalidEmail'),
            });
          case 'User is a Google user':
            return setErrors({
              email: t('ssrComponents.auth.errors.googleUser'),
            });
          default:
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleFormSubmitCode = () => {
    setIsLoading(true);

    usersApiVerifySubmitCode(email, resetCode)
      .then((res) => {
        setResetAccessToken(res?.data?.reset_password_token);
        setStep('password');
      })
      .catch((error) => {
        switch (error?.data?.detail) {
          case 'Invalid or expired reset code':
            return setErrors({
              reset_code: t('ssrComponents.auth.errors.invalidOrExpiredResetCode'),
            });
          default:
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleFormSubmitNewPassword = (data: any) => {
    setIsLoading(true);

    if (!resetAccessToken) return setIsLoading(false);

    if (data?.new_password !== data?.new_password_confirmation) {
      setErrors({
        new_password: ' ',
        new_password_confirmation: t('ssrComponents.auth.errors.passwordsDontMatch'),
      });
      return setIsLoading(false);
    }

    const body = data as UserSubmitNewPassword;

    usersApiSubmitNewPassword(resetAccessToken, body?.new_password)
      .then((res) => {
        setAccessToken(res?.data?.access_token);
      })
      .catch((error) => {
        switch (error?.data?.detail) {
          case 'Invalid email':
            return setErrors({
              email: t('ssrComponents.auth.errors.invalidEmail'),
            });
          case 'User is a Google user':
            return setErrors({
              email: t('ssrComponents.auth.errors.googleUser'),
            });
          default:
            console.error(error);
            break;
        }
      })
      .finally(() => setIsLoading(false));
  };

  const data: DataType = {
    email: {
      subtitle: t('ssrComponents.auth.forgotPassword.email.subtitle'),
      fields: [
        {
          name: 'email',
          type: 'email',
          autoComplete: 'email',
          label: t('ssrComponents.form.fields.email'),
          value: email,
        },
      ],
      onChange: (data) => setEmail(String(data?.email)),
      onSubmit: handleFormSubmitEmail,
      submitText: t('ssrComponents.auth.forgotPassword.email.submit'),
    },
    code: {
      subtitle: t('ssrComponents.auth.forgotPassword.code.subtitle'),
      fields: [
        {
          name: 'reset_code',
          type: 'number',
          label: t('ssrComponents.form.fields.resetCode'),
          value: resetCode,
        },
      ],
      onChange: (data) => setResetCode(Number(data?.reset_code)),
      onSubmit: handleFormSubmitCode,
      submitText: t('ssrComponents.auth.forgotPassword.code.submit'),
    },
    password: {
      subtitle: t('ssrComponents.auth.forgotPassword.password.subtitle'),
      fields: [
        {
          name: 'new_password',
          type: 'password',
          autoComplete: 'new-password',
          label: t('ssrComponents.form.fields.newPassword'),
        },
        {
          name: 'new_password_confirmation',
          type: 'password',
          autoComplete: 'new-password',
          label: t('ssrComponents.form.fields.newPasswordConfirmation'),
        },
      ],
      onSubmit: handleFormSubmitNewPassword,
      submitText: t('ssrComponents.auth.forgotPassword.password.submit'),
    },
  };

  const currentData = data[step];

  return (
    <div className="ForgotPasswordPage">
      <Form
        title={t('ssrComponents.auth.forgotPassword.title')}
        subtitle={currentData.subtitle}
        fields={currentData.fields}
        errors={errors}
        onChange={(data) => {
          setErrors({});

          currentData.onChange?.(data);
        }}
        onSubmit={currentData.onSubmit}
        submitText={currentData.submitText}
        isSubmitLoading={isLoading}
        clearFormOnSubmit={step === 'code'}
      />
    </div>
  );
};

export default ForgotPasswordPage;
