import React from 'react';
import Form, { FormProps } from '../../../../components/Form';
import Modal from '../../../../components/Modal';
import { useTranslation } from '../../../../tools/i18n';
import { getFavoritesActions } from '../../store';

const { addFavoritesGroup } = getFavoritesActions;

interface NewFavoritesGroupModalProps {
  isOpen?: boolean;
  onClose: () => void;
}

const NewFavoritesGroupModal = ({ isOpen, onClose }: NewFavoritesGroupModalProps) => {
  const { t } = useTranslation();

  const fields: FormProps['fields'] = [
    {
      label: t('ssrComponents.favorites.newGroupModal.name'),
      name: 'name',
      type: 'text',
      required: true,
    },
  ];

  const handleCreateFavoritesGroup = (values: { [key: string]: any }) => {
    addFavoritesGroup(values.name);
    onClose();
  };

  return (
    <Modal
      title={t('ssrComponents.favorites.newGroup')}
      isOpen={isOpen}
      onClose={onClose}
      className="NewFavoritesGroupModal"
    >
      <Form
        fields={fields}
        submitText={t('ssrComponents.favorites.newGroupModal.create')}
        onSubmit={handleCreateFavoritesGroup}
      />
    </Modal>
  );
};

export default NewFavoritesGroupModal;
