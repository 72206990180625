import React from 'react';
import { PictoProps } from './index';

export const HeartV2 = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.34248 1.18519C6.31237 1.18896 6.20512 1.20213 6.10727 1.21342C4.63015 1.38089 3.2377 2.21824 2.43799 3.41875C1.48774 4.8413 1.34473 6.80954 2.05601 8.61596C2.49632 9.72991 3.21324 10.7535 4.35731 11.9014C5.18525 12.7293 5.78927 13.243 7.72552 14.7634C9.08597 15.8322 9.55639 16.2311 10.1341 16.8107C10.6704 17.347 11.0279 17.8023 11.2461 18.2276C11.2913 18.3179 11.3327 18.3913 11.3383 18.3913C11.344 18.3913 11.376 18.3367 11.4099 18.269C11.6921 17.697 12.2717 17.0158 13.1165 16.2631C13.5926 15.8397 13.9464 15.5518 15.2428 14.5338C16.9364 13.2035 17.514 12.7086 18.3288 11.892C19.2094 11.0095 19.7871 10.2624 20.2538 9.40626C21.0328 7.9743 21.2642 6.35418 20.8973 4.90716C20.6865 4.07922 20.2876 3.36042 19.7024 2.7564C18.7183 1.74029 17.3597 1.1739 15.9127 1.1739C14.2211 1.1739 12.7684 1.94916 11.9311 3.30021C11.7203 3.64267 11.4908 4.16013 11.3892 4.52518C11.3496 4.66442 11.3195 4.71523 11.3195 4.63996C11.3195 4.55529 11.1144 4.01148 10.9733 3.72358C10.2827 2.3142 9.07092 1.45051 7.44326 1.21154C7.30214 1.19084 6.44974 1.17014 6.34248 1.18519Z"
      stroke={fill || 'white'}
      strokeWidth="1.44"
    />
  </svg>
);
