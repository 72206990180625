import * as React from 'react';
import { useEffect } from 'react';
import { getStoreInfo } from '../../tools/ciblerAPI';
import { RequirementsProps } from '../../tools/context';
import { useWindowSize } from '../../tools/hooks';
import { useTranslation } from '../../tools/i18n';
import {
  CustomDataStoreWithLangs,
  getStoreInfoFromProvider,
  StoreInfoProvider,
} from '../../tools/providersAPI';
import { getImageList } from '../../tools/tools';
import ImagesGalleryCarousel from '../ImagesGalleryCarousel';
import ContainerShopV2 from './Container';
import DescriptionShopV2, { DescriptionWithLang } from './Description';
import GroupsShopV2 from './Groups';
import HeroShopV2 from './Hero';

export interface ShopProps {
  componentStates: {
    customData: CustomDataStoreWithLangs;
    images?: string[];
    seller: { [key: string]: any };
    data: { [key: string]: any };
  };
  requirements?: RequirementsProps;
  navigation: any;
  headerButtons: any;
}

const Renderer = ({ componentStates, requirements, navigation, headerButtons }: ShopProps) => {
  const images = componentStates?.images || []; // Utilise un tableau vide si images est `null` ou `undefined`.
  const seller = componentStates?.seller;
  const data = componentStates?.data;
  const customDataWithLangs = componentStates?.customData || {};
  const { t, i18n } = useTranslation();
  const customData = customDataWithLangs[i18n.language] || customDataWithLangs?.fr || {};
  const { isMobile } = useWindowSize();
  const IS_IMAGES_SHOWN = false;

  // Store data in localStorage
  useEffect(() => {
    const name = customData?.store?.name || seller?.name;
    const city = customData?.store?.address?.city || data?.city;
    if (name) localStorage.setItem('store-name', JSON.stringify(name));
    if (city) localStorage.setItem('store-city', JSON.stringify(city));
  }, [seller, data, customData]);

  if (!data) return (window.location.href = '/404');
  // Descriptions (data.description, data.description_en, data.description_fr, data.description_de...)
  const descriptions: DescriptionWithLang[] = Object.keys(data)
    ?.filter((key) => key.includes('description'))
    .map((key) => {
      if (!!!data[key] || data[key] === 'null') return null;

      return {
        lang: key.split('_')[1] || 'fr',
        value: data[key],
      };
    });

  // Merge with customData descriptions
  Object.keys(customDataWithLangs)?.forEach((l) => {
    if (!!!customDataWithLangs[l]?.store?.description) return;
    const old = descriptions?.find((d) => d?.lang === l);
    if (old) old.value = customDataWithLangs[l]?.store?.description;
    else descriptions.push({ lang: l, value: customDataWithLangs[l]?.store?.description });
  });

  const imageList = customData?.store?.images?.length
    ? customData?.store?.images?.map((i) => i.url)
    : getImageList(requirements, images, seller?.frontCateg)?.map((i) => i.src ?? i.fallback);

  return (
    <div className="ShopV2">
      <div className="ShopV2__top__container">
        <HeroShopV2
          requirements={requirements}
          title={customData?.store?.name || seller.name}
          imageUrls={imageList}
          seller={seller}
          data={data}
          customData={customData}
        />
        <div className="ShopV2__container">
          <DescriptionShopV2 title={seller.name} descriptions={descriptions} />
          <div className="ShopV2__content">
            {imageList?.length && IS_IMAGES_SHOWN ? (
              <ContainerShopV2 className="ShopV2__gallery" title={t('shopV2.photosGallery')}>
                <ImagesGalleryCarousel
                  imageUrls={imageList}
                  swiperProps={{ slidesPerView: isMobile ? 1 : 1.05 }}
                />
              </ContainerShopV2>
            ) : null}

            <GroupsShopV2
              seller={seller}
              data={data}
              customData={customData}
              requirements={requirements}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const preloader = async (_: any, requirements: RequirementsProps) => {
  try {
    const param = requirements.parameters.length
      ? requirements.parameters[0]
      : requirements.sellerId;
    const sellerId = param?.split('-')?.pop();

    if (!!!sellerId) return null;

    const storeInfo = await getStoreInfo(sellerId, requirements);
    const providers: StoreInfoProvider[] = ['apidae'];
    if (!storeInfo?.data) {
      return null;
    }

    const provider = providers.find((p) => storeInfo.data[`${p}_id`]);
    const customData = provider
      ? await getStoreInfoFromProvider(requirements, provider, storeInfo.data[`${provider}_id`])
      : {};

    return {
      ...storeInfo,
      customData,
    };
  } catch (error) {
    return null;
  }
};

const headers = (state: any) => {
  const headers = [];

  if (state?.seller?.name) {
    headers.push(`<meta name="name" content="${state?.seller?.name}">`);
  }
  if (state?.data) {
    headers.push(
      `<meta name="address" content="${state.data.street || ''} ${
        state.data.zipcode || ''
      } ${state.data.city || ''}">`
    );
    headers.push(`<meta name="description" content="${state?.data.description || ''}">`);

    if (state.data.noindex === 'true') {
      headers.push('<meta name="robots" content="noindex">');
      headers.push('<meta name="googlebot" content="noindex">');
    }
  }

  return headers;
};

const Shop = { Renderer, preloader, headers };

export default Shop;
export { Renderer, preloader };
