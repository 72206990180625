import React from 'react';
import { PictoProps } from './index';

export const DotsV2 = ({ width, height, fill }: PictoProps) => (
  <svg
    width={width || '18'}
    height={height || '4'}
    viewBox="0 0 18 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2.04382" cy="1.99999" r="1.73913" fill={fill || 'white'} />
    <circle cx="9.00085" cy="1.99999" r="1.73913" fill={fill || 'white'} />
    <circle cx="15.9559" cy="1.99999" r="1.73913" fill={fill || 'white'} />
  </svg>
);
