import React from 'react';
import { RequirementsProps } from '../../tools/context';
import { useTranslation } from '../../tools/i18n';

export interface LogoProps {
  className?: string;
  logoLink?: string;
  requirements: RequirementsProps;
  location?: 'header' | 'footer';
  target_blank?: boolean;
}

const Logo: React.FC<LogoProps> = ({
  requirements,
  className = '',
  location = 'header',
  logoLink = '/',
  target_blank = false,
}) => {
  const { t } = useTranslation();
  const logo = requirements?.config?.[location === 'header' ? 'headerLogo' : 'footerLogo'];
  const logoClassName =
    `${className} ${requirements.config.showTagline ? 'with-tagline' : ''}`.trim();
  const partnerKey = requirements.partner;
  const isMartinique = partnerKey === 'martinique';
  logoLink = isMartinique ? '/v2' : logoLink;
  logoLink = requirements.host.startsWith('localhost') ? '/v2' : logoLink;

  return (
    <a
      href={logoLink ? logoLink : '/'}
      className={logoClassName}
      target={target_blank ? '_blank' : '_self'}
      rel={target_blank ? 'noreferrer' : undefined}
    >
      <img src={`/logo/${logo}`} alt="Logo" />
      {requirements.config.showTagline && <span>{t('Header.logo.tagline')}</span>}
    </a>
  );
};

export default Logo;
