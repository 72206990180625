import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSearchBox } from '../../../contexts/SearchBoxContext';
import { eventTracking } from '../../../tools/eventTracking';
import useTypingAnimation from '../../../tools/hookTypingAnimation';
import FloatingSearchBarInput from '../../FloatingSearchBar/FloatingSearchBarInput';

const TYPING_SPEED = 150;
const PAUSE_TIME = 1000;

interface SearchBoxIAProps {
  placeholderSentences: string[];
}

const SearchBoxIA: React.FC<SearchBoxIAProps> = ({ placeholderSentences }) => {
  const [query, setQuery] = React.useState('');
  const onChange = (e: string) => setQuery(e);
  const { searchBoxPosition, setSearchBoxPosition } = useSearchBox();
  const searchBoxIARef = useRef(null);

  const updateSearchBoxIAPosition = () => {
    if (searchBoxPosition !== searchBoxIARef.current.getBoundingClientRect().bottom) {
      setSearchBoxPosition({
        bottomPosition: searchBoxIARef.current.getBoundingClientRect().bottom + window.scrollY,
      });
    }
  };

  const currentText = useTypingAnimation({ placeholderSentences, TYPING_SPEED, PAUSE_TIME, query });

  React.useEffect(() => {
    updateSearchBoxIAPosition();
  }, []);
  React.useEffect(() => {
    window.addEventListener('resize', updateSearchBoxIAPosition);
    return () => {
      window.removeEventListener('resize', updateSearchBoxIAPosition);
    };
  }, []);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      submit();
    }
  };
  const submit = () => {
    const chat_id = uuidv4();
    const encodeQuery = encodeURIComponent(query);

    if (typeof window !== 'undefined') {
      eventTracking("interaction", "clic-barre-recherche", "clic-barre-recherche")
      window.location.href = `/engine/charentes-engine/chat?chat_id=${chat_id}&query=${encodeQuery}`;
    }
    setQuery('');
  };

  return (
    <div className="searchBoxIA" ref={searchBoxIARef}>
      <FloatingSearchBarInput
        isFocus={false}
        placeholder={currentText}
        onInputChange={onChange}
        input={query}
        onKeyDown={onKeyDown}
        inputValidation={submit}
        isDefault={true}
      />
    </div>
  );
};

export default SearchBoxIA;
