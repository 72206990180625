import algoliasearch from 'algoliasearch';

const formatFilters = (partnerKey, categ, labels, filters, department, stringFilter) => {
  const filterList = [];
  filterList.push(`${partnerKey}:true`);
  filterList.push(`NOT test_account:true`);
  if (categ && categ.length)
    filterList.push(`(${categ.map((c) => `categslug:${c}`).join(' OR ')})`);
  if (labels && labels.length) filterList.push(`(${labels.map((c) => `label:${c}`).join(' OR ')})`);
  if (department)
    filterList.push(
      `deptname:${department
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace("'", '')
        .replace(/\s/g, '-')
        .toLowerCase()}`
    );

  if (filters) {
    Object.keys(filters).forEach((f) => filterList.push(`${f}:${filters[f]}`));
  }
  if (stringFilter) filterList.push(stringFilter);

  return filterList.join(' AND ');
};

export const getStoresAsync = async (partnerKey, inputs, secondaryInput) => {
  const {
    lat,
    lon,
    categ,
    labels,
    radius,
    query,
    hitsPerPage,
    page,
    department,
    filters,
    stringFilter,
    insideBoundingBox,
    index,
  } = inputs;

  const client = algoliasearch('T1OP86AOVT', '997079138a21c91e288b2eedd315b8c4');
  const Index = index || 'tca_stores';
  const searchIndex = client.initIndex(Index);

  const filterQueries = [
    formatFilters(partnerKey, categ, labels, filters, department, stringFilter),
  ];
  if (secondaryInput && Object.keys(secondaryInput).length) {
    filterQueries.push(
      formatFilters(partnerKey, secondaryInput.categ, secondaryInput.labels, secondaryInput.filters)
    );
  }

  const filterQuery = filterQueries
    ?.filter((f) => f)
    ?.join(' AND ')
    ?.replace(/AND\s+AND/g, 'AND');
  const algoResponse = await algoliaSearchCall(
    query,
    searchIndex,
    filterQuery,
    lat,
    lon,
    radius,
    insideBoundingBox,
    hitsPerPage,
    page
  ); // new code

  return { data: algoResponse, searchIndex };
};

export const algoliaSearchCall = (
  query,
  searchIndex,
  formattedFiltersString,
  lat,
  lon,
  radius,
  insideBoundingBox,
  hitsPerPage,
  page
) => {
  return searchIndex.search(query || '', {
    aroundLatLng: lat && lon ? `${lat},${lon}` : '', // ignored if insideBoundingBox is set
    insideBoundingBox,
    filters: formattedFiltersString,
    aroundRadius: radius || 6000,
    hitsPerPage: hitsPerPage || 50,
    page,
  });
};

export const makeAnAlgoliaSearch = async (data, requirements, categs, stringFilter, lat, lon) => {
  const query = {
    filters: {},
    categ: categs || [],
    labels: [],
    query: data.query,
    stringFilter: data.search ? data.search + (stringFilter ?? '') : (stringFilter ?? ''),
    radius: requirements.config.key === 'martinique' ? 100000 : (data.radius ?? 30000),
    page: data.page || 0,
    hitsPerPage: data.hitsPerPage || 50,
    lat: requirements.lat ?? lat ?? undefined,
    lon: requirements.lon ?? lon ?? undefined,
    insideBoundingBox: data.insideBoundingBox || undefined,
  };

  if (data.sort && data.sort.field) {
    query.index = 'tca_store_replica_reviewscore';
  }

  if (data.filters?.length) {
    data.filters.forEach((l) => {
      switch (l._type) {
        case 'category':
          query.categ.push(l.data);
          break;
        case 'label':
          query.labels.push(l.data);
          break;
        default:
          query.filters[l._type] = l.data;
      }
    });
  }

  const reqQuery = {
    filters: {},
    categ: [],
    labels: [],
  };
  if (requirements.categ) reqQuery.categ.push(...requirements.categ);
  if (requirements.labels) reqQuery.labels.push(...requirements.labels);
  if (requirements.filters) requirements.filters.forEach((k) => (reqQuery.filters[k] = true));

  if (requirements.department) query.filters.deptname = requirements.department;

  const key =
    requirements.config?.key === 'tca_oeno' && query.index === 'tca_stores'
      ? 'tca'
      : requirements.config?.key;
  return await getStoresAsync(key, query, reqQuery);
};
