import React, { useEffect, useState } from 'react';
import uuidv4 from 'uuid/v4';
import { useIsClient } from '../../tools/hooks';
import ButtonV2 from '../ButtonV2';

interface EngineSearchSuggestion {
  title: string;
  query: string;
}

export interface SearchAroundProps {
  title: string;
  engineId: string;
  suggestions: EngineSearchSuggestion[];
}

const SearchAround: React.FC<SearchAroundProps> = ({ title, engineId, suggestions }) => {
  const [searchSuggestions, setSearchSuggestions] = useState<EngineSearchSuggestion[]>();
  const isClient = useIsClient();
  const city =
    isClient && JSON.parse(localStorage.getItem('store-city'))
      ? JSON.parse(localStorage.getItem('store-city'))
      : '';
  const name =
    isClient && JSON.parse(localStorage.getItem('store-name'))
      ? JSON.parse(localStorage.getItem('store-name'))
      : '';

  useEffect(() => {
    setSearchSuggestions(
      suggestions.map((s) => ({
        title: s.title,
        query: s.query?.replace('{{city}}', city)?.replace('{{name}}', name),
      }))
    );
  }, [isClient]);

  const handleCallEngine = (query: string) => {
    const chat_id = uuidv4();
    const encodeQuery = encodeURIComponent(query);
    window.location.href = `/engine/${engineId}/chat?chat_id=${chat_id}&query=${encodeQuery}`;
  };

  if (!searchSuggestions) return null;

  return (
    <div className="SearchAround">
      {title ? <h2 className="SearchAround__title">{title}</h2> : null}
      <div className="SearchAround__suggestions">
        {searchSuggestions.map((s, i) => (
          <ButtonV2 key={i} text={s.title} onClick={() => handleCallEngine(s.query)} />
        ))}
      </div>
    </div>
  );
};

export default SearchAround;
