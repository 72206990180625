import React, { Fragment, useState } from 'react';
import ButtonV2 from '../../../../components/ButtonV2';
import Modal from '../../../../components/Modal';
import Separator from '../../../../components/Separator';
import { useTranslation } from '../../../../tools/i18n';
import { getFavoritesActions, useFavoritesGroups } from '../../store';
import NewFavoritesGroupButton from '../NewGroupButton';
import { Favorite } from '../types';

const { addFavorite } = getFavoritesActions;

interface AddToFavoritesModalProps {
  favorite: Favorite;
  isOpen?: boolean;
  onClose: () => void;
}

const AddToFavoritesModal = ({ favorite, isOpen, onClose }: AddToFavoritesModalProps) => {
  const { t } = useTranslation();
  const favoritesGroups = useFavoritesGroups();
  const [selectedGroupId, setSelectedGroupId] = useState('');

  const handleAddToList = () => {
    const data = {
      ...favorite,
      favorites_group_id: selectedGroupId,
    };

    addFavorite(data);
    onClose();
  };

  return (
    <Fragment>
      <Modal
        title={t('ssrComponents.favorites.addToFavoritesModal.title')}
        subtitle={t('ssrComponents.favorites.addToFavoritesModal.subtitle')}
        isOpen={isOpen}
        onClose={onClose}
        className="AddToFavoritesModal"
      >
        <div className="AddToFavoritesModal__contentContainer">
          {favoritesGroups?.length ? (
            <Fragment>
              <div className="AddToFavoritesModal__favoritesGroups">
                {favoritesGroups.map((g) => (
                  <ButtonV2
                    key={g.id}
                    className={`AddToFavoritesModal__favoritesGroups__groupButton ${selectedGroupId === g.id ? 'AddToFavoritesModal__favoritesGroups__groupButton--selected' : ''}`}
                    variant="outline"
                    onClick={() => setSelectedGroupId(g.id)}
                  >
                    <h5>{g.name}</h5>
                    <p>
                      {t('ssrComponents.favorites.addToFavoritesModal.elementsCount', {
                        count: g.favorites?.length || 0,
                      })}
                    </p>
                  </ButtonV2>
                ))}
              </div>
              {selectedGroupId ? (
                <ButtonV2
                  className="AddToFavoritesModal__button"
                  text={t('ssrComponents.favorites.addToFavoritesModal.add')}
                  onClick={handleAddToList}
                />
              ) : null}
            </Fragment>
          ) : (
            <p
              className="AddToFavoritesModal__noGroup"
              dangerouslySetInnerHTML={{
                __html: t('ssrComponents.favorites.addToFavoritesModal.noGroup'),
              }}
            />
          )}
        </div>
        <Separator />
        <div className="AddToFavoritesModal__contentContainer">
          <NewFavoritesGroupButton />
        </div>
      </Modal>
    </Fragment>
  );
};

export default AddToFavoritesModal;
