import * as React from 'react';
import { Picto, pictos } from '../../../Picto';

export interface ArticlesCarouselLabelProps {
  picto?: keyof typeof pictos;
  text?: string;
}

const ArticlesCarouselLabel: React.FC<ArticlesCarouselLabelProps> = ({ picto, text }) => {
  if (!!!picto && !!!text) return null;

  return (
    <div className="ArticlesCarouselLabel">
      {picto ? <Picto iconKey={picto} /> : null}
      {!!text ? <p className="ArticlesCarouselLabel__text">{text}</p> : null}
    </div>
  );
};

export default ArticlesCarouselLabel;
