import React, { Fragment, useEffect, useRef } from 'react';
import { loadMap } from '../../../tools/apiTools';
import { RequirementsProps } from '../../../tools/context';
import { useIsClient } from '../../../tools/hooks';
import { useTranslation } from '../../../tools/i18n';
import { CustomDataStore, CustomStoreGroup } from '../../../tools/providersAPI';
import ContainerShopV2 from '../Container';
import LabelsShopV2 from '../Labels';
import DetailsStoreItemComp from './Details';
import TableStoreItemComp from './Table';
import TextStoreItemComp from './Text';
import TextListStoreItemComp from './TextList';

export interface GroupsShopV2Props {
  seller: { [key: string]: any };
  data: { [key: string]: any };
  customData: CustomDataStore;
  requirements: RequirementsProps;
}

const GroupsShopV2: React.FC<GroupsShopV2Props> = ({ seller, data, customData, requirements }) => {
  const { t } = useTranslation();
  const lat = seller?.latitude ?? data?.latitude;
  const lng = seller?.longitude ?? data;
  const { frontCateg } = seller;
  const { groups } = customData;

  const addGroupAnchors = (groups: CustomStoreGroup[]) => {
    if (!groups?.length) return [];
    const updatedGroups = [...groups, { anchor: 'map' }];
    if (customData?.store?.labels || customData?.store?.handicaps) {
      updatedGroups.push({ anchor: 'labels' });
    }
    return updatedGroups;
  };

  const getSortedGroups = (groups: CustomStoreGroup[]) => {
    const order = ['schedules', 'prices', 'map', 'labels'];
    return [...groups.filter((group) => group.title !== '{{t:socialNetworks}}')].sort((a, b) => {
      const indexA = order.indexOf(a.anchor);
      const indexB = order.indexOf(b.anchor);
      return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
    });
  };

  const augmentedGroups = getSortedGroups(addGroupAnchors(groups));

  const groupTypes = {
    text: TextStoreItemComp,
    textList: TextListStoreItemComp,
    details: DetailsStoreItemComp,
    table: TableStoreItemComp,
  };

  const MapItem = ({ lat, lng, frontCateg }) => {
    const isClient = useIsClient();
    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (isClient) {
        try {
          loadMap(
            divRef.current,
            { lat, lng },
            [{ geoloc: { lat, lng }, category: frontCateg }],
            15
          );
        } catch (error) {
          console.error('Error loading the map:', error);
        }
      }
    }, [isClient, lat, lng, frontCateg]);

    if (!isClient) {
      return null;
    }

    return (
      <div className="GroupsShopV2__items">
        <div ref={divRef} className="GroupsShopV2__items__map" />
      </div>
    );
  };

  const renderLabelsGroup = (group: any) => (
    <ContainerShopV2
      key={group.anchor}
      id={group.anchor}
      title={t(`shopV2.groupsCustom.titles.${group.anchor}`)}
    >
      <LabelsShopV2
        requirements={requirements}
        labels={customData?.store?.labels}
        handicaps={customData?.store?.handicaps}
      />
    </ContainerShopV2>
  );

  const renderDefaultGroup = (group: any, index: number) => (
    <ContainerShopV2
      key={index}
      id={group.anchor}
      title={group.title?.replace(/{{t:(.*?)}}/g, (match, key) =>
        t(`shopV2.groupsCustom.titles.${key}`)
      )}
    >
      <div className="GroupsShopV2__items">
        {group.items?.map((item: any, i: number) => {
          if (!item?.type) return null;
          const Component = groupTypes[item.type] || null;
          return Component ? <Component key={i} {...item} /> : null;
        })}
      </div>
    </ContainerShopV2>
  );

  const renderGroup = (group: any, index: number) => {
    switch (group.anchor) {
      case 'map':
        return <MapItem lat={lat} lng={lng} frontCateg={frontCateg} key={index} />;
      case 'labels':
        return renderLabelsGroup(group);
      default:
        return group.items?.length ? renderDefaultGroup(group, index) : null;
    }
  };

  return <Fragment>{augmentedGroups.map((group, index) => renderGroup(group, index))}</Fragment>;
};

export default GroupsShopV2;
