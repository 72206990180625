import React from 'react';
import { PictoProps } from '../index';

export const Bell = (props: PictoProps) => (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23 13C18.5817 13 15 16.5817 15 21V26L13 30.2941H33L31 26V21C31 16.5817 27.4183 13 23 13Z"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M23.9999 10C23.9999 9.44772 23.5522 9 22.9999 9C22.4476 9 21.9999 9.44772 21.9999 10L23.9999 10ZM23.9999 14L23.9999 10L21.9999 10L21.9999 14L23.9999 14Z"
      fill="white"
    />
    <path
      d="M19.9999 31C19.9999 32.6569 21.343 34 22.9999 34C24.6567 34 25.9999 32.6569 25.9999 31"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

export const FavCharentes = (props: PictoProps) => (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.0491 13.0144C17.0106 13.0192 16.8736 13.0361 16.7486 13.0505C14.8611 13.2645 13.0819 14.3344 12.06 15.8684C10.8458 17.6861 10.6631 20.2011 11.5719 22.5093C12.1346 23.9327 13.0506 25.2407 14.5125 26.7073C15.5704 27.7652 16.3422 28.4216 18.8163 30.3644C20.5547 31.7301 21.1558 32.2398 21.8939 32.9803C22.5792 33.6656 23.036 34.2474 23.3149 34.7908C23.3726 34.9062 23.4255 35 23.4327 35C23.4399 35 23.4808 34.9303 23.5241 34.8437C23.8847 34.1128 24.6253 33.2424 25.7048 32.2807C26.3131 31.7397 26.7652 31.3718 28.4218 30.071C30.5857 28.3712 31.3239 27.7388 32.365 26.6953C33.4902 25.5677 34.2283 24.6131 34.8246 23.5191C35.82 21.6894 36.1158 19.6192 35.6469 17.7703C35.3776 16.7124 34.8679 15.7939 34.1201 15.0221C32.8627 13.7237 31.1267 13 29.2777 13C27.1162 13 25.26 13.9906 24.1901 15.7169C23.9208 16.1545 23.6275 16.8157 23.4976 17.2822C23.4471 17.4601 23.4087 17.525 23.4087 17.4289C23.4087 17.3207 23.1466 16.6258 22.9663 16.2579C22.0839 14.4571 20.5354 13.3534 18.4557 13.0481C18.2753 13.0216 17.1862 12.9952 17.0491 13.0144Z"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

export const UserCharentes = (props: PictoProps) => (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="22.8077" cy="18" rx="6" ry="6" stroke="white" strokeWidth="2" />
    <path
      d="M32.6154 33.9977C32.6154 28.5811 28.2243 24.19 22.8077 24.19C17.3911 24.19 13 28.5811 13 33.9977"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const InfiniteHeart = (props: PictoProps) => (
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill={props.fill || 'white'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.17461 14.7466C9.72851 14.4215 11.1662 13.4797 12.3096 12.0112C12.3666 11.9424 12.4235 11.8689 12.4923 11.7882C12.6299 11.6174 12.7889 11.4229 12.9715 11.1643C13.6975 10.175 14.2075 9.35654 14.6986 8.5618C14.8647 8.29372 15.0307 8.02327 15.2087 7.75045C15.4886 7.30445 15.8136 6.79439 15.9631 6.59985C16.6653 5.70547 17.8871 4.60944 19.5976 4.89649C21.1799 5.1622 22.4349 6.45514 22.5939 8.05174C22.8074 10.1821 21.1325 11.9851 19.0448 11.9851C17.8894 11.9851 16.7982 11.4252 16.122 10.4858C16.0082 10.3316 15.7448 9.9781 15.5503 9.73612L15.4862 9.66258C14.9216 10.5261 14.2692 11.4869 13.7995 12.1797C14.8006 13.5034 16.262 14.4215 17.823 14.7442L13.9323 18.6017C13.4128 19.1165 12.5753 19.1165 12.0582 18.6017L8.17223 14.7442L8.17461 14.7466ZM25.4953 8.41946C25.4953 4.86328 22.601 1.96899 19.0448 1.96899C17.0259 1.96899 15.1209 2.98199 13.6856 4.8277C13.5077 5.0507 13.2965 5.37571 13.0973 5.69124C12.6441 6.3555 12.3262 6.85844 11.9941 7.38985C11.7189 7.81925 11.439 8.26525 11.0689 8.83225C10.7486 9.31147 10.1935 10.0327 10.0345 10.2391C9.32995 11.1453 8.09157 12.2413 6.37635 11.9377C4.79873 11.6601 3.55324 10.3648 3.4014 8.77057C3.19738 6.6473 4.8699 4.85142 6.95284 4.85142C8.05124 4.85142 9.17812 5.42316 9.90406 6.35075C9.90406 6.35075 10.305 6.7849 10.5493 7.08144L10.6585 7.21904C11.1377 6.5097 11.7616 5.58923 12.293 4.77076C11.0452 3.01521 9.05475 1.96899 6.95047 1.96899C3.39429 1.96899 0.5 4.86328 0.5 8.41946C0.5 10.3767 1.37778 12.1346 2.75849 13.3184L10.5019 21.0025C11.885 22.3737 14.115 22.3737 15.4981 21.0025L23.3151 13.2543C24.6531 12.0682 25.5 10.3411 25.5 8.41709" />
  </svg>
);

export const ArrowCharentes = (props: PictoProps) => (
  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 1L1 6.5L7 12"
      stroke="#21212B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 6.5H15"
      stroke="#21212B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const StarCharentes = (props: PictoProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={props.fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7043 23L11.9614 13.4065M7.51823 22.0512L11.4266 13.2856M4.01356 19.5699L10.9792 12.9668M1.72511 15.9367L10.6861 12.5014M1 11.7043L10.5935 11.9614M1.95138 7.51823L10.7169 11.4266M4.43011 4.01356L11.0332 10.9792M8.06335 1.72511L11.4986 10.6861M12.2957 1L12.0386 10.5935M16.4818 1.95138L12.5734 10.7169M19.9864 4.43268L13.0208 11.0358M22.2749 8.06592L13.3139 11.5012M23 12.2983L13.4065 12.0411M22.0512 16.4843L13.2856 12.576M19.5699 19.989L12.9668 13.0234M15.9367 22.2775L12.5014 13.3165"
      stroke="#F89443"
      strokeWidth="1.73562"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Infinite = (props: PictoProps) => (
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill={props.fill || 'D0DB5F'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M25.5 11.9968C25.5 15.5553 22.6068 18.4549 19.0483 18.4549C17.0526 18.4549 15.0612 17.4243 13.8046 15.7602C14.2734 15.0675 14.926 14.1066 15.492 13.2428L15.5553 13.3167C15.7496 13.5575 16.0136 13.9123 16.1276 14.0665C16.8034 15.0041 17.8953 15.5659 19.0504 15.5659C21.0187 15.5659 22.6194 13.9651 22.6194 11.9968C22.6194 10.0286 21.0187 8.42783 19.0504 8.42783C17.627 8.42783 16.5922 9.38239 15.9671 10.1786C15.8193 10.3728 15.492 10.8818 15.2111 11.3274C15.0337 11.6019 14.869 11.8701 14.7021 12.1405C14.2101 12.9345 13.7011 13.7539 12.9747 14.7422C12.7909 14.9999 12.6325 15.1942 12.4953 15.3652C12.4256 15.4455 12.3686 15.5194 12.3115 15.5891C10.8755 17.437 8.9706 18.4549 6.95168 18.4549C3.39534 18.4549 0.5 15.5553 0.5 11.9968C0.5 8.43839 3.39534 5.54517 6.95168 5.54517C9.05719 5.54517 11.0465 6.59264 12.2946 8.34758C11.7625 9.16487 11.1395 10.0856 10.658 10.7952L10.5482 10.6579C10.3032 10.3602 9.90193 9.92513 9.90193 9.92513C9.17545 8.99803 8.04773 8.42572 6.94957 8.42572C4.98133 8.42572 3.38055 10.0265 3.38055 11.9947C3.38055 13.963 4.98133 15.5638 6.94957 15.5638C8.36873 15.5638 9.40987 14.6134 10.0329 13.813C10.1934 13.6061 10.7488 12.8859 11.0677 12.4065C11.4393 11.8406 11.7202 11.395 11.9948 10.9663C12.3263 10.4341 12.6473 9.93146 13.0993 9.26835C13.2999 8.95368 13.5111 8.62846 13.6885 8.4046C15.1245 6.55674 17.0294 5.54517 19.0483 5.54517C22.6068 5.54517 25.5 8.4405 25.5 11.9968Z" />
  </svg>
);

export const FacebookLogo = (props: PictoProps) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7572 10.3488L13.6248 11.4714C13.6026 11.6591 13.4519 11.7999 13.2737 11.7999H11.5518V16.4937C11.37 16.5111 11.186 16.52 11.0002 16.52C10.5841 16.52 10.1779 16.4759 9.78542 16.3923V11.8004H8.46089C8.33955 11.8004 8.23999 11.6952 8.23999 11.5662V10.1616C8.23999 10.033 8.33911 9.92742 8.46089 9.92742H9.78542V7.82079C9.78542 6.5279 10.7739 5.47998 11.9931 5.47998H13.5386C13.6599 5.47998 13.7595 5.5851 13.7595 5.71416V7.11873C13.7595 7.24732 13.6603 7.35291 13.5386 7.35291H12.4345C11.9469 7.35291 11.5518 7.77198 11.5518 8.28914V9.92789H13.4061C13.619 9.92789 13.7835 10.1259 13.7572 10.3493V10.3488Z"
      fill="#21212B"
    />
    <path
      d="M16.2346 0.879883H5.76545C3.06782 0.879883 0.879997 3.0677 0.879997 5.76533V16.2344C0.879997 18.9321 3.06782 21.1199 5.76545 21.1199H16.2346C18.9322 21.1199 21.12 18.9321 21.12 16.2344V5.76533C21.12 3.0677 18.9322 0.879883 16.2346 0.879883Z"
      stroke="#21212B"
      strokeWidth="1.6928"
    />
  </svg>
);

export const InstagramLogo = (props: PictoProps) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2346 0.879883H5.76545C3.06782 0.879883 0.880001 3.0677 0.880001 5.76533V16.2344C0.880001 18.9321 3.06782 21.1199 5.76545 21.1199H16.2346C18.9322 21.1199 21.12 18.9321 21.12 16.2344V5.76533C21.12 3.0677 18.9322 0.879883 16.2346 0.879883Z"
      stroke="#21212B"
      strokeWidth="1.6928"
    />
    <path
      d="M6.62243 10.9992C6.62243 8.57772 8.58862 6.61154 11.0101 6.61154C13.4316 6.61154 15.3978 8.57772 15.3978 10.9992C15.3978 13.4207 13.4316 15.3869 11.0101 15.3869C8.58862 15.3869 6.62243 13.4207 6.62243 10.9992Z"
      stroke="#21212B"
      strokeWidth="1.6928"
    />
    <path
      d="M16.5929 6.46374C17.1711 6.46374 17.6397 5.99507 17.6397 5.41693C17.6397 4.83879 17.1711 4.37012 16.5929 4.37012C16.0148 4.37012 15.5461 4.83879 15.5461 5.41693C15.5461 5.99507 16.0148 6.46374 16.5929 6.46374Z"
      fill="#21212B"
    />
  </svg>
);

export const YoutubeLogo = (props: PictoProps) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2346 0.879883H5.76545C3.06782 0.879883 0.880005 3.0677 0.880005 5.76533V16.2344C0.880005 18.9321 3.06782 21.1199 5.76545 21.1199H16.2346C18.9322 21.1199 21.12 18.9321 21.12 16.2344V5.76533C21.12 3.0677 18.9322 0.879883 16.2346 0.879883Z"
      stroke="#21212B"
      strokeWidth="1.6928"
    />
    <path
      d="M9.5712 14.9406C9.00693 15.2664 8.3016 14.8592 8.3016 14.2076L8.3016 8.37544C8.3016 7.72388 9.00693 7.31666 9.5712 7.64244L14.622 10.5585C15.1863 10.8843 15.1863 11.6987 14.622 12.0245L9.5712 14.9406Z"
      fill="#21212B"
    />
  </svg>
);

export const Boussole = (props: PictoProps) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 18.26H18V0.26H0V18.26Z" fill="#21212B" />
  </svg>
);
