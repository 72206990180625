export const userProfiles = ['habitant_local', 'habitant_regional', 'passager'];

export type UserProfile = (typeof userProfiles)[number];

export interface User {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  profile: UserProfile;
  city: string;

  disabled: boolean;
  verified: boolean;
  created_at: string;
  updated_at: string;
  last_login: string;
  id: string;
}

export interface UserRegister
  extends Pick<User, 'first_name' | 'last_name' | 'email' | 'password' | 'profile' | 'city'> {}

export interface UserLogin {
  username: User['email'];
  password: User['password'];
}

export interface UserForgotPassword {
  email: User['email'];
}

export interface UserSubmitCode extends UserForgotPassword {
  reset_code: number;
}

export interface UserSubmitNewPassword {
  reset_password_token: string;
  new_password: User['password'];
}

export interface UserUpdate extends Omit<UserRegister, 'password'> {}
