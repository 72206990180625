import { UserLogin, UserRegister, UserUpdate } from '../features/auth/types';
import {
  Favorite,
  FavoriteDelete,
  FavoritesGroupUpdate,
} from '../features/favorites/components/types';
import { createAxiosInstance, formatAxiosError, formatAxiosResponse } from './axiosInstance';

export const USERS_API_BASE_URL = `https://charentes-qa.wearegenial.com/api/v1/`;
//export const USERS_API_BASE_URL = `http://localhost:8000/api/v1/`;

const usersApi = createAxiosInstance({
  baseURL: USERS_API_BASE_URL,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const usersApiRegister = async (data: UserRegister) => {
  try {
    await usersApi.post('users/register', data);

    return await usersApiLogin({ username: data.email, password: data.password });
  } catch (error) {
    return Promise.reject(formatAxiosError(error));
  }
};

export const usersApiLogin = async (data: UserLogin) => {
  try {
    const response = await usersApi.post(
      'users/login',
      { username: data.username, password: data.password },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    return formatAxiosResponse(response);
  } catch (error) {
    return Promise.reject(formatAxiosError(error));
  }
};

export const usersApiRequestPasswordReset = async (email: string) => {
  try {
    const response = await usersApi.post('/request-password-reset', { email });

    return formatAxiosResponse(response);
  } catch (error) {
    return Promise.reject(formatAxiosError(error));
  }
};

export const usersApiVerifySubmitCode = async (email: string, reset_code: number) => {
  try {
    const response = await usersApi.post('/verify-reset-code', { email, reset_code });

    return formatAxiosResponse(response);
  } catch (error) {
    return Promise.reject(formatAxiosError(error));
  }
};

export const usersApiSubmitNewPassword = async (
  reset_password_token: string,
  new_password: string
) => {
  try {
    const response = await usersApi.post('/reset-password', { reset_password_token, new_password });

    return formatAxiosResponse(response);
  } catch (error) {
    return Promise.reject(formatAxiosError(error));
  }
};

export const userApiMe = async (accessToken: string | null) => {
  if (!accessToken) return Promise.reject('No Access Token provided');

  try {
    const response = await usersApi.get('users/me', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return formatAxiosResponse(response);
  } catch (error) {
    return Promise.reject(formatAxiosError(error));
  }
};

export const usersApiUpdateUser = async (data: UserUpdate, accessToken: string | null) => {
  if (!accessToken) return Promise.reject('No Access Token provided');

  try {
    const response = await usersApi.put('users/me', data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return formatAxiosResponse(response);
  } catch (error) {
    return Promise.reject(formatAxiosError(error));
  }
};

export const usersApiAddFavorite = async (favorite: Favorite, accessToken: string | null) => {
  if (!accessToken) return Promise.reject('No Access Token provided');

  try {
    const response = await usersApi.post('favorites', favorite, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return formatAxiosResponse(response);
  } catch (error) {
    return Promise.reject(formatAxiosError(error));
  }
};

export const usersApiDeleteFavorite = async (
  favoriteId: FavoriteDelete['id'],
  accessToken: string | null
) => {
  if (!accessToken) return Promise.reject('No Access Token provided');

  try {
    const response = await usersApi.delete(`favorites/${favoriteId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return formatAxiosResponse(response);
  } catch (error) {
    return Promise.reject(formatAxiosError(error));
  }
};

export const usersApiGetFavoritesGroups = async (accessToken: string | null) => {
  if (!accessToken) return Promise.reject('No Access Token provided');

  try {
    const response = await usersApi.get('favorites-groups', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return formatAxiosResponse(response);
  } catch (error) {
    return Promise.reject(formatAxiosError(error));
  }
};

export const usersApiAddFavoritesGroup = async (name: string, accessToken: string | null) => {
  if (!accessToken) return Promise.reject('No Access Token provided');

  try {
    const response = await usersApi.post(
      'favorites-groups',
      { name },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return formatAxiosResponse(response);
  } catch (error) {
    return Promise.reject(formatAxiosError(error));
  }
};

export const usersApiUpdateFavoritesGroup = async (
  data: FavoritesGroupUpdate,
  accessToken: string | null
) => {
  if (!accessToken) return Promise.reject('No Access Token provided');

  try {
    const { id, ...rest } = data;
    const response = await usersApi.patch(`favorites-groups/${data.id}`, rest, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return formatAxiosResponse(response);
  } catch (error) {
    return Promise.reject(formatAxiosError(error));
  }
};

export const usersApiDeleteFavoritesGroup = async (id: string, accessToken: string | null) => {
  if (!accessToken) return Promise.reject('No Access Token provided');

  try {
    const response = await usersApi.delete(`favorites-groups/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return formatAxiosResponse(response);
  } catch (error) {
    return Promise.reject(formatAxiosError(error));
  }
};

export default usersApi;
