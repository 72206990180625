import React, { useEffect, useRef, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { Button } from "../Button";
import { Picto } from "../Picto";
import { getQueryString } from "../../tools/tools";
import { RequirementsProps } from "../../tools/context";
import CategoryPopup from "./categoryPopup";
import {
  Configuration,
  DROM,
  predictionExceptions,
} from "../../tools/Constants";
import fuzzysort from "fuzzysort";

type AutocompleteService = google.maps.places.AutocompleteService;

export interface SearchBarProps {
  requirements?: RequirementsProps;
  heroSearch?: boolean;
  mapOpened?: boolean;
  mapGridCallback?: (p: boolean) => undefined | void;
}

/*
Regles de gestion:
- adresse/ville/departement: on vérifie si le départmement appartien à une CR,
  si oui => page CR
  si non => page search avec une recherche par géoloc
- region: à empecher
- france/rien: no filter
*/

export const SearchBar = (props: SearchBarProps) => {
  const { requirements, heroSearch, mapGridCallback } = props;
  const inputRef = useRef<HTMLInputElement>();
  const voidRef = useRef<HTMLDivElement>();
  const autocomplete = useRef<AutocompleteService>();
  const predictionsRef = useRef<HTMLButtonElement[]>([]);
  const sessionToken = useRef(null);
  const tempSave = useRef<{ input: string; p: any }>(null);
  const lastMatch = useRef<{ input: string; p: any }>(null);
  const currentMatch = useRef<{ input: string; p: any }>(null);
  const [showCategory, setShowCategory] = useState<boolean>(false);
  const [currentPlace, setCurrentPLace] = useState<any>(null);
  const [showMap, setShowMap] = useState<boolean>(props.mapOpened || false);
  const [predictions, setPredictions] = useState<any[]>([]);
  const [showPredictions, setShowPredictions] = useState<boolean>(false);

  const autoCompleteOption = {
    componentRestrictions: { country: ["fr", ...DROM] },
    fields: ["address_components", "geometry", "name"],
    types: ["route", "locality", "postal_code", "country"],
    sessionToken: sessionToken.current,
  };

  useEffect(() => {
    if (requirements?.location) {
      inputRef.current.value = requirements.location;
    }
    if (requirements?.query) {
      inputRef.current.value = requirements.query;
    }

    if (typeof window !== "undefined" && window.google) {
      return;
    }

    const loader = new Loader({
      apiKey: "AIzaSyCYp4mEcSL6CN2IjfVnVjnJDdWCWeGhUz8",
      version: "weekly",
      libraries: ["places"],
    });

    loader.load();
  }, []);

  useEffect(() => {
    if (!currentPlace) return;

    handleValidate({});
  }, [currentPlace]);

  const redirect = (queries: any, url?: string) => {
    const newUrl = url || window.location.pathname;
    if (!queries) window.location.href = newUrl;
    ["categ", "labels", "filters"].forEach((k) => {
      if (!queries[k] && requirements[k]) {
        queries[k] = requirements[k];
      }

      if (queries[k]) queries[k] = queries[k].join("|");
    });

    if (requirements.coupon) {
      queries.coupon = requirements.coupon;
    }
    if (getQueryString("mapOpened")) {
      queries.mapOpened = getQueryString("mapOpened");
    }

    const queryStrings = Object.keys(queries)
      .filter((q) => queries[q])
      .map((k) => `${k}=${encodeURIComponent(queries[k])}`)
      .join("&");

    window.location.href = `${newUrl}?${queryStrings}${window.location.hash}`;
  };

  const getPlaceAndRedirect = (placeId, location, filterPopup) => {
    new google.maps.places.PlacesService(voidRef.current).getDetails(
      {
        placeId,
        fields: autoCompleteOption.fields,
        sessionToken: autoCompleteOption.sessionToken,
      },
      (a) => {
        const filters = {
          ...filterPopup,
          location,
          department: null,
          lon: null,
          lat: null,
        };
        const country = a.address_components.find((c) =>
          c.types.includes("country")
        );
        if (country && DROM.includes(country.short_name.toLowerCase())) {
          filters.department = country.long_name;
        } else if (
          a.address_components[0].types.includes("administrative_area_level_2")
        ) {
          filters.department = a.address_components[0].short_name;
        } else {
          filters.lon = a.geometry.location.lng();
          filters.lat = a.geometry.location.lat();
        }

        if (handleCR(a.address_components, filters)) return;

        redirect(filters, "/results");
      }
    );
  };

  const getPredictionsExceptions = (input) => {
    const norm = (it) => it.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const targets = predictionExceptions.map((p) => ({
      ...p,
      norm: norm(p.label),
    }));
    const fuz = fuzzysort.go(norm(input), targets, {
      key: ["norm"],
      limit: 3,
    });

    return fuz.map((f) => f.obj);
  };

  const getPredictions = async (input) => {
    const exceptions = getPredictionsExceptions(input);
    let r = await autocomplete.current.getPlacePredictions({
      input,
      ...autoCompleteOption,
    });

    const res = [
      {
        label: `Rechercher "${input}" autour de moi`,
        description: input,
        geoloc: true,
      },
      {
        label: `Rechercher "${input}" partout`,
        description: input,
      },
    ];

    let results: any[] = r.predictions || [];
    if (results.length) {
      const p = results[0];
      const main = p.description.replace(/-/g, " ");
      if (
        main
          .toLowerCase()
          .includes(input.trim().toLowerCase().replace(/,/g, ""))
      ) {
        // match
        tempSave.current = { input, p };
        lastMatch.current = null;
        return { results: [...exceptions, ...results, ...res], input };
      }
    }

    //no match
    if (tempSave.current) {
      // save last match in formated form
      //todo maybe ? check that the city name is complete
      const splited = tempSave.current.input.split(" ");
      splited.splice(splited.length - 1, 1);
      if (splited.length && !tempSave.current.p.types.includes("route")) {
        lastMatch.current = { input: splited.join(" "), p: tempSave.current.p };
      }
      tempSave.current = null;
    }

    if (lastMatch.current) {
      // there is a match in the precedents words
      const partialInput = input.replace(lastMatch.current.input, "");

      const partialResult = {
        formatedDescription: `${partialInput} à ${lastMatch.current.p.description}`,
        query: partialInput,
        ...lastMatch.current.p,
      };

      results = [partialResult, ...res, ...results];
    } else {
      // the location is not yet in the input, try with the last word only
      const severalWord = input.trim().includes(" ");
      //is there several word in the input
      if (severalWord) {
        let partialInput;
        if (currentMatch.current) {
          //there is already a match in the latter part of the input, so we roll woth it
          const index = input.indexOf(currentMatch.current.input);
          partialInput = input.substring(index);
        } else {
          //no match yet, so with use the last word
          const isplit = input.trim().split(" ");
          partialInput = isplit[isplit.length - 1];
        }

        r = await autocomplete.current.getPlacePredictions({
          input: partialInput,
          ...autoCompleteOption,
        });
        if (r.predictions.length) {
          const p = r.predictions[0];
          const main = p.description.replace(/-/g, " ");
          if (
            main
              .toLowerCase()
              .includes(partialInput.toLowerCase().replace(/,/g, ""))
          ) {
            const partialResult = {
              formatedDescription: `${input
                .replace(partialInput, "")
                .trim()} à ${p.description}`,
              query: input.replace(partialInput, "").trim(),
              ...p,
            };

            results = [partialResult, ...res, ...results];
            currentMatch.current = { input: partialInput, p };
          } else {
            results = [...res, ...results];
          }
        } else {
          currentMatch.current = null;
          results = [...results, ...res];
        }
      } else {
        //1-word input, so query search
        results = [...res, ...results];
      }
    }

    if (!results.length) results = res;

    return { results: [...exceptions, ...results], input };
  };

  const handleValidate = (filterPopup: any = {}, inputPlace: any = null) => {
    const place = inputPlace || currentPlace;

    if (place && place.exception) {
      handleExceptionValidation(place, filterPopup);
      return;
    }

    if (place && place.place_id) {
      // autocomplete fait
      getPlaceAndRedirect(place.place_id, place.description, filterPopup);
      return;
    }

    if (filterPopup.query) {
      redirect(filterPopup, "/results");
      return;
    }

    // search vide
    if (!inputRef.current.value) {
      redirect(filterPopup, "/results");
      return;
    }

    // pas de nouvelle location, input préremplis avec l'ancienne location
    if (
      requirements.location === inputRef.current.value &&
      ((requirements.lon && requirements.lat) || requirements.department)
    ) {
      redirect({
        location: inputRef.current.value,
        lon: requirements.lon,
        lat: requirements.lat,
        department: requirements.department,
        departement: requirements.departement,
        ...filterPopup,
      });
      return;
    }

    // pas de nouvelle query, input préremplis avec l'ancienne query
    if (requirements.query === inputRef.current.value) {
      redirect({
        query: requirements.query,
        aroundme: requirements.aroundme,
        ...filterPopup,
      });
      return;
    }

    redirect(filterPopup, "/results");
  };

  const handleExceptionValidation = (place, filterPopup) => {
    const filters = {
      ...filterPopup,
      location: place.label,
      department: null,
      lon: null,
      lat: null,
    };

    if (place.types.includes("department")) {
      filters.department = place.label;
    } else {
      filters.lon = place.lon;
      filters.lat = place.lat;
    }

    if (handleCR([place], filters)) return;

    redirect(filters, "/results");
  };

  const handleCR = (place, filters) => {
    const department = place.find(
      (c) =>
        c.types &&
        (c.types.includes("department") ||
          c.types.includes("administrative_area_level_2"))
    );
    const country = place.find((c) => c.types && c.types.includes("country"));

    const cr = Object.keys(Configuration.CRList).find(
      (l) =>
        (department &&
          (Configuration.CRList[l].includes(department.label) ||
            Configuration.CRList[l].includes(department.long_name))) ||
        (country && Configuration.CRList[l].includes(country.long_name))
    );

    if (cr) {
      filters.departement = department
        ? department.label || department.long_name
        : country.long_name;
      redirect(filters, `/ca-${cr}`);
      return true;
    }

    return false;
  };

  const handleMapGridCallback = () => {
    mapGridCallback(!showMap);

    setShowMap((prev) => !prev);
  };

  const handleKeyDownInput = (e) => {
    if (!e || !e.code) return;

    if (e.code === "Enter") handleGO();
    if (e.code === "ArrowDown" && predictionsRef.current[0]) {
      e.preventDefault();
      predictionsRef.current[0].focus();
    }
  };

  const handleGO = () => {
    if (predictions.length) {
      handleSelectPrediction(predictions[0]);
    } else {
      handleValidate({});
    }
  };

  const handleKeyDown = (e, p, i) => {
    if (!e) return false;

    e.preventDefault();
    if (e.code === "ArrowDown" && predictionsRef.current[i + 1]) {
      predictionsRef.current[i + 1].focus();
      return false;
    }
    if (e.code === "ArrowUp" && predictionsRef.current[i - 1]) {
      predictionsRef.current[i - 1].focus();
      return false;
    }
    if (e.code === "Enter") {
      handleSelectPrediction(p);
      return false;
    }

    inputRef.current.focus();
    if (/^.{1}$/.test(e.key)) {
      inputRef.current.value += e.key;
      handleInput({ target: { value: inputRef.current.value } });
    }

    return false;
  };

  const handleInput = (e) => {
    if (!e.target.value) {
      setPredictions([]);
      setShowPredictions(false);
      tempSave.current = null;
      lastMatch.current = null;
      currentMatch.current = null;
      return;
    }

    if (!autocomplete.current) {
      if (typeof window !== "undefined" && window.google) {
        autocomplete.current = new google.maps.places.AutocompleteService();
        sessionToken.current =
          new google.maps.places.AutocompleteSessionToken();
      } else {
        return;
      }
    }

    const value = e.target.value;
    getPredictions(value).then((r) => {
      if (r.input !== inputRef.current.value) return;

      setPredictions(r.results);
      setShowPredictions(true);
    });
  };

  const handleSelectPrediction = (p) => {
    inputRef.current.value = p.formatedDescription || p.label || p.description;
    setShowPredictions(false);

    if (p.formatedDescription || p.exception) {
      handleValidate({ query: p.query }, p);
    } else if (p.label) {
      if (p.geoloc) {
        handleValidate({ query: p.description, aroundme: true });
      } else {
        handleValidate({ query: p.description });
      }
    } else {
      setCurrentPLace(p);
    }
  };

  const renderTag = (p) => {
    const exceptions = [
      "La Réunion",
      "Martinique",
      "Guyane française",
      "Guadeloupe",
    ];
    if (exceptions.includes(p.description)) {
      p.types[0] = "administrative_area_level_2";
    }

    let type;
    switch (p.types ? p.types[0] : "other") {
      case "department":
      case "administrative_area_level_2":
        type = { key: "region", label: "Département", icon: "region" };
        break;
      case "route":
      case "street_address":
        type = { key: "address", label: "Adresse", icon: "address" };
        break;
      case "locality":
      case "postal_code":
        type = { key: "city", label: "Ville", icon: "city" };
        break;
      case "country":
        type = { key: "country", label: "Pays", icon: "country" };
        break;
      default:
        type = { key: "other", label: "Autre", icon: "place" };
    }

    return (
      <div className={`tag ${type.key}`}>
        <Picto iconKey={type.icon} />
        {type.label}
      </div>
    );
  };

  return (
    <>
      <div className={`searchBar ${heroSearch ? "hero" : ""}`}>
        <div className="inputBlock">
          <input
            ref={ref => { inputRef.current = ref }}
            onKeyDown={handleKeyDownInput}
            onChange={handleInput}
            onFocus={() => setShowPredictions(true)}
            onBlur={() => setShowPredictions(false)}
            placeholder={"Indiquez un lieu"}
          />
          <Picto iconKey={"target"} />
          {showPredictions && !!predictions.length && (
            <div className="predictions">
              {predictions.map((p, i) => (
                <button
                  key={`prediction_${i}`}
                  onMouseDownCapture={() => handleSelectPrediction(p)}
                  onFocus={() => setShowPredictions(true)}
                  ref={ref => { predictionsRef.current[i] = ref }}
                  onKeyDown={(e) => handleKeyDown(e, p, i)}
                >
                  <div className="prediction">
                    <Picto iconKey={"place"} />
                    {p.formatedDescription || p.label || p.description}
                  </div>
                  {renderTag(p)}
                </button>
              ))}
            </div>
          )}
          <div
            nonce="__nonce__"
            style={{ display: "none" }}
            ref={ref => { voidRef.current = ref }}
          />
        </div>
        {!heroSearch && (
          <Button className="validate" theme={"yellow"} onClick={handleGO}>
            Go
          </Button>
        )}
        <div className="filters">
          {!heroSearch && (
            <Button
              className="categoryButton"
              theme={"transparent"}
              onClick={() => setShowCategory(true)}
            >
              Filtrer les catégories <Picto iconKey={"cursors"} />
            </Button>
          )}
          {mapGridCallback && (
            <Button
              className="mapButton"
              theme={"transparent"}
              onClick={handleMapGridCallback}
            >
              {showMap ? (
                <>
                  Liste
                  <Picto iconKey={"list"} />
                </>
              ) : (
                <>
                  Carte
                  <Picto iconKey={"place"} />
                </>
              )}
            </Button>
          )}
        </div>
        {showCategory && (
          <CategoryPopup
            requirements={requirements}
            closeCallback={() => setShowCategory(false)}
          />
        )}
      </div>
    </>
  );
};
