import { PortableText } from '@portabletext/react';
import React from 'react';
import { RequirementsProps } from '../../tools/context';

interface BlockProps {
  requirements: RequirementsProps;
}

export default function Block({ requirements, ...rest }: BlockProps) {
  return (
    <div className="Block">
      <PortableText value={rest as any} />
    </div>
  );
}
