import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { RequirementsProps } from '../../tools/context';

interface VideoConfig {
  autoplay?: boolean;
  loop?: boolean;
  controls?: boolean;
  muted?: boolean;
  aspect_ratio?: string;
}

export interface VideoProps {
  requirements: RequirementsProps;
  source: string;
  config?: VideoConfig;
}

const Renderer = ({ requirements, source, config }: VideoProps) => {
  const [isPlaying, setIsPlaying] = useState(config?.autoplay || false);
  if (!source) return null;

  return (
    <div
      className="Video"
      style={{
        aspectRatio: config.aspect_ratio || '16/9',
      }}
    >
      <ReactPlayer
        url={source}
        playing={isPlaying}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        loop={config?.loop || false}
        controls={config?.controls || false}
        muted={config?.muted || false}
        {...config}
      />
    </div>
  );
};

const preloader = async (data: any, requirements: RequirementsProps) => {
  return data;
};

const Video = { Renderer, preloader };

export default Video;
export { Renderer, preloader };
