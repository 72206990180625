import ArticlesCarousel from './ArticlesCarousel';
import EditoCarousel from "./EditoCarousel";
import FullScreenCarousel from "./FullScreenCarousel";
import MediaCarousel from './MediaCarousel';
import PortraitCarousel from './PortraitCarousel';
import PortraitCarouselRef from './PortraitCarouselRef';
import SearchCarousel from './SearchCarousel';
import StaticCarousel from './StaticCarousel';

const exp = {
  Articles: ArticlesCarousel,
  Static: StaticCarousel,
  Portrait: PortraitCarousel,
  Search: SearchCarousel,
  PortraitRef: PortraitCarouselRef,
  Edito: EditoCarousel,
  FullScreen: FullScreenCarousel,
  Media: MediaCarousel,
};

export default exp;
