import React from 'react';
import { useTranslation } from '../../../../tools/i18n';
import { TableStoreItem } from '../../../../tools/providersAPI';

const TableStoreItemComp: React.FC<TableStoreItem> = ({ title, rows }) => {
  const { t } = useTranslation();

  if (rows?.filter((r) => !!r)?.length === 0) return null;

  const translatedRows = rows.map((texts) =>
    texts?.map((text) =>
      text?.replace(/{{t:(.*?)}}/g, (match, key) => t(`shopV2.groupsCustom.texts.${key}`))
    )
  );

  return (
    <div className="TableStoreItemComp">
      {title ? <h5 className="TableStoreItemComp__title">{title}</h5> : null}
      {rows?.length ? (
        <table>
          <tbody>
            {translatedRows?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row?.map((cell, cellIndex) => (
                  <td key={cellIndex} dangerouslySetInnerHTML={{ __html: cell ?? '' }} />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}
    </div>
  );
};

export default TableStoreItemComp;
