import React from 'react';
import { RequirementsProps } from '../../tools/context';
import { SanityColor } from '../../tools/sanity';
import ButtonV2, { ButtonV2Props } from '../ButtonV2';

export interface ArticleLinkButtonProps {
  requirements: RequirementsProps;
  text: string;
  link: string;
  leftIcon?: ButtonV2Props['leftIcon'];
  rightIcon?: ButtonV2Props['rightIcon'];
  align?: ButtonV2Props['align'];
  linkTarget?: ButtonV2Props['linkTarget'];
  variant?: ButtonV2Props['variant'];
  color?: SanityColor;
}

const Renderer = ({ color, ...rest }: ArticleLinkButtonProps) => {
  const hexColor = color?.hex || '#000000';

  if (!rest?.text || !rest?.link) return null;

  return <ButtonV2 color={hexColor} {...rest} />;
};

const preloader = async (data: any, requirements: RequirementsProps) => {
  return data;
};

const ArticleLinkButton = { Renderer, preloader };

export default ArticleLinkButton;
export { Renderer, preloader };
