import { Loader } from '@googlemaps/js-api-loader';
import { defaultOnClusterClickHandler, MarkerClusterer } from '@googlemaps/markerclusterer';
import { OverlappingMarkerSpiderfier } from 'ts-overlapping-marker-spiderfier';
import { getPreloadedState, loadCMS } from '../App';
import charentes from '../configurations/charentes';
import { getAllVendorsNoFilter } from './ciblerAPI';
import { Endpoints } from './Constants';
import { defReq, RequirementsProps } from './context';
import { getSanityClient } from './sanity';
import { mergeFooterConfig } from './utils';

type LatLngLiteral = google.maps.LatLngLiteral;
declare const window: any;

const fetchArticlesData = async (cityKey: string, lang: string, postalCode?: string) => {
  let url = `https://clients.wearegenial.com/api/v1/france/articles?city_key=${encodeURIComponent(cityKey)}&lang=${encodeURIComponent(lang)}`;
  if (postalCode) {
    url += `&postal_code=${encodeURIComponent(postalCode)}`;
  }
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_FRANCE_SECRET_KEY}`,
        'api-key': process.env.REACT_APP_FRANCE_API_KEY,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch articles:', error);
    return [];
  }
};

const preloadState = async (
  url: string,
  query: any,
  host: string,
  cookies: { [key: string]: any },
  useragent: string
) => {
  const parsed = new URL(url, 'https://test.com');

  let requirements: RequirementsProps = getUrlInfoRequirements(
    url,
    parsed.pathname,
    query,
    host,
    cookies,
    useragent
  );
  let cmsState = await getCMSData(requirements);
  cmsState = await loadCMS(cmsState, requirements);
  let componentStates = await getPreloadedState(cmsState, requirements);

  return { ...cmsState, componentStates: componentStates, requirements };
};

export const getPartner = (host: string, cookies: { [key: string]: any }, queries: any) => {
  let partner = cookies?.partner;

  const partnerFromUrl = [
    '.ticket-pei.fr',
    '.ticket-commercant.fr',
    '.reducpeyi.fr',
    '.bordeaux-mon-commerce.fr',
  ];
  let bvnDomain = partnerFromUrl.some((url) => host.endsWith(url)) ? host.split('.')[0] : null;
  bvnDomain = bvnDomain !== 'dev' && bvnDomain !== 'fork' ? bvnDomain : null;

  if (queries) {
    partner =
      queries.part || queries.partner || queries.sponsor || bvnDomain || partner || 'charentes';
  } else {
    partner = bvnDomain || partner || 'charentes';
  }

  if (partner === 'prod') partner = 'charentes';

  const partnerDomains = {
    'myprovence.fr': 'passprovence',
    'passmyprovence.fr': 'passprovence',
    'tourismebyca.fr': 'tca',
    'bienvenus.fr': 'bvn',
    'lavalagglo.bienvenus.com': 'lavalagglo',
    'loreedelabrie.bienvenus.com': 'loreedelabrie',
    'tickets-intermarche.fr': 'intermarche',
    'bordeaux-mon-commerce.fr': 'bordeauxmoncommerce',
    'avantageariege.fr': 'ariege',
    'avantagesariege.fr': 'ariege',
    'passevasionsiblu.fr': 'siblu',
    'martinique.org': 'martinique',
    'guidemartinique.org': 'martinique',
    'reducpeyi.fr': 'otc',
    'terresducentremartinique.fr': 'otc',
    'zanmi.ai': 'otc',
    'marianne.france.fr': 'france',
    'marianne-france.fr': 'france',
    'tourismecorreze.com': 'correze',
    'avantagesiledefrance.fr': 'iledefrance',
    'tourisme-orleansmetropole.com': 'orleans',
    'avantagearmor.fr': 'armor',
    'savoirfairedesalpilles.fr': 'ccvba',
    'avantage-ille-et-vilaine.fr': 'ileetvilaineca',
    'bretipass.bzh': 'illeetvilainetourisme',
    'bretipass.fr': 'illeetvilainetourisme',
    'charentes-qa.wearegenial.com': 'charentes',
    'charentes.wearegenial.dev': 'charentes',
    'infiniment-charentes.com': 'charentes',
    'huttopia-qa.wearegenial.com': 'huttopia',
    'roadtrip.bivouacs.huttopia.com': 'huttopia',
    'nordmartiniqueplanner-qa.wearegenial.com': 'nordmartiniqueplanner',
  };
  const domPartKey = Object.keys(partnerDomains).find((k) => host.endsWith(k));
  partner = domPartKey ? partnerDomains[domPartKey] : partner;

  return partner;
};

const getUrlInfoRequirements = (
  href: string,
  path: string,
  query: any,
  host,
  cookies: { [key: string]: any },
  useragent: string
) => {
  let req: RequirementsProps = {
    ...defReq,
    href,
    host,
  };

  if (query) {
    req = {
      ...req,
      ...query,
    };

    req.aroundme = query.aroundme === 'true';
    req.mapOpened = query.mapOpened === 'true';
    req.sellerId = query.seller;
    req.shortcode = query.shortcode;
    req.campaign = query.campaign;
    req.location = query.location && decodeURIComponent(query.location);
    req.department = query.department && decodeURIComponent(query.department);
    req.departement = query.departement && decodeURIComponent(query.departement);
    req.query = query.query && decodeURIComponent(query.query);
    req.useragent = useragent;
    ['categ', 'labels', 'filters'].forEach((k) => {
      req[k] = query[k] && decodeURIComponent(query[k]).split('|');
    });
  }

  let res = path;
  req.partner = getPartner(host, cookies, query);
  req.config = charentes;

  if (
    host.startsWith('localhost') ||
    host.startsWith('qa.') ||
    host.startsWith('ssr-dev.') ||
    host.startsWith('dev.') ||
    host.startsWith('charentes-qa.')
  ) {
    if (req.config?.dev) {
      req.config = { ...req.config, ...req.config.dev };
    }

    req.environment = 'qa';
  }

  if (query?.endpoint || cookies?.endpoint) {
    const ep = query?.endpoint || cookies?.endpoint;
    if (Object.keys(Endpoints).includes(ep)) {
      req.environment = ep;
    }
  }

  req.endpoint = Endpoints[req.environment];

  if (res === '/') return req;

  if (res[0] === '/' && res.length > 1) {
    res = res.substring(1);
  }

  const split = res.split('/');

  req.path = res?.includes('article') ? res : `/${split[0]}`;
  req.parameters = split.slice(1);

  return req;
};

const replaceRef = async (input, client) => {
  const content = [...input];
  const references = content.filter((c) => c._ref);

  if (!references || !references.length) {
    return input;
  }

  const refQuery = references.map((r) => `_id == "${r._ref}"`).join(' || ');
  const refData = await client.fetch(`*[${refQuery}]`);

  if (!refData || !refData.forEach) {
    return input;
  }

  refData.forEach((r) => {
    const index = content.findIndex((c) => c._ref === r._id);
    if (index < 0) return;
    content[index] = { ...content[index], ...r };
  });

  return content;
};

const getCMSData = async (requirements: any) => {
  let client = getSanityClient(requirements);
  const path = requirements.path.startsWith('/') ? requirements.path.slice(1) : requirements.path;

  const query = `*[(_type == "page" && (path.current == "${path}" || path.current == "/${path}")) || (_type == "article" && (slug.current == "${path}" || slug.current == "/${path}")) || _type == "configuration" || _type == "popup"]${getPageImagesData}`;

  const data = await client.fetch(query);

  if (!data || !data.length) return {};

  let configuration = null;
  let otherConfigs = {};
  const config =
    data.find((d) => d._type === 'configuration' && d._id.startsWith('drafts.')) ||
    data.find((d) => d._type === 'configuration');
  const popups = data.filter((d) => d._type === 'popup' && d.activated && d.localStorageKey) || [];

  if (config) {
    otherConfigs = {
      ...config,
      siteConfiguration: config.siteConfiguration || {},
      footer: mergeFooterConfig(config),
    };
    try {
      configuration = JSON.parse(config.configuration);
    } catch (e) {}
  }

  let output: any = {
    ...otherConfigs,
    configuration,
    popups,
  };

  const content = data.filter((d) => d._type === 'page' || d._type === 'article');
  if (!content.length) return output;

  const preview = content.find((d) => d._id.startsWith('drafts.')) || content[0];
  output = {
    path: preview?.path?.current || `/${preview?.slug?.current}`,
    ...output,
    ...preview,
    topPageNotice: {
      ...(output.topPageNotice || {}),
      ...(preview.topPageNotice || {}),
    },
    headerTags: [...(output.headerTags || []), ...(preview.headerTags || content.headerTags || [])],
  };

  if (output.hero && output.hero.length) {
    output.hero = await replaceRef(output.hero, client);
  }
  if (output.content && output.content.length) {
    output.content = await replaceRef(output.content, client);
  }

  return output;
};

const createSitemap = async (requirements: RequirementsProps, isFicheSit: boolean) => {
  let client = getSanityClient(requirements);

  const pageQueryUrls =
    '*[_type == "page"]{"lastUpdate": _updatedAt, "path": path.current, name, content}';
  let pageUrls = (await client.fetch(pageQueryUrls)) || [];

  const shopsPagesUrls =
    pageUrls.filter((p: any) =>
      p.content?.some((c: any) => c._type === 'shop' || c._type === 'shopV2')
    ) || [];
  const shopPages = [];
  if (shopsPagesUrls?.length) {
    const shops = await getAllVendorsNoFilter(requirements);
    pageUrls = pageUrls.filter((p: any) => !shopsPagesUrls.some((s: any) => s.path === p.path));
    shops?.forEach((shop: any) => {
      if (shop?.postStatus === 'denied') return;
      shopsPagesUrls?.forEach((page: any) => {
        if (!shop?.slug) return;
        const path = `${page.path}/${shop.slug}`;

        shopPages.push({
          ...page,
          path: path.replace(/\/etablissement\//, '/fiche-sit/'),
          name: shop?.storeName,
        });
      });
    });
  }

  const articleQueryUrls =
    '*[_type == "article"]{"lastUpdate": _updatedAt, "path": slug.current, title}';
  const articleUrls =
    (await client.fetch(articleQueryUrls))?.map(({ title, ...a }) => ({
      ...a,
      name: title,
    })) || [];

  let urls = isFicheSit ? [...shopPages] : [...articleUrls];

  const querySlugs =
    '*[_type == "detours" || _type == "portrait"]{"type": _type, "slug": slug.current}';
  const slugs = await client.fetch(querySlugs);

  urls = urls.map(({ path, ...u }) => ({
    ...u,
    path: path.startsWith('/') ? path.slice(1) : path,
  }));

  return `<?xml version="1.0" encoding="UTF-8"?>
  <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
    ${urls
      .filter(
        (f) =>
          !f.name?.toLowerCase().includes('test') && f.path !== '/portrait' && f.path !== '/detours'
      )
      .map(
        (u) => `<url>
      <loc>http://${requirements.host}/${u.path}</loc>
      <lastmod>${u.lastUpdate}</lastmod>
    </url>`
      )
      .join('')}
    ${slugs
      .filter((f: any) => !f.slug || !f.slug.toLowerCase().includes('test'))
      .map((s: any) => {
        let path = '';
        switch (s.type) {
          case 'detours':
            path = '/detours';
            break;
          case 'portrait':
            path = '/portrait';
            break;
          default:
            return '';
        }

        return `
      <url>
        <loc>http://${requirements.host}${path}/${s.slug}</loc>
      </url>`;
      })
      .join('')}
  </urlset>
  `;
};

const createRobots = async (requirements: RequirementsProps) => {
  return `
  User-agent: *
  Disallow: 

  # Disallow the login page (useless content)
  Disallow: /compte

  Sitemap: ${requirements.config.canonicalUrl}/sitemap.xml
  Sitemap: ${requirements.config.canonicalUrl}/sitemap-fiche-sit.xml
  `;
};

export interface MarkersProps {
  geoloc: any;
  category: string;
  content?: string;
}

const loadMap = (
  divRef: HTMLDivElement,
  center: any,
  markers: MarkersProps[],
  zoom?: number,
  position?: LatLngLiteral
) => {
  if (typeof window !== 'undefined' && window.google) {
    initiateMap(divRef, center, markers, zoom, position);
    return;
  }

  const loader = new Loader({
    apiKey: 'AIzaSyCYp4mEcSL6CN2IjfVnVjnJDdWCWeGhUz8',
    version: 'weekly',
    libraries: ['places'],
  });

  loader.importLibrary('maps').then(() => {
    initiateMap(divRef, center, markers, zoom, position);
  });
};

const initiateMap = (
  divRef: HTMLDivElement,
  center: any,
  marks: MarkersProps[],
  zoom?: number,
  position?: LatLngLiteral
) => {
  let mapExist = !!window.map;

  if (mapExist && window.map.getDiv() !== divRef) {
    mapExist = false;
  }

  if (!mapExist) {
    if (google.maps?.Map === undefined) return;
    window.map = new google.maps.Map(divRef, {
      zoom: zoom || 15,
      center,
      styles: [{ featureType: 'poi', stylers: [{ visibility: 'off' }] }],
    });
    const options = { legWeight: 3 }; // Just an example of options - please set your own if necessary
    window.oms = new OverlappingMarkerSpiderfier(window.map, options);
    window.clusterer = new MarkerClusterer({
      map: window.map,
      markers: [],
      onClusterClick: (e, c, m) => {
        defaultOnClusterClickHandler(e, c, m);
      },
    });
  }
  const map = window.map;
  const oms = window.oms;
  let markers = window.markers || [];
  const clusterer = window.clusterer;

  if (mapExist) {
    markers.forEach((m) => {
      m.setMap(null);
      oms.removeMarker(m, null);
    });
    clusterer.clearMarkers();
  }
  if (!marks || !marks.length) return;

  const infoWindow = new google.maps.InfoWindow({
    content: '',
    disableAutoPan: false,
  });

  let latlong = new google.maps.LatLngBounds();
  markers = marks.map((m) => {
    if (!m.geoloc) return null;

    const marker = new google.maps.Marker({
      position: m.geoloc,
      map,
      icon: `https://tourismebyca.twic.pics/static/tca/v2/pin/${m.category}.png`,
    });
    let c = new google.maps.LatLng(m.geoloc);
    if (!zoom) latlong.extend(c);
    if (m.content) {
      // marker.addListener("click", () => {
      //   infoWindow.setContent(m.content);
      //   infoWindow.open(map, marker);
      // });
      google.maps.event.addListener(marker, 'spider_click', function (e) {
        // 'spider_click', not plain 'click'
        infoWindow.setContent(m.content);
        infoWindow.open(map, marker);
      });
      oms.addMarker(marker, null);
    }

    return marker;
  });

  google.maps.event.addListener(map, 'drag', () => {
    infoWindow.close();
  });
  google.maps.event.addListener(map, 'zoom_changed', () => {
    infoWindow.close();
  });
  // Path for cluster icons to be appended (1.png, 2.png, etc.)
  // const imagePath = "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m";

  clusterer.addMarkers(markers);

  if (position) {
    const icon = {
      url: `https://tourismebyca.twic.pics/static/tca/v2/pin/geoloc.png`, // url
      scaledSize: new google.maps.Size(30, 30), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 0), // anchor
    };

    new google.maps.Marker({
      position,
      map,
      icon,
    });
    if (!zoom) latlong.extend(position);
  }
  if (!zoom) map.fitBounds(latlong);
};

const getImageMetadata = async (imageId: string, client: any) => {
  if (!imageId) return null;

  const query = `*[_type == "sanity.imageAsset" && _id == "${imageId}"][0]{
    metadata,
    altText,
    title,
    description,
    creditLine
  }`;

  return await client.fetch(query);
};

const getPageImagesData = `{
  ...,
  content[]{
    ...,
    _type == "imagesGroup" => {
      ...,
      images[]{
        ...,
        image{
          asset->{
            _id,
            url,
            metadata,
            altText,
            title,
            description,
            creditLine
          }
        }
      }
    },
    _type == "imagesGalleryCarousel" => {
      ...,
      images[]{
        ...,
        asset->{
          _id,
          url,
          metadata,
          altText,
          title,
          description,
          creditLine
        }
      }
    }
  },
  hero[]{
    ...,
    "backgroundImage": {
      "asset": *[_type == "sanity.imageAsset" && _id == ^.backgroundImage.asset._ref][0]{
        _id,
        metadata,
        altText,
        title,
        description,
        creditLine
      }
    }
  },
  "image": {
    "asset": *[_type == "sanity.imageAsset" && _id == ^.image.asset._ref][0]{
      _id,
      metadata,
      altText,
      title,
      description,
      creditLine
    }
  }
}`;

export {
  getCMSData,
  preloadState,
  loadMap,
  createSitemap,
  createRobots,
  getUrlInfoRequirements,
  fetchArticlesData,
  getImageMetadata,
  getPageImagesData,
};
