import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  usersApiAddFavorite,
  usersApiAddFavoritesGroup,
  usersApiDeleteFavorite,
  usersApiDeleteFavoritesGroup,
  usersApiGetFavoritesGroups,
  usersApiUpdateFavoritesGroup,
} from '../../tools/usersApi';
import { Favorite, FavoritesGroup, FavoritesGroupUpdate } from './components/types';

interface FavoritesStore {
  favoritesGroups: FavoritesGroup[];
  accessToken: string | null;

  actions: {
    init: () => void;
    addFavorite: (data: Favorite) => void;
    deleteFavorite: (id: string) => void;
    addFavoritesGroup: (name: string) => void;
    updateFavoritesGroup: (data: FavoritesGroupUpdate) => void;
    deleteFavoritesGroup: (id: string) => void;
  };
}

const useFavoritesStore = create<FavoritesStore>()(
  persist(
    (set, get) => ({
      favoritesGroups: [],
      accessToken: null,

      actions: {
        init: () => {
          const authStore = JSON.parse(localStorage.getItem('auth-store') || '{}');
          const accessToken = authStore?.state?.accessToken;
          set({ accessToken });

          if (!accessToken) {
            set({ favoritesGroups: [] });
          }

          usersApiGetFavoritesGroups(accessToken)
            .then((response) => {
              set({ favoritesGroups: response.data });
            })
            .catch(console.error);
        },
        addFavorite: (data: Favorite) => {
          const accessToken =
            get().accessToken ||
            JSON.parse(localStorage.getItem('auth-store') || '{}')?.state?.accessToken;

          usersApiAddFavorite(data, accessToken)
            .then((response) => {
              const favorite = response.data;

              set((state) => {
                const favoritesGroups = state.favoritesGroups.map((group) => {
                  if (group.id === favorite.favorites_group_id) {
                    return {
                      ...group,
                      favorites: [...group.favorites, favorite],
                    };
                  }
                  return group;
                });

                return { favoritesGroups };
              });
            })
            .catch(console.error);
        },
        deleteFavorite: (id: string) => {
          const accessToken =
            get().accessToken ||
            JSON.parse(localStorage.getItem('auth-store') || '{}')?.state?.accessToken;

          usersApiDeleteFavorite(id, accessToken)
            .then(() => {
              set((state) => {
                const favoritesGroups = state.favoritesGroups.map((group) => {
                  return {
                    ...group,
                    favorites: group.favorites.filter((favorite) => favorite.id !== id),
                  };
                });

                return { favoritesGroups };
              });
            })
            .catch(console.error);
        },
        addFavoritesGroup: (name: string) => {
          const accessToken =
            get().accessToken ||
            JSON.parse(localStorage.getItem('auth-store') || '{}')?.state?.accessToken;

          usersApiAddFavoritesGroup(name, accessToken)
            .then((response) => {
              const newGroup = response.data;

              set((state) => {
                return { favoritesGroups: [...state.favoritesGroups, newGroup] };
              });
            })
            .catch(console.error);
        },
        updateFavoritesGroup: (data: FavoritesGroupUpdate) => {
          const accessToken =
            get().accessToken ||
            JSON.parse(localStorage.getItem('auth-store') || '{}')?.state?.accessToken;

          usersApiUpdateFavoritesGroup(data, accessToken)
            .then((response) => {
              const updatedGroup = response.data;

              set((state) => {
                const favoritesGroups = state.favoritesGroups.map((group) => {
                  if (group.id === updatedGroup.id) {
                    return updatedGroup;
                  }
                  return group;
                });

                return { favoritesGroups };
              });
            })
            .catch(console.error);
        },
        deleteFavoritesGroup: (id: string) => {
          const accessToken =
            get().accessToken ||
            JSON.parse(localStorage.getItem('auth-store') || '{}')?.state?.accessToken;

          usersApiDeleteFavoritesGroup(id, accessToken)
            .then(() => {
              set((state) => {
                return {
                  favoritesGroups: state.favoritesGroups.filter((group) => group.id !== id),
                };
              });
            })
            .catch(console.error);
        },
      },
    }),
    {
      name: 'favorites-store', // Storage key
      partialize: (state) => ({
        favoritesGroups: state.favoritesGroups,
      }),
    }
  )
);

// Getters
export const getFavoritesActions = useFavoritesStore.getState().actions;

// Selectors
export const useFavoritesGroups = () => useFavoritesStore((state) => state.favoritesGroups);
export const useFavoritesActions = () => useFavoritesStore((state) => state.actions);

export default useFavoritesStore;
