import React from 'react';
import ReactConfetti from 'react-confetti';
import { createPortal } from 'react-dom';
import { useWindowSize } from '../../tools/hooks';

interface ConfettiProps extends React.ComponentProps<typeof ReactConfetti> {}

export default function Confetti(props: ConfettiProps) {
  const { width, height } = useWindowSize();

  return createPortal(
    <div className="Confetti">
      <ReactConfetti
        tweenDuration={200}
        confettiSource={{
          x: width / 2,
          y: height / 2,
          w: 1,
          h: 1,
        }}
        numberOfPieces={250}
        initialVelocityY={30}
        initialVelocityX={-20}
        gravity={0.3}
        recycle={false}
        {...props}
      />
    </div>,
    document.body
  );
}
