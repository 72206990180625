import React from 'react';
import FavoriteButton from '../../../features/favorites/components/Button';
import { RequirementsProps } from '../../../tools/context';
import { useWindowSize } from '../../../tools/hooks';
import { useTranslation } from '../../../tools/i18n';
import { CustomDataStore } from '../../../tools/providersAPI';
import { getFormattedEuro } from '../../../tools/tools';
import HowToGoButton from '../../Buttons/HowToGo';
import ShareButton from '../../Buttons/Share';
import ShopV2Card from '../../ShopV2Card';
import { StickyItem } from '../../StickyItem';
import { DetailShopV2Props } from '../Detail';
import IconList from '../IconList';
import ManagementStoreV2 from '../Management';

interface HeroShopV2Props {
  requirements: RequirementsProps;
  title: string;
  imageUrls: string[];
  seller: { [key: string]: any };
  data: { [key: string]: any };
  customData?: CustomDataStore;
}

const HeroShopV2: React.FC<HeroShopV2Props> = ({
  requirements,
  title,
  imageUrls,
  seller,
  data,
  customData,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const { id, contactEmail: email } = seller;
  const lat = customData?.store?.address?.latitude ?? seller?.latitude ?? data?.latitude;
  const lng = customData?.store?.address?.longitude ?? seller?.longitude ?? data;

  const details: DetailShopV2Props[] = [
    { icon: 'mapPin', title: customData?.store?.address?.city || data?.city },
    !!customData?.store?.minimumPrice
      ? {
          icon: 'euroV2',
          title: getFormattedEuro(customData?.store?.minimumPrice),
        }
      : null,
  ];

  const management = customData?.store?.management;

  const getSocialNetwork = (networkName: string) => {
    const socialNetworkGroup = customData?.groups?.find(
      (group) => group.title === '{{t:socialNetworks}}'
    );

    if (!socialNetworkGroup) return null;

    const matchingItem = socialNetworkGroup.items.find((item) =>
      // @ts-ignore
      item.details.some((detail) => detail.title.toLowerCase() === networkName.toLowerCase())
    );

    return (
      // @ts-ignore
      matchingItem?.details.find(
        (detail: DetailShopV2Props) => detail.title.toLowerCase() === networkName.toLowerCase()
      )?.value || null
    );
  };

  return (
    <div className="HeroShopV2">
      <ShopV2Card imageUrls={imageUrls} title={title} details={details} h1Title={isMobile} />
      <div className="HeroShopV2__buttonsContainer">
        <div className="HeroShopV2__primaryButtons">
          <HowToGoButton latitude={lat} longitude={lng} />
        </div>
        <IconList
          title={t('shopV2.hero.contactTitle')}
          phone={customData?.store?.phone}
          email={email}
          website={customData?.store?.website}
          facebook={getSocialNetwork('facebook')}
          instagram={getSocialNetwork('instagram')}
        />
        <StickyItem position="right">
          <div className="HeroShopV2__secondaryButtons">
            <FavoriteButton
              favorite={{
                type: 'shop',
                data: {
                  shop_id: id,
                },
              }}
            />
            <ShareButton />
          </div>
        </StickyItem>
        {management ? (
          <ManagementStoreV2
            name={management.name}
            email={management.email}
            website={management.website}
            createdAt={management.createdAt}
            updatedAt={management.updatedAt}
            noContainer
            id={data?.apidae_id}
            url={requirements.href}
          />
        ) : null}
      </div>
    </div>
  );
};

export default HeroShopV2;
