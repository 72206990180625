import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import ButtonV2 from '../ButtonV2';

interface ModalProps {
  isOpen?: boolean;
  title?: string;
  subtitle?: string;
  onClose: () => void;
  className?: string;
  children?: React.ReactNode;
}

const Modal = ({ isOpen, title, subtitle, onClose, className, children }: ModalProps) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', handleEscape);
    } else {
      document.body.style.overflow = '';
      document.removeEventListener('keydown', handleEscape);
    }

    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  return isOpen
    ? createPortal(
        <div className={`Modal ${className ?? ''}`}>
          <div className="Modal__container">
            <ButtonV2
              className="Modal__closeButton"
              leftIcon="cross"
              variant="clear"
              onClick={onClose}
            />
            {!!title || !!subtitle ? (
              <div className="Modal__header">
                {title ? <h4>{title}</h4> : null}
                {subtitle ? <p>{subtitle}</p> : null}
              </div>
            ) : null}
            {children}
          </div>
        </div>,
        document.body
      )
    : null;
};

export default Modal;
