import React from 'react';
import { Picto } from '../../Picto';
import { FacebookLogo, InstagramLogo } from '../../Picto/charentes/Icons';
import { Website } from '../../Picto/Website';

interface IconListProps {
  title: string;
  phone?: string;
  email?: string;
  website?: string;
  facebook?: string;
  instagram?: string;
}

const IconList = ({ title, phone, email, website, facebook, instagram }: IconListProps) => {
  return (
    <div className="icon--list">
      <p className="icon--list--title">{title}</p>
      <div className="icon--list--icons">
        {phone && (
          <a className="icon--phone" href={`tel:${phone}`} target="_blank" rel="noopener">
            <Picto iconKey={'phoneV2'} fill="black" />
          </a>
        )}
        {email && (
          <a className="icon--email" href={`mailto:${email}`} target="_blank" rel="noopener">
            <Picto iconKey={'envelopeV2'} fill="black" />
          </a>
        )}
        {website && (
          <a className="icon--website" href={website} target="_blank" rel="noopener">
            <Website fill="black" />
          </a>
        )}
        {facebook && (
          <a className="icon--facebook" href={facebook} target="_blank" rel="noopener">
            <FacebookLogo />
          </a>
        )}
        {instagram && (
          <a className="icon--instagram" href={instagram} target="_blank" rel="noopener">
            <InstagramLogo />
          </a>
        )}
      </div>
    </div>
  );
};

export default IconList;
