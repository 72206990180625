import React from 'react';
import ImagesCarousel from '../ImagesCarousel';
import DetailShopV2, { DetailShopV2Props } from '../ShopV2/Detail';
import { StickyItem } from '../StickyItem';

export interface ShopV2CardProps {
  imageUrls: string[];
  title: string;
  details?: DetailShopV2Props[];
  h1Title?: boolean;
}

const ShopV2Card = ({ imageUrls, title, details, h1Title }: ShopV2CardProps) => {
  const TitleTag = h1Title ? 'h1' : 'span';

  const Title = () => <TitleTag className="ShopV2Card__container__title">{title}</TitleTag>;

  return (
    <div className="ShopV2Card">
      {imageUrls?.length > 1 ? (
        <ImagesCarousel
          imageUrls={imageUrls}
          swiperProps={{ autoplay: { delay: 3000, disableOnInteraction: false } }}
        />
      ) : imageUrls?.[0] ? (
        <img src={imageUrls[0]} alt={title} className="ShopV2Card__image" />
      ) : null}
      <div className="ShopV2Card__container">
        {TitleTag === 'h1' ? (
          <StickyItem>
            <Title />
          </StickyItem>
        ) : (
          <Title />
        )}
        <div className="ShopV2Card__container__details">
          {details?.length ? details.map((d, i) => <DetailShopV2 key={i} {...d} />) : null}
        </div>
      </div>
    </div>
  );
};

export default ShopV2Card;
