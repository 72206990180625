import React from 'react';
import { RequirementsProps } from '../../tools/context';
import { imageUrlFor } from '../../tools/sanity';
import { LinkProps } from '../index';
import Logo from '../Logo';
import { FacebookLogo, InstagramLogo, YoutubeLogo } from '../Picto/charentes/Icons';

export interface ImageProps {
  media: any;
  link?: string;
}
export interface SocialProps {
  image: any;
  link?: string;
  alt?: string;
}

interface ContentItem {
  _key?: number;
  type: 'text' | 'image';
  text?: {
    content: string;
    hasLink: boolean;
    link?: string;
  };
  image?: any;
  imageLink?: string;
}

interface SubHeroSection {
  left: ContentItem[];
  middle: ContentItem[];
  right: ContentItem[];
}

interface MainSection {
  helpLink2Url: string;
  proLink2Text: string;
  proLink1Url: string;
  proLink1Text: string;
  helpLink2Text: string;
  helpTitle: string;
  facebookLink: string;
  instagramLink: string;
  youtubeLink: string;
  proTitle: string;
  helpLink1Url: string;
  proLink2Url: string;
  helpLink1Text: string;
}

export interface FooterProps {
  footer?: {
    navigation?: LinkProps[];
    bottomLinks?: LinkProps[];
    rightLogo?: ImageProps;
    leftLogoLink?: string;
    socialList?: SocialProps[];
    subHero?: SubHeroSection;
    mainSection?: MainSection;
    type?: 'classic' | 'modern';
  };
  footerType?: 'classic' | 'modern';
  requirements: RequirementsProps;
}

export const Footer = ({ footer, requirements, footerType }: FooterProps) => {
  // console.log('Footer props received:', { footer, requirements, footerType });

  return footerType === 'classic' ? (
    <ClassicFooter footer={footer} requirements={requirements} />
  ) : (
    <ModernFooter footer={footer} requirements={requirements} />
  );
};

export const ClassicFooter = ({ footer, requirements }: FooterProps) => {
  const links = footer && footer.navigation ? footer.navigation : [];
  const bottomLinks = footer && footer.bottomLinks ? footer.bottomLinks : [];
  const rightLogo = footer && footer.rightLogo ? footer.rightLogo : null;
  const socialList = footer && footer.socialList ? footer.socialList : [];

  const RightLogoComp = rightLogo?.link ? 'a' : 'div';
  const RightLogoProps = rightLogo?.link
    ? { href: rightLogo.link, target: '_blank', rel: 'noopener' }
    : {};
  const logoLink = footer?.leftLogoLink || '/';

  return (
    <div className={'Footer'}>
      <div className="footer-content">
        <div className="footer-logo">
          <Logo
            location={'footer'}
            requirements={requirements}
            logoLink={logoLink}
            target_blank={true}
          />
          <div className="social-images-row">
            {socialList.map((social, index) => (
              <a
                key={index}
                className="social-image"
                href={social.link ? social.link : '#'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={imageUrlFor(social.image, requirements).url()}
                  alt={social.alt ? social.alt : 'Social image'}
                />
              </a>
            ))}
          </div>
        </div>
        <div className="links">
          {links.map((link, index) => (
            <a
              className={'link'}
              key={index}
              href={link.link}
              target={link.external ? '_blank' : null}
              rel={link.external ? 'noreferrer' : undefined}
            >
              {link.text}
            </a>
          ))}
        </div>
        <div className="footer-logo">
          {rightLogo && rightLogo.media && (
            <RightLogoComp className="block" key="test" {...RightLogoProps}>
              <img src={imageUrlFor(rightLogo.media, requirements).url()} alt="Right footer logo" />
            </RightLogoComp>
          )}
        </div>
      </div>
      <div className="footer-bottom">
        {bottomLinks.map((link, index) => (
          <React.Fragment key={index}>
            <a className={`link ${link.link ? 'url' : ''}`} href={link.link}>
              {link.text}
            </a>
            <div className="dot" />
          </React.Fragment>
        ))}
        <a href="/">
          {/* <a className={`link url`} href="javascript:openAxeptioCookies()"> */}
          Cookies
        </a>
      </div>
    </div>
  );
};

export const ModernFooter = ({ footer, requirements }: FooterProps) => {
  const logoLink = footer?.leftLogoLink || '/';
  const middleSection = footer?.subHero.middle || [];
  const rightSection = footer?.subHero.right || [];
  const leftSection = footer?.subHero.left || [];
  const mainSection: MainSection = footer?.mainSection || ({} as MainSection);

  return (
    <div className="ModernFooter">
      <div className="main-footer">
        <div className="logo-section">
          <Logo
            location="footer"
            requirements={requirements}
            logoLink={logoLink}
            target_blank={false}
          />
          <div className="social-links">
            <a href={mainSection?.facebookLink || '#'} target="_blank" rel="noreferrer">
              <FacebookLogo />
            </a>
            <a href={mainSection?.instagramLink || '#'} target="_blank" rel="noreferrer">
              <InstagramLogo />
            </a>
            <a href={mainSection?.youtubeLink || '#'} target="_blank" rel="noreferrer">
              <YoutubeLogo />
            </a>
          </div>
        </div>

        <div className="help-section">
          <span>{mainSection?.helpTitle || 'Aide'}</span>
          <a href={mainSection?.helpLink1Url || '/contact'} className="help-link contact-link">
            <span className="icon">📧 </span>
            {mainSection?.helpLink1Text || 'Contact'}
          </a>
          <a href={mainSection?.helpLink2Url || '/offices'} className="help-link office-link">
            <span className="icon">🧭 </span>
            {mainSection?.helpLink2Text || 'Office de Tourisme'}
          </a>
        </div>

        <div className="pro-section">
          <span>{mainSection?.proTitle || 'Accès professionnels'}</span>
          <a href={mainSection?.proLink1Url || '/espace-professionnel'} className="pro-link footer-pro-link">
            <span className="icon">💼 </span>
            {mainSection?.proLink1Text || 'Espace Pro'}
          </a>
          <a href={mainSection?.proLink2Url || '/espace-presse'} className="pro-link footer-press-link">
            <span className="icon">📰 </span>
            {mainSection?.proLink2Text || 'Presse'}
          </a>
        </div>

        <div className="lang-section">
          <span>Langues</span>
          <div className="lang-selector">
            <button className="lang-button">
              Français <span className="arrow">▼</span>
            </button>
          </div>
        </div>
      </div>

      <div className="bottom-footer">
        <div className="copyright" style={{ textAlign: 'left' }}>
          {leftSection.map((item, index) => (
            <span key={index}>{item.text?.content}</span>
          ))}
        </div>

        <div className="logos" style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          {middleSection.map((item, index) => (
            <a href={item?.imageLink} target="_blank" rel="noreferrer" key={item?._key ||index} >
              <img
                src={imageUrlFor(item.image, requirements).url()}
                alt={item.text?.content}
                style={{ width: '85px', height: 'auto' }}
              />
            </a>
          ))}
        </div>

        <div className="legal-links">
          {rightSection.map((item, index) => (
            <a href={item.text?.link} target="_blank" rel="noreferrer" className="legal-link" key={ item?._key|| index}>
              {item.text?.content}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
