import React from 'react';
import {replaceUrlParam} from "../../tools/tools";
import {RequirementsProps} from "../../tools/context";

export interface PaginationProps {
  nbPages?: number
  currentPage?: number
  handleChangePage: (p: number) => Promise<void>
  requirements: RequirementsProps
}

const Pagination = ({nbPages, currentPage, requirements, handleChangePage}: PaginationProps) => {
  if (!nbPages || nbPages<=1) return null;

  const handleClientSide = (e, page) => {
    e && e.preventDefault();

    const newUrl = replaceUrlParam('page', page, window.location.href);
    window.history.pushState({}, '', newUrl);
    handleChangePage && handleChangePage(page);

    return false;
  }

  if (nbPages <= 5) {
    return (
      <div className="pagination">
        {new Array(nbPages).fill('page_')
          .map((a, i) => i === currentPage
            ? <span key={a + i}>Page {i+1}</span>
            : <a
              key={a + i}
              href={replaceUrlParam('page', i, requirements.href)}
              onClick={e => handleClientSide(e, i)}
            >{i + 1}</a>)
        }
      </div>
    )
  }

  let max = Math.min(currentPage+2, nbPages-1)
  let min = Math.max(0, currentPage-2);
  let nb = max - min;

  if (nb < 4 && currentPage+2<nbPages-1) max += (4-nb)
  if (nb < 4 && currentPage-2>0) min -= (4-nb)
  nb = max - min;

  return (
    <div className="pagination">
      {min > 0 && <a
        href={replaceUrlParam('page', 0, requirements.href)}
        onClick={e => handleClientSide(e, 0)}
      >1</a>}
      {min>1 && <span>...</span>}
      {new Array(nb+1).fill('page_')
        .map((a, i) => (min+i) === currentPage
          ? <span key={a + i}>Page {min+i+1}</span>
          : <a
            key={a + i}
            href={replaceUrlParam('page', min+i, requirements.href)}
            onClick={e => handleClientSide(e, min+i)}
          >{min+i+1}</a>)
      }
      {max<nbPages-2 && <span>...</span>}
      {max<nbPages-1 &&
      <a
        href={replaceUrlParam('page', nbPages - 1, requirements.href)}
        onClick={e => handleClientSide(e, nbPages - 1)}
      >{nbPages}</a>
      }
    </div>
  )
}

export default Pagination;
